import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import store from "./store/store";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}
const root = ReactDOM.createRoot(document.getElementById("root"));
//TODO rollback URL after test
axios.defaults.baseURL = "https://backend.nudoc.in/";

// axios.i nterceptors.request.use(
//   (config) => {
//     const token = getAccessToken();
//     if (token) {
//       config.headers["Authorization"] = "Token " + token;
//     }
//     // config.headers['Content-Type'] = 'application/json';
//     return config;
//   },x
//   (error) => {
//     Promise.reject(error);
//   }
// );

// let refresh = false;

// axios.interceptors.response.use(
//   (resp) => resp,
//   async (error) => {
//     if (error.response.status === 401 && !refresh) {
//       refresh = true;
//       window.location.replace("https://pdf-backend.de/expired");
//       const token = getAccessToken();
//       const response = await axios.post("api/refresh-token/", {
//         username: "pdf",
//         token: token,
//       });

//       if (response.status === 200) {
//         axios.defaults.headers.common[
//           "Authorization"
//         ] = `Token ${response.data["token"]}`;
//         setAccessToken(response.data["token"]);
//         return axios(error.config);
//       }
//       console.log(response);
//     }
//     console.log(error);
//     refresh = false;
//     return Promise.reject(error.response);
//   }
// );

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
);
