/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import logo_m from "../../assets/logo-m.png";
import logo_s from "../../assets/logo-s.png";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { deepOrange } from "@mui/material/colors";
import axios from "axios";
import { useTimer } from "react-timer-hook";
import { useDispatch } from "react-redux";
import { logOut } from "../../slice/authSlice";
import { useNavigate } from "react-router";
import { useMemo } from "react";
const defnavigation = [
  { name: "Project Editor", href: "/editor", current: true },
];
const defnav2 = [
  { name: "Project Editor", href: "/editor", current: true },
  { name: "Dashboard", href: "/dashboard", current: false },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const adjustNumbers = (num) => {
  if (num < 10) {
    return "0" + num;
  } else {
    return num;
  }
};

export function Navbar(props) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  const navigation = props.showDashboard ? defnav2 : defnavigation;
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: authState.expires,
    onExpire: () => {
      dispatch(logOut());
      navigate("/expired", {
        replace: true,
      });
    },
  });
  const autosaveState = useSelector((s) => s.autosave);
  const displayTime = useMemo(()=>autosaveState.value!==null ? autosaveState.value.toLocaleTimeString() : " --",[autosaveState.value])
  // props.showAutoSave && console.log(autosaveState)
  return (
    <Disclosure as="nav" className="bg-slate-900">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              {props.showAutoSave && (
                  <div className="text-slate-400 mr-3">
                    <span>Autosaved: </span>
                    <span>{displayTime} </span>
                  </div>
                )}
              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                {/* <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block lg:hidden h-20 w-auto"
                    src={logo_s}
                    alt="NuDoc"
                  />
                  <img
                    className="hidden lg:block  h-25 w-80"
                    src={logo_m}
                    alt="NuDoc"
                  />
                </div> */}
                {/* <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "px-3 py-2 rounded-md text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div> */}
              </div>
              
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                
                <div className="text-slate-300 mr-3">
                  <span>Expires in </span>
                  <span>{adjustNumbers(hours)}</span>:
                  <span>{adjustNumbers(minutes)}</span>:
                  <span>{adjustNumbers(seconds)}</span>
                </div>
                <button
                  type="button"
                  className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open user menu</span>
                      <Avatar sx={{ bgcolor: deepOrange[500] }}>
                        {authState.name.toUpperCase()[0]}
                      </Avatar>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                              props.setActiveTab(0);
                            }}
                            to={{
                              pathname: "/dashboard",
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={() => {
                             authState.privilege==1&& props.setActiveTab(4);
                            }}
                            to={{
                              pathname: "/dashboard",
                            }}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            href="/"
                            onClick={() => {
                              navigate("/login", {
                                replace: true,
                              });
                              axios
                                .get("api/signout/")
                                .then((res) => {
                                  console.log("Signed Out successfully");
                                })
                                .catch((err) => {
                                  alert(
                                    "Could not sign out please refresh the page to sign out securely"
                                  );
                                });
                            }}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
