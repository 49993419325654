import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Button,
  TextField,
  DialogContentText,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const indexToTask = ["Scanning", "Splitting", "Renaming", "Verification"];
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [checkbox, setCheckbox] = useState(false);
  if (props.fileurl === null) return null;
  const authState = useSelector((s) => s.auth);
  //where is the error present?
  const optionVal = useRef(null);
  const descVal = useRef("");
  //list of pages
  const pageVal = useRef("");
  const fileState = useSelector((s) => s.file);
  const handleChange = (e) => {
    optionVal.current = e.target.value;
  };

  const handleStrChange = (e, isDesc) => {
    descVal.current = isDesc ? e.target.value : descVal.current;
    pageVal.current = !isDesc ? e.target.value : pageVal.current;
  };

  const raiseFlag = () => {
    axios
      .post("api/flag/?id=" + fileState.project_id, {
        project: fileState.project_id,
        category: props.rename ? props.flagConfig.category : props.category,
        og_name: props.rename ? props.flagConfig.name : props.filename,
        user: authState.name,
        origin: optionVal.current,
        file_path: props.rename ? props.flagConfig.path : props.filepath,
        current_task: indexToTask[props.curTask],
        major_issue: checkbox,
        page_number: (pageVal.current + ",")
          .split(",")
          .map((e) => parseInt(e))
          .filter((e) => e),
        status: "pending",
        description: descVal.current,
      })
      .then((response) => {
        alert(
          "The issue has been raised to the project in-charge successfully "
        );
        if (checkbox) {
          props.handleLock();
        } else {
          props.handleClose();
        }
      });
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#0F172A",
        },
      }}
    >
      <DialogTitle>Raise a Flag</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Flagged File :{" "}
          {props.rename ? props.flagConfig.combined : props.filename}
        </DialogContentText>
        <DialogContentText>
          Flagged File Path:{" "}
          {props.rename ? props.flagConfig.path : props.filepath}
        </DialogContentText>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">Error Type</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="scanning"
              control={<Radio />}
              label="Scanning"
            />
            <FormControlLabel
              value="splitting"
              disabled={props.curTask <= 1}
              control={<Radio />}
              label="Splitting"
            />
            <FormControlLabel
              value="renaming"
              disabled={props.curTask <= 2}
              control={<Radio />}
              label="Renaming"
            />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
        {!props.rename && (
          <TextField
            fullWidth
            placeholder="Page Number"
            defaultValue={props.pageVal}
            margin="normal"
            onChange={(e) => handleStrChange(e, false)}
          />
        )}

        <TextField
          fullWidth
          multiline
          placeholder="Description"
          onChange={(e) => handleStrChange(e, true)}
        />
        {!props.rename ? (
          <FormControlLabel
            label={"Major Issue"}
            control={
              <Checkbox
                checked={checkbox}
                onChange={(ev) => {
                  setCheckbox(ev.target.checked);
                }}
              />
            }
          />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={raiseFlag}>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};
