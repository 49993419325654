import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Dialog, TextField, Checkbox } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SelectPreset from "./SelectPreset";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import axios from "axios";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import Divider from "@mui/material/Divider";
import { DialogTitle } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import InfoDialog from "./InfoDialog";
const ogSteps = ["Define Categories", "Define Naming Scheme"];
let resourceReqd = ["fieldNo"];
const validations = {
  fieldNo: /^\d+$/,
};
// eslint-disable-next-line import/no-anonymous-default-export
const Define = (props) => {
  const [steps, setSteps] = useState(ogSteps);
  const [loader, setLoader] = useState(false);
  const [selectedCat, setSelectedCat] = useState(null);
  const authState = useSelector((state) => state.auth);
  const [activeStep, setActiveStep] = React.useState(0);
  const [isDialogOpen, setDialog] = useState(false);
  const [skipped, setSkipped] = React.useState(new Set());
  const [presetDialog, setPresetDialog] = useState(false);
  const [presetID, setPresetID] = useState(-1);
  const root_ids = useRef({});
  //object with category name as key and category id as value
  const [isPresetFolder, setPresetFolder] = useState(true);
  //is preset for file or folder
  const [isLoading, setLoading] = useState(true);
  const previousCat = useRef([]);
  //array of configs for different categories
  const offsetStep = isDialogOpen ? 1 : 0; // in case info dialog is open we need to make sure activeStep - 1 is 0 and not isnt -1
  const [combinedObj, setCombinedObj] = useState([
    {
      folderData: {},
      fileData: {},
      category: "Default",
      category_description: { naming: "", splitting: "" },
    },
  ]);
  const closePresetDialog = () => setPresetDialog(false);
  // const [formData, setFormData] = useState({});
  const completedCall = useRef(0);
  const [uploadCat, setUploadCat] = useState([
    { name: "CATEGORY 1", category_description: { naming: "", splitting: "" } },
  ]);
  const isStepOptional = (step) => {
    return step === 5;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const validateInput = () => {
    let obj = combinedObj[activeStep - 1];
    for (let e of obj.folderData.folderObj) {
      if (
        e.locked &&
        (e.possible == undefined ||
          e.possible === null ||
          e.possible.length === 0)
      ) {
        alert(
          "Possible values are not set for field : " +
            e.name +
            " Category: " +
            obj.category
        );
        return false;
      }
    }
    for (let e of obj.fileData.folderObj) {
      if (
        e.locked &&
        (e.possible == undefined ||
          e.possible === null ||
          e.possible.length === 0)
      ) {
        alert(
          "Possible values are not set for field : " +
            e.name +
            " Category: " +
            obj.category
        );
        return false;
      }
    }

    return true;
  };

  const handleNext = () => {
    if (activeStep > 0) {
      if (!validateInput()) {
        return;
      }
    }
    if (activeStep === steps.length - 1) {
      submitResources();
      return;
    }
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 0) {
      completedCall.current = 0;
      let newSteps = ["Define Categories"];
      let newObj = [];
      let newRoot = {};
      for (let cat of uploadCat) {
        if (!cat.name || cat.name === null) {
          alert("Please enter name of categories");
          return;
        }
        newRoot[cat.name] = null;
        newSteps.push("Define " + cat.name);
        newObj.push({
          folderData: {
            fieldNo: 1,
            folderObj: [
              {
                id: "field1",
                locked: false,
                suggest: false,
                name: "",
                delimiter: "",
              },
            ],
          },
          fileData: {
            fieldNo: 1,
            folderObj: [
              {
                id: "field1",
                locked: false,
                suggest: false,
                name: "",
                delimiter: "",
              },
            ],
          },
          category: cat.name,
        });
      }
      root_ids.current = newRoot;
      setSteps(newSteps);
      setCombinedObj(newObj);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // if (activeStep === 2) {
    //   setCombinedObj({ ...combinedObj, fileData: formData });
    //   setFormData(combinedObj.folderData);
    // } else if (activeStep === 3) {
    //   setCombinedObj({ ...combinedObj, fileData: formData });
    // }
  };

  const handleDelimiterChange = (e, id) => {
    let newForm = [...combinedObj];

    newForm[activeStep + offsetStep - 1].fileData.folderObj[id].delimiter =
      e.target.value?.toUpperCase();
    setCombinedObj(newForm);
  };
  const handleKeyDown = (e, id, val) => {
    console.log(e.key, val);
    let newForm = [...combinedObj];
    if (e.key === "Backspace" && val === " ") {
      console.log("here");
      newForm[activeStep + offsetStep - 1].fileData.folderObj[id].delimiter =
        "";
      setCombinedObj(newForm);
    }
  };
  const handleNameChange = (e, id, isFolder) => {
    let newForm = [...combinedObj];
    if (isFolder)
      newForm[activeStep + offsetStep - 1].folderData.folderObj[id].name =
        e.target.value?.toUpperCase();
    else
      newForm[activeStep + offsetStep - 1].fileData.folderObj[id].name =
        e.target.value?.toUpperCase();
    setCombinedObj(newForm);
  };
  const handleCategoryConfiguration = (e, naming = false) => {
    let newForm = [...uploadCat];
    if (naming) {
      newForm[activeStep + offsetStep - 1].category_description.naming =
        e.target.value;
    } else {
      newForm[activeStep + offsetStep - 1].category_description.splitting =
        e.target.value;
    }

    setUploadCat(newForm);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  const unsetPreset = (id, isFolder) => {
    let newForm = [...combinedObj];
    if (isFolder) {
      newForm[activeStep + offsetStep - 1].folderData.folderObj[id].possible =
        null;
      newForm[activeStep + offsetStep - 1].folderData.folderObj[id].presetName =
        undefined;
    } else {
      newForm[activeStep + offsetStep - 1].fileData.folderObj[id].possible =
        null;
      newForm[activeStep + offsetStep - 1].fileData.folderObj[id].presetName =
        undefined;
    }
    setCombinedObj(newForm);
  };
  const toggleLock = (i, isFolder) => {
    let newForm = [...combinedObj];
    if (isFolder)
      newForm[activeStep + offsetStep - 1].folderData.folderObj[i].locked =
        !newForm[activeStep + offsetStep - 1].folderData.folderObj[i].locked;
    else
      newForm[activeStep + offsetStep - 1].fileData.folderObj[i].locked =
        !newForm[activeStep + offsetStep - 1].fileData.folderObj[i].locked;
    console.log("setting lock status", newForm);
    setCombinedObj(newForm);
  };

  //FETCH EXISTING SETTINGS
  useEffect(() => {
    console.log("in use effect category");
    axios
      .get("api/root-category/?project_id=" + props.projectData.id)
      .then((r) => {
        setLoading(false);
        previousCat.current = r.data;
        console.log(previousCat.current);
      })
      .catch((e) => {
        props.setSnackbarData(e);
        props.setSnackbar(true);
        props.setSnackType("error");
      });
  }, []);

  const submitResources = () => {
    let totalCalls = uploadCat.length;
    for (let cat of uploadCat) {
      if (!delimValidator(uploadCat.map((obj) => obj.name).indexOf(cat.name))) {
        alert("Delimiter empty in " + cat.name + "  please fill to proceed");
        return;
      }
      for (let obj of combinedObj) {
        for (let e of obj.folderData.folderObj) {
          if (!e.name || e.name === null) {
            alert(
              "Folder Field name is a required field in Category: " +
                obj.category
            );
            return;
          }
        }
        for (let e of obj.fileData.folderObj) {
          if (!e.name || e.name === null) {
            alert(
              "File Field name is a required field in Category: " + obj.category
            );
            return;
          }
        }
      }
      setLoader(true);
      axios
        .post("api/root-category/", {
          project: props.projectData.id,
          category: cat.name.trim(),
          naming_description: cat.category_description.naming,
          splitting_description: cat.category_description.splitting,
        })
        .then((r) => {
          completedCall.current = completedCall.current + 1;
          root_ids.current[r.data.category.trim()] = r.data.id;
          console.log(completedCall.current, totalCalls);
          if (completedCall.current === totalCalls) {
            submitNamingPattern();
          }
        })
        .catch((e) => {
          props.setSnackbarData(
            "Some error occured while creating Category: " + cat.name + e
          );

          props.setSnackbar(true);
          props.setSnackType("error");
        });
    }

    // console.log(newAll)
  };

  const handleEditCat = () => {
    root_ids.current[selectedCat.name] = selectedCat.id;
    let combined_arr = getCombinedArray();
    console.log(combined_arr);
    console.log(selectedCat);
    axios
      .patch("api/root-category/", {
        project: props.projectData.id,
        category: selectedCat.name.trim(),
        naming_description: selectedCat.category_description.naming,
        splitting_description: selectedCat.category_description.splitting,
      })
      .then(() => {
        axios
          .patch("api/naming-pattern/", {
            naming_patterns: combined_arr,
          })
          .then(() => {
            setDialog(false);
            setCombinedObj([
              {
                folderData: {},
                fileData: {},
                category: "Default",
              },
            ]);
            props.setSnackbarData("Successfully changed category");
            props.setSnackbar(true);
            props.setSnackType("success");
          })
          .catch(() => {
            props.setSnackbarData("Error while updating naming patterns");
            props.setSnackbar(true);
            props.setSnackType("error");
          });
      })
      .catch(() => {
        props.setSnackbarData("Error while updating Category");
        props.setSnackbar(true);
        props.setSnackType("error");
      });
  };

  const getCombinedArray = () => {
    let combined_arr = [];
    for (let obj of combinedObj) {
      for (let e of obj.folderData.folderObj) {
        console.log("individual", e.locked);
        if (e.possible === undefined) {
          e.locked = false;
        }
        if (e.locked && e.possible !== undefined) {
          e.possible_values = e.possible.join(",");
          delete e.possible;
        }
        delete e.id;
        e.project = props.projectData.id;
        e.type = "folder";
        e.suggest = e.suggest === null ? false : e.suggest;
        e.root_category = root_ids.current[obj.category.trim()];
        e.root_category_name = obj.category.trim();
        e.description = "Sample Description";

        combined_arr.push(e);
      }
      for (let e of obj.fileData.folderObj) {
        if (e.possible === undefined) {
          e.locked = false;
        }
        if (e.locked && e.possible !== undefined) {
          e.possible_values = e.possible.join(",");
          delete e.possible;
        }
        delete e.id;
        e.project = props.projectData.id;
        e.type = "file";
        e.suggest = e.suggest === null ? false : e.suggest;
        e.root_category = root_ids.current[obj.category.trim()];
        e.description = "Sample Description";
        e.root_category_name = obj.category.trim();

        combined_arr.push(e);
      }
    }
    console.log("combined", combined_arr);
    return combined_arr;
  };
  const delimValidator = (step) => {
    let newForm = [...combinedObj];
    console.log(step, newForm);
    var ctr = 0;

    var fieldlen = newForm[step].fileData.folderObj.length;
    console.log("Fieldlen", fieldlen);
    newForm[step].fileData.folderObj.forEach((obj, index) => {
      console.log(obj.delimiter);
      if (obj.delimiter && obj.delimiter !== "" && index !== fieldlen - 1) {
        ctr++;
        console.log(ctr, "Incremented");
      }
    });
    console.log("computed length", newForm[step].fileData.folderObj.length - 1);
    return ctr === newForm[step].fileData.folderObj.length - 1 ? true : false;
  };
  const submitNamingPattern = () => {
    console.warn("Submitting patterns");
    let newForm = [...combinedObj];
    var ctr = 0;
    axios
      .get("api/project/" + props.projectData.id)
      .then((r) => {
        if (r.data.details.status === props.projectData.status) {
          let combined_arr = getCombinedArray();
          console.log(combined_arr);
          axios
            .post("api/naming-pattern/", {
              naming_patterns: combined_arr,
            })
            .then(() => {
              props.handleClose();
              props.setSnackbarData("Successfully configured category");
              props.setSnackbar(true);
              props.setSnackType("success");
              props.update(props.projectData.id, props.projectData.table_id);
              setLoader(false);
            })
            .catch(() => {
              setLoader(false);
              props.setSnackbarData("Error while creating naming patterns");
              props.setSnackbar(true);
              props.setSnackType("error");
            });
        }
      })
      .catch((e) => {
        props.setSnackbarData(e);
        props.setSnackbar(true);
        props.setSnackType("error");
      });
  };
  const setLockedValues = (allocated, name) => {
    let newForm = [...combinedObj];
    if (isPresetFolder) {
      newForm[activeStep + offsetStep - 1].folderData.folderObj[
        presetID
      ].possible = allocated;
      newForm[activeStep + offsetStep - 1].folderData.folderObj[
        presetID
      ].presetName = name;
    } else {
      newForm[activeStep + offsetStep - 1].fileData.folderObj[
        presetID
      ].possible = allocated;
      newForm[activeStep + offsetStep - 1].fileData.folderObj[
        presetID
      ].presetName = name;
    }
    setCombinedObj(newForm);
    setPresetDialog(false);
  };

  const addUploadCat = () => {
    setUploadCat([
      ...uploadCat,
      {
        name: "NEW CATEGORY " + uploadCat.length,
        category_description: { naming: "", splitting: "" },
      },
    ]);
  };
  const removeCat = (index) => {
    let newCat = [];
    for (let i = 0; i < uploadCat.length; i++) {
      if (i !== index) newCat.push(uploadCat[i]);
    }
    setUploadCat(newCat);
  };
  const addField = (i, isFile) => {
    let newObj = [...combinedObj];
    if (isFile) {
      newObj[activeStep + offsetStep - 1].fileData.fieldNo++;
      newObj[activeStep + offsetStep - 1].fileData.folderObj.splice(i + 1, 0, {
        id: "field" + i,
        locked: false,
        suggest: false,
        name: "",
        delimiter: null,
      });
    } else {
      newObj[activeStep + offsetStep - 1].folderData.fieldNo++;
      newObj[activeStep + offsetStep - 1].folderData.folderObj.splice(
        i + 1,
        0,
        {
          id: "field" + i,
          locked: false,
          suggest: false,
          name: "",
          delimiter: null,
        }
      );
    }
    setCombinedObj(newObj);
  };
  const handleCheckbox = (ev, i, isFile) => {
    let newObj = [...combinedObj];
    console.log(newObj);
    if (isFile) {
      newObj[activeStep + offsetStep - 1].fileData.folderObj[i].suggest =
        ev.target.checked;
    } else {
      newObj[activeStep + offsetStep - 1].folderData.folderObj[i].suggest =
        ev.target.checked;
    }
    setCombinedObj(newObj);
  };
  const removeField = (i, isFile) => {
    let newObj = [...combinedObj];
    if (isFile) {
      newObj[activeStep + offsetStep - 1].fileData.fieldNo--;
      newObj[activeStep + offsetStep - 1].fileData.folderObj.splice(i, 1);
    } else {
      newObj[activeStep + offsetStep - 1].folderData.fieldNo--;
      newObj[activeStep + offsetStep - 1].folderData.folderObj.splice(i, 1);
    }
    setCombinedObj(newObj);
  };
  const handleCatChange = (e, i) => {
    let newCat = [...uploadCat];
    newCat[i].name = e.target.value?.toUpperCase();
    setUploadCat(newCat);
  };

  const [errorState, setErrorState] = useState({});

  function setDynmaic(isFolder) {
    let dynamicRows = [];
    let activeObj = isFolder
      ? combinedObj[activeStep + offsetStep - 1].folderData
      : combinedObj[activeStep + offsetStep - 1].fileData;
    for (let i = 0; i < activeObj.fieldNo; i++) {
      dynamicRows.push(
        <div className="items-center flex">
          <TextField
            margin="normal"
            id="fieldname"
            label={"Name of Field" + (i + 1)}
            value={activeObj.folderObj[i].name}
            onChange={(e) => handleNameChange(e, i, isFolder)}
            type="text"
          />
          <IconButton
            disabled={
              activeObj.folderObj[i].suggest !== undefined &&
              activeObj.folderObj[i].suggest
            }
            color={activeObj.folderObj[i].locked ? "primary" : undefined}
            aria-label="lock"
            size="large"
            onClick={(_) => toggleLock(i, isFolder)}
          >
            {activeObj.folderObj[i].locked ? (
              <LockRoundedIcon />
            ) : (
              <LockOpenRoundedIcon />
            )}
          </IconButton>
          {activeObj.folderObj[i].locked ? (
            <>
              <IconButton
                aria-label="configure"
                size="large"
                onClick={(_) => {
                  setPresetID(i);
                  setPresetDialog(true);
                  setPresetFolder(isFolder);
                }}
              >
                <SettingsOutlinedIcon />
              </IconButton>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {activeObj.folderObj[i].presetName === undefined
                  ? "Unset"
                  : activeObj.folderObj[i].presetName}
              </Typography>
              <IconButton
                aria-label="configure"
                size="large"
                onClick={(_) => unsetPreset(i, isFolder)}
              >
                <ClearRoundedIcon />
              </IconButton>
            </>
          ) : null}
          <IconButton
            aria-label="add"
            size="large"
            onClick={(_) => addField(i, !isFolder)}
          >
            <AddRoundedIcon />
          </IconButton>
          <IconButton
            aria-label="remove"
            size="large"
            disabled={activeObj.fieldNo === 1}
            onClick={(_) => removeField(i, !isFolder)}
          >
            <RemoveRoundedIcon />
          </IconButton>
          <Checkbox
            checked={
              activeObj.folderObj[i].suggest !== undefined &&
              activeObj.folderObj[i].suggest
            }
            onChange={(ev) => handleCheckbox(ev, i, !isFolder)}
            disabled={activeObj.folderObj[i].locked}
          />
          {!isFolder && (
            <TextField
              inputProps={{ maxLength: 1 }}
              margin="normal"
              disabled={i === activeObj.fieldNo - 1}
              label={"Delimiter " + (i + 1)}
              value={
                activeObj.folderObj[i].delimiter === " "
                  ? "SPACE"
                  : activeObj.folderObj[i].delimiter
              }
              onChange={(e) => handleDelimiterChange(e, i)}
              onKeyDown={(e) => {
                handleKeyDown(e, i, activeObj.folderObj[i].delimiter);
              }}
              type="text"
            />
          )}
        </div>
      );
    }
    return dynamicRows;
  }
  return (
    <Dialog
      open={props.open}
      onClose={(e, reason) => {
        console.log(reason);
        if (reason !== "backdropClick") {
          props.handleClose();
        }
      }}
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#0F172A",
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              props.handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <Box sx={{ width: "100%", p: 3 }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : isLoading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography>
            <div>
              {/* {(activeStep === 0 || activeStep === 2) && (
                <TextField
                  margin="normal"
                  id="fieldno"
                  label="Number of Fields"
                  type="text"
                  value={
                    (activeStep === 0
                      ? combinedObj.folderData.fieldNo
                      : combinedObj.fileData.fieldNo) || ""
                  }
                  onChange={(e) => handleChange(e, "fieldNo", activeStep === 0)}
                  error={
                    activeStep === 0
                      ? errorState.fieldNofolder
                      : errorState.fieldNofile
                  }
                />
              )} */}
              {activeStep !== 0 ? (
                <>
                  <Divider>
                    <Chip label="Folder Naming" />
                  </Divider>
                  {setDynmaic(true)}
                  <Divider>
                    <Chip label="File Naming" />
                  </Divider>
                  {setDynmaic(false)}
                  <Divider>
                    <Chip label="Category Description" />
                  </Divider>
                  <TextField
                    className="w-full"
                    margin="normal"
                    id="catdescriptsplit"
                    label={"Splitting Instuctions for resources"}
                    value={
                      uploadCat[activeStep + offsetStep - 1]
                        .category_description.splitting
                    }
                    onChange={(e) => {
                      handleCategoryConfiguration(e);
                    }}
                    type="text"
                  />
                  <TextField
                    className="w-full"
                    margin="normal"
                    id="catdescriptname"
                    label={"Naming Instuctions for resources"}
                    value={
                      uploadCat[activeStep + offsetStep - 1]
                        .category_description.naming
                    }
                    onChange={(e) => {
                      handleCategoryConfiguration(e, true);
                    }}
                    type="text"
                  />
                </>
              ) : (
                <div>
                  <Typography sx={{ mt: 2, mb: 1 }} className="inline">
                    Existing Categories:
                  </Typography>
                  {previousCat.current.map((e, i) => {
                    return (
                      <Chip
                        key={i}
                        sx={{ ml: 1, mb: 1 }}
                        label={e.category}
                        onClick={() => {
                          setSelectedCat({
                            name: e.category,
                            id: e.id,
                            category_description: {
                              naming: e.naming_description,
                              splitting: e.splitting_description,
                            },
                          });
                          setDialog(true);
                        }}
                      />
                    );
                  })}
                  <br />
                  <Typography sx={{ mt: 2, mb: 1 }} className="inline">
                    Create Categories
                  </Typography>
                  {isDialogOpen && (
                    <InfoDialog
                      id={props.projectData.id}
                      cat={selectedCat}
                      // combinedObj={combinedObj}
                      // addField={addField}
                      // toggleLock={toggleLock}
                      // removeField={removeField}
                      handleEditCat={handleEditCat}
                      setDynmaic={setDynmaic}
                      setSelectedCategory={setSelectedCat}
                      setCombinedObj={setCombinedObj}
                      handleClose={() => {
                        setDialog(false);
                        setCombinedObj([
                          {
                            folderData: {},
                            fileData: {},
                            category: "Default",
                          },
                        ]);
                      }}
                      open={isDialogOpen}
                    />
                  )}
                  <IconButton
                    aria-label="add"
                    size="large"
                    onClick={addUploadCat}
                  >
                    <AddRoundedIcon />
                  </IconButton>
                  {uploadCat.map((e, i) => {
                    return (
                      <div className="items-center flex">
                        <TextField
                          margin="normal"
                          id={"catname" + i}
                          label={"Category Name " + (i + 1)}
                          type="text"
                          value={uploadCat[i].name || ""}
                          onChange={(e) => handleCatChange(e, i)}
                        />
                        <IconButton
                          aria-label="remove"
                          size="large"
                          disabled={i === 0}
                          onClick={() => removeCat(i)}
                        >
                          <ClearRoundedIcon />
                        </IconButton>
                      </div>
                    );
                  })}
                </div>
              )}
              {presetDialog && (
                <SelectPreset
                  open={presetDialog}
                  handleClose={closePresetDialog}
                  toDisplay={
                    isPresetFolder
                      ? combinedObj[activeStep + offsetStep - 1].folderData
                          .folderObj[presetID].locked && {
                          possible:
                            combinedObj[activeStep + offsetStep - 1].folderData
                              .folderObj[presetID].possible,
                          presetName:
                            combinedObj[activeStep + offsetStep - 1].folderData
                              .folderObj[presetID].presetName,
                        }
                      : combinedObj[activeStep + offsetStep - 1].fileData
                          .folderObj[presetID].locked && {
                          possible:
                            combinedObj[activeStep + offsetStep - 1].fileData
                              .folderObj[presetID].possible,
                          presetName:
                            combinedObj[activeStep + offsetStep - 1].fileData
                              .folderObj[presetID].presetName,
                        }
                  }
                  set={setLockedValues}
                />
              )}
            </div>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}
              {loader ? (
                <CircularProgress />
              ) : (
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              )}
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Dialog>
  );
};

export const DefineDialog = React.memo(Define);
