import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import LoadingDiv from "../PdfPreview/LoadingDiv";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useMemo } from "react";
export const Preview = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const condition = props.isBytes ? props.blob.data : props.blob
  const file = useMemo(() => (props.blob), [condition])
  return (
    <div className="bg-white w-full h-full fixed z-[1600] inset-0 overflow-y-auto">
      <div
        className="w-fit h-fit bg-blue-500 p-1 text-slate-100 font-semibold text-center cursor-pointer hover:bg-blu-600 fixed z-50 opacity-75"
        onClick={props.close}
      >
        <CloseRoundedIcon />
      </div>
      <center>
        <Document
          file={file}
          className="w-full "
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="w-full mx-auto"
                loading={<LoadingDiv />}
                renderTextLayer={false}
                // downloadProgress={<Progress />}
                renderAnnotationLayer={false}
                scale={1.5}
              />
              <div className="ml-30 h-2 bg-slate-600 w-full"></div>
            </>
          ))}
        </Document>
      </center>
      {/* <embed className="w-full h-full" src={props.blob + "#toolbar=0"}></embed> */}
    </div>
  );
};
