import { createSlice } from "@reduxjs/toolkit";
export const fileSlice = createSlice({
  name: "file",
  initialState: {
    files: [
      // {
      //   name: "AUGUST (1).pdf",
      //   url: "https://pdfdev-code.s3.ap-south-1.amazonaws.com/SAMPLE+FOR+SPLIT+BDR+EXPENCES+2-5-22+PRATHAMESH+2017-18/AUGUST+(1).pdf",
      //   completed: false,
      // },
      // {
      //   name: "JULY (01 TO 100) (1).pdf",
      //   url: "https://pdfdev-code.s3.ap-south-1.amazonaws.com/SAMPLE+FOR+SPLIT+BDR+EXPENCES+2-5-22+PRATHAMESH+2017-18/JULY+(01+TO+100)+(1).pdf",
      //   completed: false,
      // },
      // {
      //   name: "JULY (2).pdf",
      //   url: "https://pdfdev-code.s3.ap-south-1.amazonaws.com/SAMPLE+FOR+SPLIT+BDR+EXPENCES+2-5-22+PRATHAMESH+2017-18/JULY+(2).pdf",
      //   completed: false,
      // },
      // {
      //   name: "JUNE (MUM 301 T 400) (3).pdf",
      //   url: "https://pdfdev-code.s3.ap-south-1.amazonaws.com/SAMPLE+FOR+SPLIT+BDR+EXPENCES+2-5-22+PRATHAMESH+2017-18/JUNE+(MUM+301+T+400)+(3).pdf",
      //   completed: false,
      // },
      // {
      //   name: "JUNE (MUM MM SOV 401 TO 600) (4).pdf",
      //   url: "https://pdfdev-code.s3.ap-south-1.amazonaws.com/SAMPLE+FOR+SPLIT+BDR+EXPENCES+2-5-22+PRATHAMESH+2017-18/JUNE+(MUM+MM+SOV+401+TO+600)+(4).pdf",
      //   completed: false,
      // },
      // {
      //   name: "JUNE (MUM TRD SOV) (5).pdf",
      //   url: "https://pdfdev-code.s3.ap-south-1.amazonaws.com/SAMPLE+FOR+SPLIT+BDR+EXPENCES+2-5-22+PRATHAMESH+2017-18/JUNE+(MUM+TRD+SOV)+(5).pdf",
      //   completed: false,
      // },
      // {
      //   name: "MARCH (201 TO 400) (2).pdf",
      //   url: "https://pdfdev-code.s3.ap-south-1.amazonaws.com/SAMPLE+FOR+SPLIT+BDR+EXPENCES+2-5-22+PRATHAMESH+2017-18/MARCH+(201+TO+400)+(2).pdf",
      //   completed: false,
      // },
    ],
    currentIndex: 0,
    category: "",
    project_id: -1,
    task_id: -1
  },
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setCurrentFile: (state, action) => {
      state.currentIndex = action.payload;
    },
    setTask: (state,action) =>{
      state.project_id = action.payload.project_id
      // state.task_id = action.payload.task_id
      // state.category = action.payload.category
    },
    setSaved: (state,action) =>{
      state.files[action.payload].saved = true
    },
    setSavedOps: (state,action) =>{
      state.files[action.payload].savedOps = true
    },
    updateURL:(state,action) =>{
      state.files[action.payload.index].url = action.payload.url
    },
    updateFileExt: (state,action) =>{
      state.files[state.currentIndex].extracted = action.payload.extracted
    },
    setRender: (state,action) =>{
      let newExt = [...state.files[state.currentIndex].extracted]
      let newExtObj = {...state.files[state.currentIndex].extracted[action.payload.index],...action.payload.renderData}
      newExt[action.payload.index] = newExtObj
      state.files[state.currentIndex].extracted = newExt
    },
    setDelRender:(state,action)=>{
      state.files[state.currentIndex].deleted_rendering=action.payload.renderData
    },
    clearExt : (state,action) =>{
      state.files[action.payload.index].extracted = []
    },
    updateDeleted: (state, action) => {
      state.files[state.currentIndex].deleted = action.payload.deleted
    },
    clearDel:(state,action)=>{
      delete state.files[action.payload.index].deleted;
      state.files[action.payload.index].deleted_rendering={}
    },

  },
});

export const { setFiles, setCurrentFile, setTask, setSaved,updateURL, setSavedOps, updateFileExt,setRender, clearExt,updateDeleted,setDelRender,clearDel } = fileSlice.actions;
export default fileSlice.reducer;
