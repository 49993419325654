import { get, set, keys, clear, update } from "idb-keyval";
//Saving State
export const SaveState = async (
  blobState,
  fileState,
  FileData,
  FolderData,
  ognames,
  index,
  displayMsg
) => {
  console.log(index);

  var updated = FileData.map((obj, i) => {
    return {
      ...obj,
      url: blobState.allBlobs[index][i]?.url,
      path: blobState.allBlobs[index][i]?.path,
    };
  });
  console.log("Writing", FileData, FolderData);

  console.log(fileState.files[index].category);
  await set(
    "--naming--file--" +
      fileState.project_id +
      "--" +
      fileState.files[index].category +
      "--" +
      fileState.files[index].name,
    {
      folderData: FolderData,
      fileData: updated,
      children: ognames[fileState.files[index].name],
      fileState: fileState.files[index],
    }
  )
    .then(() => console.log("Rename Data saved Successfully", "success"))
    .catch((err) =>
      displayMsg("An Error occured while saving the file try again error")
    );

  return true;
};
//Restore state
export const RestoreState = async (proj, fileName, category) => {
  let keyList = await keys();
  let data = {};
  if (keyList.length === 0) {
    return {
      error: true,
      errorDesc: "no saved files found",
    };
  }
  let found = false;
  for (let key of keyList) {
    console.log(key);
    if (key.startsWith("--naming--file--")) {
      let combined = key.slice(16, key.lastIndexOf("--"));
      let splitted = combined.split("--");
      let proj_id = splitted[0];
      let cat = splitted[1];
      console.log(proj_id);
      if (parseInt(proj_id) !== proj && cat !== category) {
        continue;
      }
      let temp = key.slice(key.lastIndexOf("--") + 2);
      console.log(temp + " ?== " + fileName);
      if (temp !== fileName) {
        found = false;
        continue;
      }
      found = true;
      let val = await get(key);
      data = {
        ...val,
      };
      break;
    }
  }

  return {
    data,
    error: !found,
    errorText: found ? null : "Couldn't find the specified file",
  };
};
