import { DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { Button } from "@mui/material";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import { DialogContent } from "@mui/material";
import { Modal } from "@mui/material";
import { Preview } from "../../../LeftBox/Preview";
export const AllotData = (props) => {
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const close = () => {
    setPreview(false);
    setPreviewData({});
  };

  const showPreview = (pages, file) => {
    setPreviewData({ blob: file, pages, close });
    setPreview(true);
  };
  let config = props.config;
  return (
    <>
      {preview && <Preview {...previewData} />}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#0F172A",
          },
        }}
        open={props.open}
        onClose={props.handleClose}
      >
        <div>
          <p>{config.name}</p>
          <p>Files: </p>
          {config.files
            ? config.files.map((filename) => {
                return (
                  <div
                    onClick={() => {
                      showPreview(1, filename.url);
                    }}
                  >
                    {filename.name}
                  </div>
                );
              })
            : null}

          <p>{config.category}</p>
        </div>
      </Dialog>
    </>
  );
};
