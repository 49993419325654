import { createSlice } from "@reduxjs/toolkit";
export const autoSaveSlice = createSlice({
  name: "autosave",
  initialState: {
    value:null
  },
  reducers: {
    setAutoSave: (state) => {
      state.value = new Date()
    },
    clearAutoSave: (state) => {
      state.value = null
    }
  },
});

export const { setAutoSave,clearAutoSave } =autoSaveSlice.actions;
export default autoSaveSlice.reducer;
