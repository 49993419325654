import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelperDialog from "../HelperDialog/HelperDialog";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { io } from "socket.io-client";
import { useEffect } from "react";
import axios from "axios";
import { DocumentIcon } from "@heroicons/react/solid";
import JSZip, { files } from "jszip";
import FileSaver from "file-saver";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import { setTime } from "../../slice/timeSlice";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";
import { DeleteOutlineRounded } from "@mui/icons-material";

export default function ControlledTreeView(props) {
  console.warn("props data", props.data);
  const [expanded, setExpanded] = useState([]);
  const [message, setMessage] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [selected, setSelected] = useState([]);
  const [ids, setIds] = useState([]);
  const [dirs, setDirs] = useState([]);
  const [data, setData] = useState([]);
  const [link, setLink] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [listcount, setListCount] = useState([]);
  const [loader, setLoader] = useState(false);
  const [curFile, setCurFile] = useState();
  const [startedCount, setStartedCount] = useState();
  const [compFileCount, setCompFileCount] = useState(0);
  const [curFocussed, setCurFocused] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogProp = React.useRef({});

  // const useStyles = makeStyles({
  //   labelg: {
  //     color: "green",
  //   },
  //   labelr: {
  //     color: "red",
  //   },
  // });
  // const classes = useStyles();
  // const timeState = useSelector((state) => state.time);
  // useEffect(()=>{console.log(logs)},[logs])
  // useEffect(() => {
  //   console.log(props)
  //   if(props.hasOwnProperty('socketInstance'))
  //   {
  //   props.socketInstance.on("data", (data) => {
  //     console.log(data)
  //   });
  // }
  // }, [props.hasOwnProperty('socketInstance'),props.socketInstance]);
  // var instance = props.socketInstance;

  // useEffect(() => {
  //   if (instance) {
  //     props.socketInstance.on("data", (data) => {
  //       if (data.hasOwnProperty("file")) {
  //         setCurFile(data.file);
  //         props.setDeskewFilename(data.file);
  //         setStartedCount((started) => {
  //           return started + 1;
  //         });
  //       }
  //       if (data.hasOwnProperty("success")) {
  //         props.refresh();
  //         props.setDeskewFilename("");
  //         setCompFileCount((completed) => {
  //           return completed + 1;
  //         });
  //       } else {
  //         props.setLogs((logs) => {
  //           var l = [...logs];
  //           l.push(data);
  //           return l;
  //         });
  //       }

  //       console.log(props.logs);
  //     });

  //     return () => {
  //       props.socketInstance.off("data", () => {
  //         console.log("data event was removed");
  //       });
  //     };
  //   }
  // }, [instance]);
  const zipDownload = () => {
    props.setPrev(null);
    // props.setDLoading(true);
    var zipobjs = getChildren(data, selected[0])?.flat(Infinity)[0];
    console.log(zipobjs);

    // const response = await
    axios
      .post("/api/get-single-s3/", {
        dir_name: zipobjs.path.split(zipobjs.name)[0],
        file_name: zipobjs.name,
      })
      .then((r) => {
        const link = document.createElement("a");
        link.href = r.data.url;
        link.download = zipobjs.name;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });

    // // //console.log(blobObj)
    // // eslint-disable-next-line no-loop-func
    // FileSaver.saveAs(blobObj, zipobjs.name);
    // props.setDLoading(false);

    // //console.log(blobObj)
    // eslint-disable-next-line no-loop-func
    // FileSaver.saveAs(blobObj, zipobjs.name);
    // props.setDLoading(false);
  };

  const downloadFiles = async () => {
    const zip = require("jszip")();
    const generateZip = (folderName) => {
      zip.generateAsync({ type: "blob" }).then(function (content) {
        FileSaver.saveAs(content, `Deskewed_Files.zip`);
      });
      setMessageData("");
      setMessage(false);
    };

    const response = await axios.get("/api/get-s3-objects/", {
      onDownloadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentage);
        if (percentage === 100) {
          setTimeout(() => {
            setLoading(false);
          }, 400);
        }
      },
      params: {
        dir_name:
          "project/" + props.id + "/working/" + props.category + "/deskewed/",
      },
    });

    let promises = [];
    let folderName = zip.folder("Deskewed_Files");
    for (const [index, item] of response.data.objects.entries()) {
      var blobObj = await fetch(item.url).then((r) => {
        setMessageData(
          `Downloading File ${index + 1} of ${response.data.objects.length}`
        );
        return r.blob();
      });
      // //console.log(blobObj)
      // eslint-disable-next-line no-loop-func
      let filePromise = new Promise((resolve) => {
        let reader = new FileReader();
        //console.log(blobObj)
        reader.readAsArrayBuffer(blobObj);
        reader.onload = () => resolve(reader.result);
      });
      promises.push(filePromise);
    }
    setMessageData("Running Compression");
    Promise.all(promises).then((fileContents) => {
      response.data.objects.forEach((item, i) => {
        folderName.file(item.name.split("/").pop(), fileContents[i]);
        // //console.log(fileContents[i]);
      });
      generateZip(folderName);
    });
  };

  const getDirs = (nodes, res = []) => {
    if (nodes.children.length > 0) {
      //console.log("adding",nodes.id,dirs)
      res.push([...dirs, nodes.id]);
      nodes.children.map((node) => getDirs(node, res));
      return res;
    }
    return;
  };
  const deleteFile = (id) => {
    setDialogOpen(true);
    dialogProp.current = {
      onSubmit: deleteFileCaller.bind(this, id),
      handleClose: () => {
        setDialogOpen(false);
      },
      title: "Delete File",
      desc: "All related data and children files will be deleted",
      okText: "Delete",
    };
  };
  const deleteFileCaller = (id) => {
    //console.log("here")
    axios
      .delete("api/get-s3-objects/", {
        data: {
          file_id: id,
        },
      })
      .then((response) => {
        //console.log(response)

        props.setSnackDuration(5000);
        props.setSnackbarData("Deleted File");
        props.setSnackType("info");
        props.setSnackbar(true);
        props.refresh();
        props.fetchFiles();
        setDialogOpen(false);
      })
      .catch((err) => {
        props.setSnackDuration(5000);
        props.setSnackbarData("Could not delete file due to an internal error");

        props.setSnackType("error");
      });
  };
  const deskewcaller = (ids) => {
    props.setSnackDuration(3000);
    props.setSnackbarData("Starting Deskew Process");

    props.setSnackType("info");
    props.setSnackbar(true);
    var names = [];
    ids.forEach((id) => {
      var child_arr = getChildren(data, id);
      names.push(child_arr[0].path);
    });
    // var newDateObj = new Date(timeState.timeobj.getTime());
    // dispatch(setTime({ timeobj: newDateObj }));
    // props.advanceTime(timeState.timeobj);
    names.forEach((name) => {
      axios
        .post("api/deskew/", {
          project_id: props.id,
          s3_path: name,
        })
        .then((response) => {
          props.fetchStatus(props.action.task, true);
          // var totaltime = -1;

          // totaltime += response.data.time_left;

          // totaltime = totaltime / 60 < 1 ? 1 : Math.round(totaltime / 60);
          console.log("Instance data", props);
          if (!props.socketInstance) {
            props.setSnackDuration(3000);
            props.setSnackbarData("Connecting to remote Deskew Server");
            props.setSnackType("info");
            props.setSnackbar(true);
          }
          console.log("Socket Instance", props.socketInstance);
          //Socket client code
          console.log("Socket Request", response.data.request);
          props.socketInstance.emit("data", response.data.request);

          setLoader(false);
        })
        .catch((err) => {
          //console.log(err);
          props.setSnackDuration(5000);
          props.setSnackbarData("Deskewing could not be executed try again");
          props.setSnackType("error");
          props.setSnackbar(true);
        });
    });

    // Promise.all(
    //   names.flat().map((name) => {
    //     return axios
    //       .post("api/deskew/", {
    //         project_id: props.id,
    //         s3_path: name,
    //       })
    //       .then((response) => {
    //         // props.fetchStatus(props.action.task, true);
    //         // var totaltime = -1;

    //         // totaltime += response.data.time_left;

    //         // totaltime = totaltime / 60 < 1 ? 1 : Math.round(totaltime / 60);

    //           props.setSnackDuration(5000);
    //           props.setSnackbarData(
    //             "Connecting to remote Deskew Server"
    //           );
    //           props.setSnackType("info");
    //           props.setSnackbar(true);
    //           //Socket client code
    //           props.socketInstance.emit("data",response.data.request );

    //         setLoader(false);
    //       })
    //       .catch((err) => {
    //         //console.log(err);
    //         props.setSnackDuration(5000);
    //         props.setSnackbarData(
    //           "Deskewing could not be executed try again"
    //         );
    //         props.setSnackType("error");
    //         props.setSnackbar(true);
    //       });
    //   })
    // )
    //   .then((responsearray) => {
    //     //console.log(responsearray);
    //     props.setSnackDuration(5000);
    //     props.setSnackbarData(
    //       "Connecting to remote Deskew Server"
    //     );
    //     props.setSnackType("info");
    //     props.setSnackbar(true);
    //     //Socket client code
    //     props.socketInstance.emit("data",responsearray[0].data.request );

    //     var totaltime = -1;
    //     responsearray.forEach((response) => {
    //       totaltime += response.data.time_left;
    //     });
    //     totaltime = totaltime / 60 < 1 ? 1 : Math.round(totaltime / 60);
    //     var newDateObj = new Date(
    //       timeState.timeobj.getTime() + totaltime * 1000
    //     );
    //     dispatch(setTime({ timeobj: newDateObj }));
    //     props.advanceTime(timeState.timeobj);
    //     props.setSnackDuration(5000);
    //     props.setSnackbarData(
    //       "Deskewing Will take " +
    //         totaltime +
    //         " minutes to complete please check back later at approximately "
    //     );

    //     props.setSnackType("info");
    //     props.setSnackbar(true);
    //     var times = {
    //       hours: timeState.hours,
    //       minutes: timeState.minutes + totaltime,
    //       seconds: timeState.seconds,
    //     };
    //     if (times.seconds > 60) {
    //       times.minutes = times.minutes + times.seconds / 60;
    //       times.seconds = times.seconds % 60;
    //     }
    //     if (times.minutes > 60) {
    //       times.hours = times.hours + times.minutes / 60;
    //       times.minutes = times.minutes % 60;
    //     }

    //     dispatch(setTime(times));
    //     props.stop();

    //     props.fetchFiles();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     if (err.response.data.error == "File does not exist for project") {
    //       props.setSnackbarData("File has already been deskewed");
    //       props.setSnackType("error");
    //       props.setSnackbar(true);
    //     } else {
    //       alert(err);
    //     }
    //   });
  };
  const skipDeskew = (ids) => {
    var names = [];
    ids.forEach((id) => {
      var child_arr = getChildren(data, id);
      names.push(child_arr[0].name);
    });
    names.forEach((name) => {
      axios
        .patch("api/file-status/", {
          file_name: name,
          project_id: props.id,
        })
        .then(() => {
          props.setSnackbarData("Updated status of " + name + " to desekewed");
          props.setSnackType("info");
          props.setSnackbar(true);
          setTimeout(() => {
            props.fetchFiles();
          }, 3000);
        })
        .catch((err) => {
          props.setSnackbarData(
            "Could not update status of " + name + " try again"
          );
          props.setSnackType("error");
          props.setSnackbar(true);
        });
    });
  };
  const getNames = (ids) => {
    setLoader(true);
    // if (props.status == "NOT RUNNING") {
    //   props.setSnackDuration(5000);
    //   props.setSnackbarData(
    //     "Starting Deskew Server please wait for two mins for the server to start"
    //   );
    //   props.setSnackType("info");
    //   props.setSnackbar(true);
    //   axios
    //     .post("api/server-action/", {
    //       id: "i-02c75e75ed9222887",
    //       action: props.action.task,
    //     })
    //     .then((response) => {
    //       var timeout;
    //       if (props.action == "stop") {
    //         timeout = 10000;
    //       } else {
    //         timeout = 0;
    //       }
    //       setTimeout(() => {
    //         props.fetchStatus(props.action.task);
    //       }, timeout);
    //       setTimeout(() => {

    //       }, 110000);
    //     })
    //     .catch((error) => {
    //       //TODO change on timeout debug
    //       props.setSnackDuration(5000);
    //       props.setSnackbarData(
    //         "Deskew Request could not be completed please try again or shutdown the server using the stop button and try again"
    //       );
    //       props.setSnackType("info");
    //       props.setSnackbar(true);
    //       var timeout;
    //       if (props.action == "stop") {
    //         timeout = 120000;
    //       } else {
    //         timeout = 0;
    //       }
    //       setTimeout(() => {
    //         props.fetchStatus(props.action);
    //       }, timeout);
    //     });
    // } else {
    // deskewcaller(ids);
    // }
  };
  const getChildren = (parent, id) => {
    if (parent.id === id) {
      const children = parent;

      return children;
    } else if (parent.children.length > 0) {
      var par = parent.children
        .map((child) => {
          if (getChildren(child, id)) {
            return getChildren(child, id);
          } else {
            return null;
          }
        })
        .filter((e) => e);
      return par;
    }
    // return;
  };
  const getParent = (parent, id, precursor = null) => {
    if (parent.id === id) {
      const children = parent;

      return { index: precursor.children.indexOf(children), parent: precursor };
    } else if (parent.children.length > 0) {
      var par = parent.children
        .map((child) => {
          if (getParent(child, id, parent)) {
            return getParent(child, id, parent);
          } else {
            return null;
          }
        })
        .filter((e) => e);
      return par;
    }
    // return;
  };
  const getNode = (parent, id) => {
    if (parent.id === id) {
      return parent;
    } else if (parent.children.length > 0) {
      var par = parent.children
        .map((child) => {
          if (getChildren(child, id)) {
            return getChildren(child, id);
          }
        })
        .filter((e) => e);
      return par;
    }
    // return;
  };

  const [linkData, setLinkData] = useState("");
  useEffect(() => {
    setData(props.data);

    var lc = Array(props.limit)
      .fill()
      .map((_, i) => i + 1)
      .map((obj) => {
        return obj.toString();
      });
    var res = getDirs(props.data);
    console.log("--------->res", res);
    if (res) {
      setDirs([...dirs, ...res.flat()]);
    } else {
      setDirs([...dirs]);
    }

    var filtered = lc.filter((value) => {
      if (res) {
        if (res.flat(Infinity).includes(value)) {
          return false;
        }
        return true;
      } else {
        return true;
      }
    });
    console.log("----->", lc);
    console.log("----->", filtered);
    setListCount(filtered);
    var idlist = [];
    props.data.children.forEach((id) => {
      var val = id.id.toString();
      idlist.push(val);
    });

    setIds(idlist);
  }, [props.data]);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    var data;
    if (!Array.isArray(nodeIds)) {
      data = [nodeIds.toString()];
    } else {
      data = nodeIds;
    }
    var mod = data.filter((node) => {
      if (dirs.includes(node)) {
        return false;
      } else {
        return true;
      }
    });
    //TODO: API call
    setSelected(mod);

    // axios.post("/  ")

    // axios
    //   .post("/api/deskew-log/" + props.id, {
    //     logs: "specific",
    //     file_name: getNode(data, mod[0])[0].name,
    //   })
    //   .then((response) => {
    //     var logs = response.data;
    //     //console.log(logs);
    //     props.setLogs(logs);
    //   });
    // .catch((error) => {
    //   props.setSnackbarData("Error fetching Logs");
    //   props.setSnackType("error");
    //   props.setSnackbar(true);
    //   // props.setInfoID(-1);
    // });
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) => (oldExpanded.length === 0 ? dirs : []));
  };

  const handleSelectClick = () => {
    console.log(listcount);
    setSelected((oldSelected) => (oldSelected.length === 0 ? listcount : []));
  };
  const verifyFiles = () => {
    //console.log(selected)
    var ids = [...selected];
    var names = [];
    ids.forEach((id) => {
      console.log(data, id);
      var child_arr = Array.isArray(getChildren(data, id))
        ? getChildren(data, id).flat(Infinity)
        : [];
      console.log("Child array", child_arr);
      if (child_arr.length > 0) {
        if (child_arr[0].children.length === 0) {
          names.push(child_arr[0]);
        }
      }
    });
    names = names.filter((obj) => {
      //console.log(obj);
      if (obj.status === "Named") {
        return true;
      } else {
        return false;
      }
    });
    //console.log(names)
    if (names.length > 0) {
      axios
        .patch(`api/sales-project/${props.id}`, {
          files: names.map((obj) => {
            return obj.path;
          }),
          file_status: "Verified",
        })
        .then((response) => {
          props.setFetcher(props.fetcher + 1);

          alert("Updated status of the selection to verified");
        })
        .catch((err) => {
          //console.log(err)
          alert("error updating file status to verified");
        });
    }
  };
  const verifyFocussedFiles = (id) => {
    //console.log(selected)

    var names = [];
    console.log(data, id);
    var child_arr = Array.isArray(getChildren(data, id))
      ? getChildren(data, id).flat(Infinity)
      : [];
    console.log("Child array", child_arr);
    if (child_arr.length > 0) {
      if (child_arr[0].children.length === 0) {
        names.push(child_arr[0]);
      }
    }
    names = names.filter((obj) => {
      //console.log(obj);
      if (obj.status === "Named") {
        return true;
      } else {
        return false;
      }
    });
    console.log(names);
    if (names.length > 0) {
      axios
        .patch(`api/sales-project/${props.id}`, {
          files: names.map((obj) => {
            return obj.path;
          }),
          file_status: "Verified",
        })
        .then((response) => {
          props.setFetcher(props.fetcher + 1);

          // alert("Updated status of the selection to verified");
        })
        .catch((err) => {
          //console.log(err)
          alert("error updating file status to verified");
        });
    }
  };
  const previewHandler = (event, nodeIds) => {
    setCurFocused(nodeIds);

    console.log(nodeIds);
    var node = Array.isArray(getChildren(data, nodeIds))
      ? getChildren(data, nodeIds).flat(Infinity)[0]
      : [];

    // var found = false
    while (node.length > 0) {
      let i = 0;
      // maxIter++;
      for (i = 0; i < node.length; i++) {
        // if(node[i].id){
        //   found = true
        //   node
        // }
        if (node[i].length > 0 || node[i].id) {
          node = node[i];
          break;
        }
      }
    }
    if (
      !Array.isArray(node) &&
      node.name !== null &&
      node.name.endsWith(".pdf")
    ) {
      //console.log("here");
      let pathArr = node.path.split("/");
      axios
        .post("/api/get-single-s3/", {
          file_name: pathArr.pop(),
          dir_name: pathArr.join("/") + "/",
        })
        .then((r) => {
          props.setPrev({
            url: r.data.url,
            name: node.name,
            path: node.path,
            pages: 33,
          });
        })
        .catch((e) => {
          alert(e);
        });
    }
  };
  const renderTree = (nodes) => {
    nodes.last_modified = new Date(nodes.last_modified);
    var colorclass = {};
    // eslint-disable-next-line default-case

    // eslint-disable-next-line default-case
    console.log(nodes);
    // eslint-disable-next-line default-case
    console.warn(nodes.status);
    switch (nodes.status) {
      case "Deskewed":
        colorclass = { bgcolor: "red", color: "black" };
        break;
      case "Splitted":
        colorclass = { bgcolor: "yellow", color: "black" };
        break;
      case "Named":
        colorclass = { bgcolor: "green", color: "black" };
        break;
      case "Verified":
        colorclass = { bgcolor: "green", color: "black" };
        break;
      case "Zipped":
        colorclass = { bgcolor: "gray", color: "black" };
        break;
    }

    const colorobj = colorclass.hasOwnProperty("bgcolor")
      ? {
          backgroundColor: colorclass.bgcolor,
          color: "black",
        }
      : null;
    return (
      <div
        style={colorobj}
        className={
          colorclass.hasOwnProperty("bgcolor")
            ? "flex " + " text-" + colorclass.color
            : "flex"
        }
      >
        {!dirs.includes(nodes.id) && !props.hasOwnProperty("zip") && (
          <DocumentIcon
            onClick={() => {
              // //console.log(nodes.path,nodes.id)
              previewHandler(nodes.path, nodes.id);
            }}
            className="w-5 h-5  inline cursor-context-menu "
          />
        )}
        <TreeItem
          label={
            props.timestamp && !dirs.includes(nodes.id)
              ? nodes.name === "No data"
                ? "No data"
                : nodes.name +
                  "  -  " +
                  nodes.last_modified.getDate() +
                  "/" +
                  (Number(nodes.last_modified.getMonth()) + 1).toString() +
                  "/" +
                  nodes.last_modified.getFullYear() +
                  " at " +
                  nodes.last_modified.getHours() +
                  ":" +
                  nodes.last_modified.getMinutes() +
                  ":" +
                  nodes.last_modified.getSeconds() +
                  (nodes.hasOwnProperty("allocation")
                    ? " - " + nodes.allocation
                    : "")
              : nodes.name +
                (nodes.hasOwnProperty("allocation")
                  ? "-" + nodes.allocation
                  : "")
          }
          key={nodes.id}
          nodeId={nodes.id}
          disabled={
            nodes.hasOwnProperty("allocation")
              ? nodes.allocation === "Shifted"
              : false
          }
        >
          {Array.isArray(nodes.children)
            ? nodes.children.map((node) => renderTree(node))
            : null}
        </TreeItem>
        {props.deletable &&
          nodes.children?.length === 0 &&
          nodes.hasOwnProperty("dbid") && (
            <DeleteOutlineRounded
              onClick={() => {
                deleteFile(nodes.dbid);
              }}
            />
          )}
      </div>
    );
  };

  return (
    //     <div
    // // onKeyDown={()=>{
    // //   //TODO CUSTOM FUNCTION LATER
    // //   if(selected.length===1)
    // //   {
    // //     var {index,parent}=getParent(data,selected[0]).flat(Infinity)[0]
    // //     console.log(index,parent)
    // //     var newIndex=index+1%parent.children.length;
    // //     var newobj=parent.children[newIndex];
    // //     console.log(newIndex,newobj)
    // //     setSelected([newobj.id])

    // //   }

    // //   console.log(getParent(data,selected[0]).flat(Infinity))}}
    //     >
    <Box
      sx={{
        minWidth: 400,
        maxHeight: 400,
        height: "100%",
        flexGrow: 1,
        // width: 'auto',
        overflowY: "auto",
        flexDirection: "column",
      }}
    >
      <HelperDialog open={dialogOpen} {...dialogProp.current} />
      <Box sx={{ mb: 1 }}>
        {loader && (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
            <Typography color="common.white">
              Deskew is starting please wait
            </Typography>
          </Box>
        )}
        {loading && <LinearProgress percentage={progress} />}
        <Button onClick={handleExpandClick}>
          {expanded.length === 0 ? "Expand all" : "Collapse all"}
        </Button>
        <Button onClick={handleSelectClick}>
          {selected.length === 0 ? "Select all" : "Unselect all"}
        </Button>
        {props.zip && (
          <Button onClick={zipDownload}>
            {selected.length === 0 ? "" : "Download Zip"}
          </Button>
        )}
        {selected.length >= 1 && props.verifier ? (
          <Button onClick={verifyFiles}>Mark as verified</Button>
        ) : null}
      </Box>
      {/* <TreeView
        aria-label="controlled"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
        multiSelect
      >
        {renderTree(data)}
      </TreeView> */}
      <TreeView
        multiSelect={!props.hasOwnProperty("zip")}
        aria-label="rich object"
        disabledItemsFocusable={false}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={["root"]}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{
          maxheight: "auto",
          minHeight: 200,
          flexGrow: 1,
          maxWidth: 350,
          overflowY: "auto",
          color: "text.primary",
        }}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        // onNodeSelect={ handleSelect}

        onNodeSelect={handleSelect}
        onNodeFocus={props.hasOwnProperty("verify") ? previewHandler : null}
      >
        {renderTree(data)}
      </TreeView>
      {!loader && props.deskew && selected.length ? (
        <Button
          onClick={() => {
            getNames(selected);
          }}
        >
          Deskew Selection
        </Button>
      ) : null}
      {props.skipdeskew && selected.length ? (
        <Button
          onClick={() => {
            skipDeskew(selected);
          }}
        >
          Skip Deskew
        </Button>
      ) : null}
      {props.downloadable && !message ? (
        <Tooltip title="Download all files that have been deskewed">
          <Button
            onClick={() => {
              setMessageData("Downloading Files......");
              setMessage(true);
              downloadFiles();
            }}
          >
            Download Files
          </Button>
        </Tooltip>
      ) : null}
      {message && <p className="p-2 text-slate-50">{messageData}</p>}
      {link && (
        <Button
          onClick={() => {
            window.load(linkData);
            setLinkData(null);
            setLink(false);
          }}
        >
          Click Here to download the extracted Data as Zip
        </Button>
      )}
    </Box>
    // </div>
  );
}
