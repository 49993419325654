import { createSlice } from "@reduxjs/toolkit";
import { completeGlobalOperation } from "./operationSlice";
export const pageSlice = createSlice({
  name: "pages",
  initialState: {
    value: {
      current: 1,
      max: -1,
      extracted: [],
      realDisplayed: null,
      realDisplayIndex: 1,

    },
  },
  reducers: {
    nextPage: (state) => {
      if (state.value.max != -1 && state.value.current < state.value.max) {
        state.value.current++;
        state.value.realDisplayIndex =
          state.value.realDisplayed[state.value.current - 1];
        // state.value.currentPage =null
      }
    },
    previousPage: (state) => {
      if (state.value.current > 1) {
        state.value.current--;
        state.value.realDisplayIndex =
          state.value.realDisplayed[state.value.current - 1];
        // state.value.currentPage =null
      }
    },
    firstPage: (state) => {
      state.value.current = 1;
      state.value.realDisplayIndex =
        state.value.realDisplayed[state.value.current - 1];
      // state.value.currentPage =null
    },
    lastPage: (state) => {
      state.value.current = state.value.max;
      state.value.realDisplayIndex =
        state.value.realDisplayed[state.value.current - 1];
      // state.value.currentPage =null
    },
    initialize: (state, action) => {
      state.value = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.value = { ...state.value, currentPage: action.payload };
    },
    gotoPage: (state, action) => {
      state.value = {
        ...state.value,
        current: action.payload,
        realDisplayIndex: state.value.realDisplayed[action.payload - 1],
      };
    },
    removeRecentExtracted: (state) => {
      let ext = [...state.value.extracted];
      if (ext.length > 0) {
        let undoInd = ext.at(-1).extIndex;
        ext = ext.filter(function (obj) {
          return obj.extIndex !== undoInd;
        });
      }
      state.value = { ...state.value, extracted: ext };
    },
    
  },
  // extraReducers: (builder) => {
  //     builder.addCase(completeGlobalOperation, (state, action) => {
  //         state.value = action.payload.newPageState
  //     })
  // }
});

export const {
  nextPage,
  previousPage,
  firstPage,
  lastPage,
  initialize,
  setCurrentPage,
  gotoPage,
  removeRecentExtracted,

} = pageSlice.actions;

export default pageSlice.reducer;
