import React, { useState, Suspense, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import { Navbar } from "./components/Navbar/Navbar";
import Login from "./components/Login/Login";
import Dashboard from "./containers/Dashboard";
import { useSelector } from "react-redux";
import RequireAuth from "./containers/RequireAuth";
import { DogImage } from "./components/Dashboard/LeftDrawer/LeftDrawer";
import PdfRename from "./components/PdfRename/PdfRename";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import blue from "@mui/material/colors/blue";
import PdfVerify from "./containers/PdfVerify";
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";
import SessionExpired from "./components/SessionExpired/SessionExpired";

const PdfEdit = React.lazy(() => import("./containers/PdfEdit"));

const theme = createTheme({
  palette: {
    primary: blue,
    mode: "dark",
  },
});

function App() {
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className="flex flex-column h-screen bg-slate-800 ">
        {/* Navbar */}
        {/* <Navbar /> */}
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route
            path="/rename"
            element={
              <RequireAuth>
                <PdfRename />
              </RequireAuth>
            }
          />

          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
          <Route
            path="/editor"
            element={
              <RequireAuth>
                <Suspense fallback={LoadingComponent}>
                  <PdfEdit />
                </Suspense>
              </RequireAuth>
            }
          />
          <Route
            path="/verify"
            element={
              <RequireAuth>
                <PdfVerify />
              </RequireAuth>
            }
          />
          <Route path="/expired" element={<SessionExpired />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;
