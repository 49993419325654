/* eslint-disable import/no-anonymous-default-export */
import { io } from "socket.io-client";
import React, { useState, useEffect, useRef } from "react";
import {
  Fab,
  Tab,
  Tabs,
  Box,
  Button,
  Divider,
  AppBar,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTimer } from "react-timer-hook";

import { styled } from "@mui/material/styles";
import { ChevronLeftRounded, DeleteOutlineRounded } from "@mui/icons-material";
import FlagTable from "./ProjectInfo/FlagTable";
import axios from "axios";
import ConfirmDialog from "./ProjectInfo/ConfirmDialog";
import ControlledTreeView from "../../FileTree/FileTree";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import StopIcon from "@mui/icons-material/Stop";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import RenamePreview from "../../PdfRename/RenamePreview";
import JSZip, { files } from "jszip";
import FileSaver from "file-saver";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemText from "@mui/material/ListItemText";
import { setTime } from "../../../slice/timeSlice";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";

function SlideTransition(props) {
  return <Slide {...props} />;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function TagMaker(name) {
  const Tag = name;
  if (name == "StopIcon") return <StopIcon />;
  else if (name == "PlayCircleFilledWhiteIcon")
    return <PlayCircleFilledWhiteIcon />;
}
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default (props) => {
  const authState = useSelector((state) => state.auth);

  const dayInMonthComparator = (v1, v2) => {
    return Date.parse(v1) - Date.parse(v2);
  };
  const timeState = useSelector((state) => state.time);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   axios
  //     .get("api/deskew-time/")
  //     .then((response) => {
  //       console.log(response.data);
  //       var cur_date = new Date();

  //       var d_date = new Date(response.data.time_left);
  //       const diff = dayInMonthComparator(d_date, cur_date);
  //       console.log(d_date, cur_date);
  //       console.log(diff);
  //       if (diff > 0) {
  //         // const val_add_days = diff > 86400000 ? diff % 86400000 : 0;
  //         // var cur_time = {
  //         //   hours: cur_date.toLocaleString().split(":")[0].split(", ")[1],
  //         //   minutes: cur_date.toLocaleString().split(":")[1],
  //         //   seconds: cur_date.toLocaleString().split(":")[2],
  //         // };

  //         // var d_time = {
  //         //   hours: d_date.toLocaleString().split(":")[0].split(", ")[1],
  //         //   minutes: d_date.toLocaleString().split(":")[1],
  //         //   seconds: d_date.toLocaleString().split(":")[2],
  //         // };
  //         // var timer_val = {
  //         //   hours: d_time.hours - cur_time.hours,
  //         //   minutes: d_time.minutes - cur_time.minutes,
  //         //   seconds: d_time.seconds - cur_time.seconds,
  //         //   days: val_add_days,
  //         // };

  //         dispatch(setTime({ timeobj: d_date }));
  //       } else {
  //         dispatch(setTime({ timeobj: cur_date }));
  //       }
  //     })
  //     .catch((error) => {
  //       alert(error);
  //     });
  // }, []);
  // useEffect(() => {}, [timeState.timeobj]);

  const [snackduration, setSnackDuration] = useState(5000);
  const [listcount, setListCount] = useState(0);
  const [buttonvis, setButtonVis] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [prev, setPrev] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [dialogOpen, setDialog] = useState(false);
  const [cats, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [openSnackBar, setSnackbar] = useState(false);
  const [snackBardata, setSnackbarData] = useState("");
  const [snackType, setSnackType] = useState("info");
  let [logs, setLogs] = useState([]);
  const [status, setStatus] = useState("Fetching...");
  const [tablevis, setTableVis] = useState(false);
  const [update, setUpdate] = useState(0);
  const [socketInstance, setSocketInstance] = useState("");
  const [loading, setLoading] = useState(false);
  const [deskewfilename, setDeskewFilename] = useState("");

  const [action, setAction] = useState({
    type: "STOP",
    icon: "StopIcon",
    btype: "error",
    task: "stop",
    message: "Stopping Deskew Server",
  });
  const stats = useRef({
    project_parent_files: { splitted: 0, total: 1 },
    project_total_files: { named: 0, total: 1 },
  });
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };
  const [deskeweddata, setDeskeweddata] = useState({
    name: "No data for selected category",
    children: [],
    id: "0000",
  });
  const [data, setData] = useState({
    name: "No data for selected category",
    children: [],
    id: "0000",
  });

  // setInterval(()=>{
  //   fetchStatus('start',true)
  // },10000)
  // const executeDeskew = (files) => {
  //   var config = { project_id: props.id, file_name, category };

  //   axios
  //     .post("/api/deskew/", config, {
  //       headers: {
  //         Authorization: `Token ${props.token}`,
  //       },
  //     })
  //     .then((response) => {})
  //     .catch((error) => {});
  // };
  const runserver = (action, msg) => {
    setSnackDuration();
    setSnackbarData(msg);
    setSnackType("info");
    setSnackbar(true);
    // if (action === "stop") {
    //   setTableVis(false);
    // }
    axios
      .post("api/server-action/", {
        id: "i-0aeae3b65c1ff9487",
        action: action,
      })
      .then((response) => {
        var timeout;
        if (action == "stop") {
          timeout = 10000;
        } else {
          timeout = 0;
        }
        setTimeout(() => {
          fetchStatus(action);
        }, timeout);
      })
      .catch((error) => {
        //TODO change on timeout debug
        // setSnackDuration(5000);
        // setSnackbarData(error.response.data.error);
        // setSnackType("error");
        // setSnackbar(true);
        var timeout;
        if (action == "stop") {
          timeout = 120000;
        } else {
          timeout = 0;
        }
        setTimeout(() => {
          fetchStatus(action);
        }, timeout);
      });
  };

  const fetchFilesOld = (cat, dir_name, setData, ftype = true) => {
    console.log("here");
    var ctr = 0;

    var obj = { name: cat, id: ctr.toString() };
    ctr++;
    if (cat) {
      var pending = {};

      axios
        .post("api/get-s3-tree/", {
          dir_name: dir_name,
          show_all: true,
        })
        .then((response) => {
          console.log("Calling s3 get");
          var paths = response.data.file_names;
          var children = [];
          console.log("Resp", response.data);
          if (paths) {
            paths.forEach((path) => {
              var file_name = path.split("/").pop();
              if (!file_name.includes("Deskew")) {
                children.push({
                  name: file_name,
                  children: [],
                  id: ctr.toString(),
                  path,
                  // key,
                  // del
                });
                ctr++;
              }
            });
          }
          obj = { ...obj, children: children };

          var name = "project/" + props.id + "/working/" + cat + "/deskewed/";
          var ctr1 = 0;

          var obj1 = { name: cat, id: ctr.toString() };
          ctr1++;
          axios
            .post("api/get-s3-tree/", {
              dir_name: name,
              show_all: true,
            })
            .then((response) => {
              var paths = response.data.file_names;
              var children = [];
              if (paths) {
                paths.forEach((path) => {
                  var file_name = path.split("/").pop();
                  if (!file_name.includes("Deskew")) {
                    children.push({
                      name: file_name,
                      children: [],
                      id: ctr1.toString(),
                      path,
                    });
                    ctr1++;
                  }
                });
              }

              obj1 = { ...obj1, children: children };

              var d2 = obj1.children.map((obj) => {
                if (obj.name.includes("_deskewed")) {
                  return obj.name.split("_deskewed")[0];
                } else {
                  return obj.name.split(".pdf")[0];
                }
              });

              var count = 0;
              var modified = obj.children.map((object) => {
                console.log(d2.includes(object.name.split(".pdf")[0]));
                if (d2.includes(object.name.split(".pdf")[0])) {
                  return { ...object, disabled: true };
                } else {
                  count++;
                  return { ...object, disabled: false };
                }
              });

              axios
                .post("api/get-s3-tree/", {
                  dir_name: dir_name,
                  show_all: false,
                })
                .then((response) => {
                  pending = {
                    path: response.data.file_names,
                    ids: response.data.file_ids,
                  };
                  var withdb = modified.map((child) => {
                    if (pending.path.includes(child.path)) {
                      console.log("contains db ", {
                        ...child,
                        dbid: pending.ids[pending.path.indexOf(child.path)],
                      });
                      return {
                        ...child,
                        dbid: pending.ids[pending.path.indexOf(child.path)],
                      };
                    } else {
                      return child;
                    }
                  });
                  setData({
                    ...obj,
                    children: withdb,
                    name: `${obj.name} - ${modified.length} Files ${count} Files Pending`,
                  });
                  console.log("------>wdb", withdb);
                  console.log("------>modified", modified);
                  setListCount(withdb.length + 1);
                });
            })
            .catch((error) => {
              console.log("------>error", error);
              setData({
                ...obj,
                name: `${obj.name} - ${obj.children.length} Files`,
              });
              console.log("Data passed", {
                ...obj,
                name: `${obj.name} - ${obj.children.length} Files`,
              });
              setListCount(obj.children.length + 1);
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("------>error", error);
        });
    }
  };
  const fetchFiles = (cat, dir_name, setData, ftype = true) => {
    console.log("here");
    var ctr = 0;

    var obj = { name: cat, id: ctr.toString() };
    ctr++;
    if (cat) {
      axios
        .post("api/get-s3-tree/", {
          dir_name: dir_name,
          show_all: true,
        })
        .then((response) => {
          console.log("Calling s3 get");
          var paths = response.data.file_names;
          var children = [];

          if (paths) {
            paths.forEach((path, index) => {
              var file_name = path.split("/").pop();
              children.push({
                name: file_name,
                children: [],
                id: ctr.toString(),
                dbid: response.data.file_ids[index],
                allocation: response.data.allocation_status[index],
                path,
                status: response.data.status[index],
              });
              ctr++;
            });
          }
          var obj1 = { name: cat, id: ctr.toString() };
          obj1 = { ...obj1, children: children };
          setData({
            ...obj1,
            children: children,
            name: `${cat}`,
          });
          console.log("--------->Final Object", {
            ...obj1,
            children: children,
            name: `${cat}`,
          });
          setListCount(children.length + 1);
        })
        .catch((error) => {
          console.log("------>error", error);
        });
    }
  };
  const fetchStatus = (action, init = false) => {
    //TODO ENV instance Id
    axios
      .get("api/server-status/")
      .then((response) => {
        console.log(response);
        var fstatus = response.data.instance
          .filter((obj) => {
            if (obj.InstanceId == "i-0aeae3b65c1ff9487") {
              return true;
            }
          })
          .map((obj) => {
            return obj.InstanceState;
          });

        setStatus(fstatus[0].toUpperCase());
        if (!init) {
          setSnackDuration(5000);

          setSnackbarData("Server has been " + action + "ed successfully");
          setSnackType("success");
          setSnackbar(true);
        }

        var config;
        if (fstatus !== status) {
          config =
            fstatus !== "running"
              ? {
                  type: "START",
                  icon: "PlayCircleFilledWhiteIcon",
                  btype: "success",
                  task: "start",
                  message: "Starting Deskew Server",
                }
              : {
                  type: "STOP",
                  icon: "StopIcon",
                  btype: "error",
                  task: "stop",
                  message: "Stopping Deskew Server",
                };
        }
        if (fstatus !== "running") {
          setTableVis(false);
        } else {
          setTableVis(true);
        }
        setAction(config);
        if (!init && fstatus !== "running") {
          setTimeout(() => {
            setButtonVis(false);
          }, 10000);
        } else {
          setButtonVis(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackDuration(5000);
        setStatus("Could not fetch status reload to get the server status");
        setSnackbarData(
          "Could not fetch server status please refresh the page"
        );
        setSnackType("error");
        setSnackbar(true);
      });
  };
  // useEffect(() => {
  //   // console.log(authState.token);
  //   // const socket = io(`http://localhost:5001/?token=${authState.token}`, {
  //   //   transports: ["websocket"],
  //   //   cors: {
  //   //     origin: "https://nudoc.pdf-backend.de/",
  //   //   },
  //   // });

  //   // socket.on("connect", (data) => {
  //   //   console.log(data);
  //   // });

  //   // socket.on("disconnect", (data) => {
  //   //   console.log(data);
  //   // });

  //   // socket.on("data", (data) => {
  //   //   console.log(data);
  //   // });

  //   // setSocketInstance(socket);
  //   // setLoading(false);
  //   // return function cleanup() {
  //   //   socket.disconnect();
  //   // };
  // }, []);
  useEffect(() => {
    axios
      .get("/api/project-stats/" + props.id)
      .then((r) => {
        stats.current = r.data;
        setStatsLoading(false);
      })
      .catch(alert);
    axios
      .get("/api/root-category/", {
        params: { project_id: props.id },
      })
      .then((resp) => {
        console.log(resp);
        var cats = [];
        resp.data.forEach((obj) => {
          if (!cats.includes(obj.category)) {
            cats.push(obj.category);
          }
        });

        setCategories(cats);
      })
      .catch((err) => {
        console.log(err);
      });

    fetchStatus(action.task, true);
  }, [update]);

  return (
    <div className="w-9/12">
      {!prev && (
        <div className="w-full m-8 mt-16 space-y-6 ">
          <Snackbar
            sx={{ width: 600 }}
            anchorOrigin={{
              height: 100,
              vertical: "top",
              horizontal: "center",
            }}
            TransitionComponent={SlideTransition}
            open={openSnackBar}
            onClose={handleSnackbarClose}
            autoHideDuration={5000}
          >
            <Alert
              variant="filled"
              severity={snackType}
              sx={{
                width: "100%",
                fontSize: 20,
              }}
            >
              {snackBardata}
            </Alert>
          </Snackbar>

          <Fab onClick={() => props.setInfoID(-1)}>
            <ChevronLeftRounded />
          </Fab>
          <div className="flex  justify-between">
            <div>
              <p className=" text-2xl text-slate-300 tracking-widest font-bold font-sans">
                PROJECT INFO
              </p>

              <ConfirmDialog
                open={dialogOpen}
                handleClose={() => setDialog(false)}
                id={props.id}
                handleComplete={() => {
                  props.setSnackbarData("Project Deleted");
                  props.setSnackType("info");
                  props.setSnackbar(true);
                  props.setInfoID(-1);
                }}
                updateTable={props.updateTable}
              />
              <Box sx={{ width: "100%" }}>
                <div className="mt-2">
                  <p className="text-slate-50">
                    Splitting [
                    {statsLoading
                      ? "--"
                      : stats.current.project_parent_files.splitted}
                    /
                    {statsLoading
                      ? "--"
                      : stats.current.project_parent_files.total}
                    ]
                  </p>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      statsLoading
                        ? 0
                        : (100 * stats.current.project_parent_files.splitted) /
                          stats.current.project_parent_files.total
                    }
                  />
                </div>
                <div className="mt-2">
                  <p className="text-slate-50">
                    Renaming [
                    {statsLoading
                      ? "--"
                      : stats.current.project_parent_files.named}
                    /
                    {statsLoading
                      ? "--"
                      : stats.current.project_parent_files.total}
                    ]
                  </p>
                  <BorderLinearProgress
                    variant="determinate"
                    value={
                      statsLoading
                        ? 0
                        : (100 * stats.current.project_parent_files.named) /
                          stats.current.project_parent_files.total
                    }
                  />
                </div>
              </Box>
            </div>
            <div>
              {status === "RUNNING" && logs.length > 0 ? (
                <List
                  sx={{
                    height: "auto",
                    maxHeight: 200,
                    border: 2,
                    maxWidth: 350,
                    overflowY: "auto",
                    color: "text.primary",
                  }}
                >
                  <ListSubheader>Logs for the selected file</ListSubheader>

                  {logs.map((log) => {
                    var val = log.data;
                    return (
                      <ListItem>
                        <ListItemText className="w-full" primary={val} />
                      </ListItem>
                    );
                  })}
                </List>
              ) : null}
            </div>
            <div>
              {/* <span className=" text-xl text-slate-300 tracking-widest font-bold font-sans">
                DESKEW SERVER STATUS :
              </span>
              <span className=" text-3xl text-slate-50 tracking-widest font-bold font-sans ">
                {" "}
                {status}
              </span> */}
              {/* <div>
                <Button
                  disabled={buttonvis}
                  color={action.btype}
                  startIcon={TagMaker(action.icon)}
                  onClick={() => {
                    setButtonVis(true);
                    runserver(action.task, action.message);
                  }}
                  variant="contained"
                >
                  {action.type}
                </Button>
              </div>
              {deskewfilename
                ? deskewfilename.trim() !== "" && (
                    <div>
                      <span className=" text-xl text-slate-300 tracking-widest font-bold font-sans">
                        Current File Under Deskew :
                      </span>
                      <span className=" text-3xl text-slate-50 tracking-widest font-bold font-sans ">
                        {" "}
                        {deskewfilename}
                      </span>
                    </div>
                  )
                : null} */}
              {/* {isRunning ? (
                <>
                  <span className=" text-xl text-slate-50 tracking-widest font-bold font-sans ">
                    Current Waiting Time For Deskew :
                  </span>
                  <span className="text-3xl text-slate-50 tracking-widest font-bold font-sans">
                    {days} Days {hours} Hours {minutes} Minutes {seconds}{" "}
                    Seconds
                  </span>
                </>
              ) : null} */}
            </div>
            <div>
              <Button
                color="error"
                startIcon={<DeleteOutlineRounded />}
                onClick={() => setDialog(true)}
                variant="contained"
              >
                {" "}
                DELETE PROJECT
              </Button>
            </div>
          </div>

          <div>
            <Tabs
              value={activeTab}
              onChange={(e, newVal) => setActiveTab(newVal)}
              aria-label="basic tabs example"
            >
              <Tab label="FILES" {...a11yProps(0)} />

              <Tab label="FLAGS" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={activeTab} index={0}>
              <div className="flex flex-col space-y-10">
                {/* <div className="border-2 w-1/3 flex-1">
              <AppBar color="primary" position="static" enableColorOnDark>
                <Toolbar>
                  <Typography variant="h6" noWrap component="div">
                    Original Files
                  </Typography>
                </Toolbar>
              </AppBar>
              <ControlledTreeView
                data={data}
                token={props.token}
              ></ControlledTreeView>
            </div>

            <div className="border-2 w-1/3 flex-1">
              <AppBar position="static" enableColorOnDark>
                <Toolbar>
                  <Typography variant="h6" noWrap component="div">
                    Files to be deskwed
                  </Typography>
                </Toolbar>
              </AppBar>
              <ControlledTreeView
                data={data}
                token={props.token}
              ></ControlledTreeView>
            </div> */}
                <div className="w-20">
                  <Tooltip title="Refresh for latest File status">
                    <Button
                      color="primary"
                      variant="contained"
                      aria-label="refresh"
                      startIcon={<RefreshIcon />}
                      onClick={() => {
                        fetchFiles(
                          selectedCategory,
                          "project/" +
                            props.id +
                            "/working/" +
                            selectedCategory +
                            "/deskewed/",
                          setData
                        );
                      }}
                    >
                      <Typography>Refresh Data</Typography>
                    </Button>
                  </Tooltip>
                </div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={cats}
                  value={selectedCategory}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      setSelectedCategory(newValue);
                      fetchFiles(
                        newValue,
                        "project/" +
                          props.id +
                          "/working/" +
                          newValue +
                          "/deskewed/",
                        setData
                      );
                      // fetchFiles(
                      //   newValue,
                      //   "project/" +
                      //     props.id +
                      //     "/working/" +
                      //     newValue +
                      //     "/deskewed/",
                      //   setDeskeweddata,
                      //   false
                      // );
                    }
                  }}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Category" />
                  )}
                />
                <div className="flex flex-row">
                  <div className="border-2 w-1/3 flex-1">
                    <AppBar position="static" enableColorOnDark>
                      <Toolbar>
                        <Typography variant="h5" noWrap component="div">
                          File Tree
                        </Typography>
                      </Toolbar>
                    </AppBar>
                    {!loading && (
                      <ControlledTreeView
                        refresh={() => {
                          fetchFiles(
                            selectedCategory,
                            "project/" +
                              props.id +
                              "/working/" +
                              selectedCategory +
                              "/deskewed/",
                            setData
                          );
                        }}
                        deletable
                        data={data}
                        token={props.token}
                        id={props.id}
                        setSnackbar={setSnackbar}
                        setSnackbarData={setSnackbarData}
                        setSnackType={setSnackType}
                        setSnackDuration={setSnackDuration}
                        // socketInstance={socketInstance}
                        // deskew={true}
                        limit={listcount}
                        setPrev={setPrev}
                        setLogs={setLogs}
                        setDeskewFilename={setDeskewFilename}
                        logs={logs}
                        category={selectedCategory}
                        downloadable
                        runner={runserver}
                        action={action}
                        status={status}
                        fetchStatus={fetchStatus}
                        // skipdeskew={true}
                        setUpdate={setUpdate}
                        update={update}
                        fetchFiles={() => {
                          fetchFiles(
                            selectedCategory,
                            "project/" +
                              props.id +
                              "/working/" +
                              selectedCategory +
                              "/deskewed/",
                            setData
                          );
                        }}
                      ></ControlledTreeView>
                    )}
                  </div>
                  {/* <div className="border-2 w-1/3 flex-1">
                    <AppBar position="static" enableColorOnDark>
                      <Toolbar>
                        <Typography variant="h6" noWrap component="div">
                          Files Already deskewed
                        </Typography>
                      </Toolbar>
                    </AppBar>
                    <ControlledTreeView
                      data={deskeweddata}
                      token={props.token}
                      id={props.id}
                      deskew={false}
                      verify={true}
                      setPrev={setPrev}
                      downloadable
                      category={selectedCategory}
                    ></ControlledTreeView>
                  </div> */}
                </div>
              </div>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <FlagTable
                project_id={props.id}
                setSnackbar={setSnackbar}
                setSnackType={setSnackType}
                setSnackbarData={setSnackbarData}
                setSnackDuration={setSnackDuration}
              />
            </TabPanel>
          </div>
        </div>
      )}
      <div className="width-full">
        {prev !== null && (
          <RenamePreview
            width="full"
            close={() => setPrev(null)}
            curTask={3}
            {...prev}
          />
        )}
      </div>
    </div>
  );
};
