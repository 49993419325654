import React, { useState, useEffect, useMemo } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { AllotDialog } from "./AllotDialog";
import { useDemoData } from "@mui/x-data-grid-generator";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import SettingsIcon from "@mui/icons-material/Settings";
import InfoIcon from "@mui/icons-material/Info";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockIcon from "@mui/icons-material/Lock";
import Box from "@mui/material/Box";
import { darken, lighten } from "@mui/material/styles";
import { LinearProgress, IconButton } from "@mui/material";
import { DefineDialog } from "./DefineDialog";
import ProjectData from "./ProjectTasks";
import { AllotFiles } from "./FileAllocation/AllotFiles";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { FileUpload, HowToReg } from "@mui/icons-material";
import { convertToObject } from "typescript";
import { UploadDialog } from "./UploadDialog";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
const dateToStr = (val) => {
  let newVal = val.getDate();
};

const labelToDisabled = (label, index) => {
  let val = 0;
  if (label === "closed") {
    val = -1;
  } else if (label === "Naming pattern pending") {
    val = 1;
  } else if (label === "File upload pending") {
    val = 2;
  } else if (label === "File allocation pending") {
    val = 3;
  } else if (label === "In progress") {
    val = 4;
  } else if (label === "Completed") {
    val = -1;
  } else if (label === "Locked") {
    val = -1;
  }

  return !(index <= val);
};

const dayInMonthComparator = (v1, v2) => Date.parse(v1) - Date.parse(v2);
function DisplayTable(props) {
  //  state

  const [allotOpen, setAllotOpen] = useState(false);
  const [defineOpen, setDefineOpen] = useState(false);
  const [configRecord, setConfigRecord] = useState(null);
  const [dataOpen, setDataOpen] = useState(false);
  const [infoState, setInfo] = useState(false);
  const [allotFiles, setAllotFiles] = useState(false);
  const [uploadState, setUploadOpen] = useState(false);
  const authState = useSelector((state) => state.auth);
  // const [defineController, setDefineController] = useState(false);
  const [userPriv, setUserPriv] = useState({});
  const [locking, setLocking] = useState([]);
  const [update, setUpdate] = useState(0);
  //handlers
  //opens add people to project dialog
  const handleConfig = (id) => {
    const record = props.tableData[id];
    setConfigRecord(record);
    setAllotOpen(true);
  };

  //FileAllotment
  const handleFile = (id) => {
    const record = props.tableData[id];
    setConfigRecord(record);
    setAllotFiles(true);
  };

  //upload files dialog
  const handleUpload = (id) => {
    const record = props.tableData[id];
    setConfigRecord(record);
    setUploadOpen(true);
  };
  //Project Config Definition dialog
  const handleUserAllotment = (id) => {
    const record = props.tableData[id];
    setConfigRecord(record);
    setDefineOpen(true);
  };
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

  //columns, dependent on project status, user permissions ,and user privilege(superuser?)
  const getColumns = () => {
    const generalA = [
      { field: "name", headerName: "Project Name", width: 150 },
      { field: "client", headerName: "Client Name", width: 150 },
      {
        field: "created_at",
        headerName: "Date Created",
        width: 200,
        sortComparator: dayInMonthComparator,
        type: "dateTime",
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: "updated_at",
        headerName: "Last Modified",
        width: 200,
        sortComparator: dayInMonthComparator,
        type: "dateTime",
        valueGetter: ({ value }) => value && new Date(value),
      },
      { field: "status", headerName: "Status", width: 100 },

      {
        field: "rep1_name",
        headerName: "Representative",
        width: 200,
        renderCell: (params) => {
          return (
            <div>{params.row.rep1_name + "-" + params.row.rep1_mobile}</div>
          );
        },
      },
    ];
    const adminCol = [
      {
        field: "allocate_personnel",
        headerName: "Allocate Users",
        width: 75,
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="allocate_users"
              onClick={() => {
                handleConfig(params.row.table_id);
              }}
              disabled={
                labelToDisabled(params.row.status, 0) ||
                (authState.privilege !== 1 &&
                  props.userPriv[params.row.id].c_allocate === undefined)
              }
            >
              <SettingsIcon index={params.row.table_id} />
            </IconButton>
          );
        },
      },
      {
        field: "configuration",
        headerName: "Configuration",
        width: 75,
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="configure"
              onClick={() => {
                handleUserAllotment(params.row.table_id);
              }}
              disabled={
                labelToDisabled(params.row.status, 1) ||
                (authState.privilege !== 1 &&
                  props.userPriv[params.row.id].c_allocate === undefined)
              }
            >
              <HowToReg index={params.row.table_id} />
            </IconButton>
          );
        },
      },

      {
        field: "file_upload",
        headerName: "Upload Files",
        width: 75,
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="upload_files"
              onClick={() => {
                handleUpload(params.row.table_id);
              }}
              disabled={
                labelToDisabled(params.row.status, 2) ||
                (authState.privilege !== 1 &&
                  props.userPriv[params.row.id].c_upl_str === undefined)
              }
            >
              <FileUpload index={params.row.table_id} />
            </IconButton>
          );
        },
      },
      {
        field: "file_allot",
        headerName: "File Allocation",
        width: 75,
        sortable: false,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="allocate_files"
              onClick={() => {
                handleFile(params.row.table_id);
              }}
              disabled={
                labelToDisabled(params.row.status, 3) ||
                (authState.privilege !== 1 &&
                  props.userPriv[params.row.id].c_allot === undefined)
              }
            >
              <InsertDriveFileIcon index={params.row.table_id} />
            </IconButton>
          );
        },
      },
      {
        field: "is_locked",
        headerName: "Lock",
        width: 75,
        renderCell: (params) => {
          return (
            <IconButton
              color={params.row.is_locked ? "primary" : undefined}
              onClick={() => {
                var lock_stat = locking;
                lock_stat[params.row.id] = true;
                setLocking(lock_stat);
                lockProject(
                  !params.row.is_locked,
                  params.row.id,
                  params.row.table_id
                );
              }}
              disabled={authState.privilege < 1}
            >
              {
                <Box sx={{ m: 1, position: "relative" }}>
                  {params.row.is_locked ? (
                    <LockIcon />
                  ) : (
                    <LockOpenRoundedIcon />
                  )}
                  {locking[params.row.id] && (
                    <CircularProgress
                      size={50}
                      sx={{
                        position: "absolute",
                        top: -6,
                        left: -9,
                        zIndex: 1,
                      }}
                    />
                  )}
                </Box>
              }
              {}
            </IconButton>
          );
        },
      },
    ];
    const generalB = [
      {
        field: "tasks",
        headerName: "Tasks",
        width: 75,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="project_tasks"
              onClick={() => {
                displayTasks(params.row.table_id);
              }}
              disabled={
                labelToDisabled(params.row.status, 3) ||
                (authState.privilege !== 1 &&
                  props.userPriv[params.row.id].c_split === undefined &&
                  props.userPriv[params.row.id].c_name === undefined)
              }
              // disabled={labelToDisabled(params.row.status, 4)}
            >
              <PlayCircleRoundedIcon index={params.row.table_id} />
            </IconButton>
          );
        },
      },
      {
        field: "projectInfo",
        headerName: "Info",
        width: 75,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="info"
              onClick={() => {
                props.setInfoID(params.row.id);
              }}
              disabled={authState.privilege < 1}
            >
              <InfoIcon index={params.row.table_id} />
            </IconButton>
          );
        },
      },
    ];

    return [...generalA, ...generalB, ...adminCol];
  };
  const columns = getColumns();
  const displayTasks = (id) => {
    const record = props.tableData[id];
    setConfigRecord(record);
    setInfo(true);
  };

  const openNext = () => {
    setAllotOpen(false);
    setDefineOpen(true);
  };
  const lockProject = (shouldLock, row, tableID) => {
    axios
      .post("api/lock-project/" + row, {
        is_locked: shouldLock,
      })
      .then((response) => {
        let newData = [...props.tableData];
        newData[tableID].is_locked = shouldLock;
        updateProj(row, tableID, true);
        var lock_stats = [...locking];
        lock_stats[row] = !lock_stats[row];
        setLocking(lock_stats);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // useEffect(() => {
  //   let checkBoxIds = [
  //     "c_allocate",
  //     "c_upl_str",
  //     "c_allot",
  //     "c_revert",
  //     "c_split",
  //     "c_name",
  //     "c_dload",
  //     "c_verify",
  //     "c_flag",
  //   ];
  //   if (authState.privilege === 1)
  //     axios
  //       .get("api/sales-project-list/", {
  //         headers: {
  //           Authorization: "Token " + authState.token,
  //         },
  //       })
  //       .then(function (response) {
  //         console.log(response.data);
  //         const parsed_data = response.data.map((row, i) => {
  //           return { ...row, file_allot: true, table_id: i };
  //         });
  //         props.modifyTable(parsed_data);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   else {
  //     axios
  //       .get("api/user-project-list/?username=" + authState.name, {
  //         headers: {
  //           Authorization: "Token " + authState.token,
  //         },
  //       })
  //       .then(function (response) {
  //         console.log(response.data);
  //         const parsed_data = response.data.project.map((row, i) => {
  //           return { ...row, file_allot: true, table_id: i };
  //         });
  //         props.modifyTable(parsed_data);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //       });
  //   }
  // }, []);

  //updates project, call after submitting a form
  const updateProj = (id, table_id, to_lock = false) => {
    console.log("calling here");
    axios
      .get("/api/project/" + id)
      .then((r) => {
        let newT = [...props.tableData];
        newT[table_id] = r.data.details;
        newT[table_id].table_id = table_id;
        props.modifyTable(newT);
        // if (to_lock) {
        //   var lock_stat = [...locking];
        //   lock_stat[id] = false;
        //   setLocking(lock_stat);
        // }
      })
      .catch((e) => alert(e));
  };
  return (
    <div style={{ height: 400 }}>
      <Box
        sx={{
          height: 700,
          width: "100%",
          "& .super-app-theme--Fup": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.warning.light,
                theme.palette.mode
              ),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.warning.light,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--Npp": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.warning.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--Rap": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.warning.dark,
                theme.palette.mode
              ),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.warning.dark,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--Fap": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--Ip": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.info.main, theme.palette.mode),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.info.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--C": {
            bgcolor: (theme) =>
              getBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.success.main,
                  theme.palette.mode
                ),
            },
          },
          "& .super-app-theme--L": {
            bgcolor: (theme) =>
              getBackgroundColor(theme.palette.error.main, theme.palette.mode),
            "&:hover": {
              bgcolor: (theme) =>
                getHoverBackgroundColor(
                  theme.palette.error.main,
                  theme.palette.mode
                ),
            },
          },
        }}
      >
        <DataGrid
          rowsPerPageOptions={[3, 5, 10]}
          disableSelectionOnClick={true}
          rows={props.tableData}
          columns={columns}
          loading={props.tableLoading}
          pageSize={10}
          editMode="row"
          getRowClassName={(params) => {
            const splitted = params.row.status.split(" ");
            const result = splitted.reduce((prev, current) => {
              return prev.concat(current.charAt(0));
            }, "");

            return `super-app-theme--${result}`;
          }}
          components={{
            Toolbar: GridToolbar,
          }}
          onSelectionModelChange={(itm) => console.log(itm)}
        />
      </Box>

      {configRecord !== null && configRecord !== undefined && allotOpen && (
        <AllotDialog
          handleClose={() => {
            setAllotOpen(false);
            setConfigRecord(null);
          }}
          handleComplete={openNext}
          open={allotOpen}
          update={updateProj}
          key={configRecord && configRecord.id}
          projectData={configRecord}
          setSnackbar={props.setSnackbar}
          setSnackbarData={props.setSnackbarData}
          setSnackType={props.setSnackType}
        />
      )}
      {/*
      <ProjectData
        handleClose={() => {
          setDataOpen(false);
          setConfigRecord(null);
        }}
        handleComplete={openNext}
        open={allotOpen}
        projectID={1}
        projectData={configRecord}
      /> */}

      {configRecord !== null && configRecord !== undefined && defineOpen && (
        <DefineDialog
          // defineController={defineController}
          // setDefineController={setDefineController}
          handleClose={() => {
            console.log("here");
            setDefineOpen(false);
            // setConfigRecord(null);
          }}
          update={updateProj}
          open={defineOpen}
          projectData={configRecord}
          setSnackbar={props.setSnackbar}
          setSnackbarData={props.setSnackbarData}
          setSnackType={props.setSnackType}
        />
      )}
      {configRecord !== null && configRecord !== undefined && infoState && (
        <ProjectData
          open={infoState}
          handleClose={() => {
            setInfo(false);
            setConfigRecord(null);
          }}
          record={configRecord}
          projectData={configRecord}
          setSnackbar={props.setSnackbar}
          setSnackbarData={props.setSnackbarData}
          setSnackType={props.setSnackType}
          userPriv={props.userPriv[configRecord.id]}
          update={update}
        />
      )}
      {configRecord !== null && configRecord !== undefined && allotFiles && (
        <AllotFiles
          open={allotFiles}
          handleClose={() => {
            setAllotFiles(false);
          }}
          update={updateProj}
          projectData={configRecord}
          setSnackbar={props.setSnackbar}
          setSnackbarData={props.setSnackbarData}
          setSnackType={props.setSnackType}
          updateTasks={setUpdate}
          // update={update}
        />
      )}
      {configRecord !== null && configRecord !== undefined && uploadState && (
        <UploadDialog
          open={uploadState}
          handleClose={() => {
            setUploadOpen(false);
            setConfigRecord(null);
          }}
          projectData={configRecord}
          update={updateProj}
          setSnackbar={props.setSnackbar}
          setSnackbarData={props.setSnackbarData}
          setSnackType={props.setSnackType}
        />
      )}
    </div>
  );
}

export default DisplayTable;
