import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  CircularProgress,
  Box,
  Typography,
  TextField,
  Divider,
  Paper,
  Chip,
} from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { LockClosedIcon } from "@heroicons/react/outline";
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [isLoading, setLoading] = useState(true);
  const folderNaming = useRef(null);
  const fileNaming = useRef(null);
  const handleCategoryConfiguration = (e, naming = false) => {
    let newForm = { ...props.cat };
    if (naming) {
      newForm.category_description.naming = e.target.value;
    } else {
      newForm.category_description.splitting = e.target.value;
    }
    console.log(newForm);
    props.setSelectedCategory(newForm);
  };
  useEffect(() => {
    !isLoading && setLoading(true);
    axios
      .get("/api/naming-pattern/", {
        params: {
          id: props.id,
        },
      })
      .then((r) => {
        let newData = r.data;
        newData.map((e) => {
          e.editable = !e.locked;
          e.possible = e.locked ? e.possible_values.split(",") : null;
          e.delimiter = e.delimiter || undefined;
        });
        newData = newData.filter(
          (el) => el.root_category_name === props.cat.name
        );
        folderNaming.current = newData.filter((el) => el.type === "folder");
        fileNaming.current = newData.filter((el) => el.type === "file");
        console.log(fileNaming.current);
        props.setCombinedObj([
          {
            folderData: {
              folderObj: folderNaming.current,
              fieldNo: folderNaming.current.length,
            },
            fileData: {
              folderObj: fileNaming.current,
              fieldNo: fileNaming.current.length,
            },
            category: props.cat.name,
          },
        ]);
        setLoading(false);
      })
      .catch(console.log);
  }, [props.cat.id, props.id]);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: "#0F172A",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">Edit {props.cat.name}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <div className="flex flex-col space-y-2">
            <Divider>
              <Chip label="Folder Naming" />
            </Divider>
            {/* {folderNaming.current.map((e)=>{
                return(
                    <div key={e.id}>
                        <Paper sx={{padding:1}}>
                        <Typography variant="body1">{e.name}</Typography>
                        {e.locked && <LockClosedIcon />}
                        </Paper>
                        <div className="space-x-1">
                            {e.locked && e.possible.map((e,i)=>{
                                return(<Chip label={e} key={i}></Chip>)
                            })}
                        </div>
                    </div>
                )
            })} */}
            {props.setDynmaic(true, 1)}
            <Divider>
              <Chip label="File Naming" />
            </Divider>
            {props.setDynmaic(false, 1)}
            <Divider>
              <Chip label="Category Description" />
            </Divider>
            <TextField
              className="w-full"
              margin="normal"
              id="catdescriptsplit"
              label={"Splitting Instuctions for resources"}
              value={props.cat.category_description.splitting}
              onChange={(e) => {
                handleCategoryConfiguration(e);
              }}
              type="text"
            />
            <TextField
              className="w-full"
              margin="normal"
              id="catdescriptname"
              label={"Naming Instuctions for resources"}
              value={props.cat.category_description.naming}
              onChange={(e) => {
                handleCategoryConfiguration(e, true);
              }}
              type="text"
            />
            {/* {fileNaming.current.map((e)=>{
                return(
                    <div key={e.id}>
                        <Paper sx={{padding:1}}>
                        <Typography variant="body1">{e.name} {e.delimiter && e.delimiter!==null && ", delmtr: "+ e.delimiter}</Typography>
                        {e.locked && "[locked]"}
                        <div className="space-x-1">
                            {e.locked && e.possible.map((e,i)=>{
                                return(<Chip label={e} key={i}></Chip>)
                            })}
                        </div>
                            </Paper>
                    </div>
                )
            })} */}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>CLOSE</Button>
        <Button onClick={props.handleEditCat}>Edit</Button>
      </DialogActions>
    </Dialog>
  );
};
