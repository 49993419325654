import { createSlice } from '@reduxjs/toolkit'

export const operationSlice = createSlice({
    name: 'operation',
    initialState: {
        pageOps: {}
    },
    reducers: {
        // addOperation: (state,action) =>{
        //     // state.value.push(action.payload)
        //     // state.redo = []
        //     if (action.payload.page in state.pageOps){
        //         state.pageOps[action.payload.page].ops.push(action.payload.operation)
        //         state.pageOps[action.payload.page].redo = []
        //     }
        //     else{
        //         state.pageOps[action.payload.page] = {
        //             ops:[action.payload.operation],
        //             redo:[]
        //         }
        //     }
        // },
        // undoOperation: (state,action) =>{
        //     // state.redo.push(state.pop())
        //     if(action.payload.page in state.pageOps){
        //         state.pageOps[action.payload.page].redo.push(state.pageOps[action.payload.page].ops.pop())
        //     }
        // },
        // redoOperation: (state,action) =>{
        //     // state.value.push(state.redo.pop())
        //     state.pageOps[action.payload.page].ops.push(state.pageOps[action.payload.page].redo.pop())
        // },
        // completeGlobalOperation: (state,action) => {
        //     state.pageOps[0].ops.at(-1).completed= true
        //     state.pageOps[0].ops.at(-1).extractedPages = action.payload.extracted
        // }
        
    }
})

export const { addOperation, undoOperation, redoOperation, completeGlobalOperation } = operationSlice.actions

export default operationSlice.reducer