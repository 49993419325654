import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import axios from "axios";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { ConstructionOutlined } from "@mui/icons-material";
const validations = {
  salary: /^\d*\.?\d*$/,
};
let resourceReqd = ["resourcename", "status", "salary"];
let checkBoxLabels = [
  "ALLOCATION OF RESOURCES",
  "UPLOADING FOR STRAIGHTENING",
  "ALLOTMENT OF WORK TO RESOURCES",
  "REVERT THE GIVEN WORK",
  "SPLITTING",
  "NAMING",
  "DOWNLOAD OF NAMED DATA",
  "VERIFICATION REPORT",
  "RAISING FLAG FOR MISTAKES",
];
let checkBoxIds = [
  "c_allocate",
  "c_upl_str",
  "c_allot",
  "c_revert",
  "c_split",
  "c_name",
  "c_dload",
  "c_verification",
  "c_flag",
];
const AllotD = (props) => {
  const [fetch, setFetch] = useState(0);
  const authState = useSelector((state) => state.auth);
  const [isLoading, setLoading] = useState(true);
  //edit mode changes button to edit, and patch request is sent instead of post
  const [isEditMode, setEditMode] = useState(false);
  //resources to be displayed in dropdown list
  const [resourcesList, setResourcesList] = useState([]);
  //current form data
  const [formData, setFormData] = useState({});
  //collection of previous allocations to be shown in sidepanel
  const [allocated, setAllocated] = useState([]);
  const [selectedIndex, setSelected] = useState(-1);
  const [selectAllCheck, setSelectAll] = useState(false);
  const updateEditMode = (current_name) => {
    let j = -1;
    for (let i = 0; i < allocated.length; i++) {
      if (allocated[i]["resourcename"]["username"] === current_name) {
        j = i;
      }
    }
    if (j === -1) {
      setEditMode(false);
      setSelected(-1);
    } else {
      setEditMode(true);
    }
  };
  //old
  const addResource = () => {
    if (!checkValidity(true)) {
      props.setSnackbarData("Please fill all required fields");
      props.setSnackbar(true);
      props.setSnackType("error");
    } else {
      let j = -1;
      for (let i = 0; i < allocated.length; i++) {
        if (
          allocated[i]["resourcename"]["username"] ===
          formData.resourcename.username
        ) {
          j = i;
        }
      }
      if (j === -1) {
        setAllocated([...allocated, formData]);
        setFormData({});
        setErrorState({});
      } else {
        let newAll = [...allocated];
        newAll[j] = formData;
        setAllocated(newAll);
        setFormData({});
        setErrorState({});
      }
    }
  };
  //removes old allocations
  const removeChip = (id) => {
    axios
      .delete("api/project-allocate/", {
        data: {
          project: props.projectData.id,
          username: allocated[id].resourcename.username,
        },
      })
      .then(() => {
        let newAll = [];
        for (let i = 0; i < allocated.length; i++) {
          if (id !== i) {
            newAll.push({ ...allocated[i] });
          }
        }
        setAllocated(newAll);
      });
  };

  //add/edit btn action
  const submitResources = () => {
    if (!formData.salary || formData.salary === null) {
      alert("Salary is a required field!");
      return;
    }

    let finalList = [];
    let newAll = [];
    for (let j = 0; j < checkBoxIds.length; j++) {
      let label = checkBoxIds[j];
      //if c_[permission] present in formdata and is set to true, append to list
      if (Object.hasOwn(formData, label) && formData[label]) {
        finalList.push(label);
      }
    }
    if (
      finalList.join(",").trim() === "" ||
      !finalList ||
      finalList.length < 1
    ) {
      alert("Please assign atleast one permission to the user");
      return;
    }
    newAll.push({});
    newAll[0].username = formData.resourcename.username;
    newAll[0].status = formData.status;
    newAll[0].salary = formData.salary;
    newAll[0].priv = [...finalList];
    // finalList = [];
    // console.log(props.projectData);
    if (isEditMode) {
      axios
        .patch("api/project-allocate/", {
          project: props.projectData.id,
          username: formData.resourcename.username,
          permissions: finalList.join(","),
          salary: formData.salary,
          salary_type: formData.status,
        })
        .then(() => {
          setFormData({});
          setSelected(-1);
          setEditMode(false);
          setFetch(fetch + 1);
          alert("User data updated");
        })
        .catch((e) => {
          props.setSnackbarData(e);
          props.setSnackbar(true);
          props.setSnackType("error");
        });
    } else {
      axios
        .get("api/project/" + props.projectData.id)
        .then((r) => {
          if (r.data.details.status === props.projectData.status)
            axios
              .post("api/project-allocate/", {
                id: props.projectData.id,
                resourcesList: newAll,
              })
              .then((resp) => {
                console.log();
                setAllocated([...allocated, formData]);
                setFormData({});
                props.update(props.projectData.id, props.projectData.table_id);
              })
              .catch((error) => {
                console.log(error);
              });
          else {
            props.handleClose();
            props.update(props.projectData.id, props.projectData.table_id);
            props.setSnackbarData(
              "Project Status has been changed, please try refreshing projects table"
            );
            props.setSnackbar(true);
            props.setSnackType("error");
          }
        })
        .catch((e) => {
          props.setSnackbarData(e);
          props.setSnackbar(true);
          props.setSnackType("error");
        });
    }
  };
  //sets formData as allocated[id] (on selection from sidepanel)
  const editChip = (id) => {
    setEditMode(true);
    setFormData(allocated[id]);
    setSelected(id);
  };
  //form validation
  const checkValidity = (isRes) => {
    let flag = true;
    let errorList = "";
    if (isRes) {
      for (let x of resourceReqd) {
        if (
          !Object.hasOwn(formData, x) ||
          formData.x === "" ||
          (Object.hasOwn(errorState, x) && errorState.x)
        ) {
          console.log(x);
          flag = false;
        }
      }
    }
    return flag;
  };
  //fetch user list and previous allocations
  useEffect(() => {
    axios.get("api/project/" + props.projectData.id).then((response) => {
      let newD = [];
      for (let obj of response.data.salary) {
        let temp = null;
        let priv = {};
        for (let k of response.data.user_permissions) {
          if (k.username === obj.username) {
            temp = k.permission.split(",");
            for (let p of temp) {
              if (checkBoxIds.includes(p)) {
                priv[p] = true;
              }
            }
          }
        }
        newD.push({
          resourcename: {
            full_name: obj.username,
            username: obj.username,
            combined_name: obj.username,
          },
          status: obj.salary_type,
          salary: obj.salary,
          ...priv,
        });
      }
      setAllocated(newD);
      setFormData({});
      setErrorState({});
    });
    axios
      .get("api/user-list/")
      .then((response) => {
        const res = response.data.map((obj) => {
          return {
            ...obj,
            combined_name: obj.full_name,
          };
        });

        setResourcesList(res);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetch]);
  const handleCheckbox = (e, inputField) => {
    setFormData({ ...formData, [inputField]: e.target.checked });
  };
  const [errorState, setErrorState] = useState({});
  const handleSalary = (e) => {
    setErrorState({
      ...errorState,
      salary: !validations["salary"].test(e.target.value),
    });
    setFormData({ ...formData, salary: e.target.value });
  };
  //form validation
  const handleChange = (e, inputField, val = null) => {
    if (val !== null && e === null) {
      setFormData({ ...formData, [inputField]: val });
      if (inputField === "resourcename") updateEditMode(val.username);
      if (val === "") {
        setErrorState({ ...errorState, [inputField]: true });
      } else {
        setErrorState({ ...errorState, [inputField]: false });
      }
    } else if (e === null) {
      setErrorState({ ...errorState, [inputField]: true });
    } else if (Object.hasOwn(validations, inputField)) {
      setErrorState({
        ...errorState,
        [inputField]: !validations[inputField].test(e.target.value),
      });
    } else if (resourceReqd.includes(inputField) && e.target.value === "") {
      setErrorState({
        ...errorState,
        [inputField]: true,
      });
    } else if (e.target.value && e.target.value.length > 0) {
      setErrorState({
        ...errorState,
        [inputField]: false,
      });
    }
  };

  return (
    <Dialog
      open={props.open}
      onClose={(e, reason) => {
        console.log(reason);
        if (reason !== "backdropClick") {
          props.handleClose();
        }
      }}
      PaperProps={{
        style: {
          backgroundColor: "#0F172A",
        },
      }}
    >
      <DialogTitle>
        Resource Allotment
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              props.handleClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className="h-full">
          {!isLoading ? (
            <div className="h-full">
              <div className="flex h-full">
                <div className="h-full">
                  <Divider textAlign="left">
                    <Chip label="Resources Configured" />
                  </Divider>
                  {!(allocated.length > 0) ? (
                    <DialogContentText className="py-4">
                      No Resources Configured
                    </DialogContentText>
                  ) : (
                    <div className="h-full">
                      <List>
                        {allocated.map((e, i) => {
                          return (
                            <>
                              <ListItem
                                disablePadding
                                key={i}
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={(ev) => removeChip(i)}
                                  >
                                    <DeleteOutlineRoundedIcon />
                                  </IconButton>
                                }
                              >
                                <ListItemButton
                                  // label={e.resourcename.username}
                                  // color="primary"
                                  selected={selectedIndex === i}
                                  onClick={(ev) => {
                                    editChip(i);
                                  }}
                                >
                                  <ListItemText
                                    primary={
                                      e.resourcename.full_name +
                                      " - " +
                                      e.resourcename.username
                                    }
                                    secondary={
                                      e.status + "-" + e.salary + "Paise"
                                    }
                                  />
                                </ListItemButton>
                              </ListItem>
                              <Divider component="li" />
                            </>
                          );
                        })}
                      </List>
                    </div>
                  )}
                </div>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ marginRight: 4 }}
                />
                <div>
                  <Divider textAlign="left">
                    <Chip label="Resource Selection" />
                  </Divider>
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.combined_name}
                    options={resourcesList}
                    sx={{ width: 300 }}
                    value={formData.resourcename || null}
                    onChange={(e, newValue) => {
                      if (typeof newValue === "string") {
                        handleChange(null, "resourcename", newValue);
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        handleChange(null, "resourcename", newValue.inputValue);
                      } else {
                        handleChange(null, "resourcename", newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        error={errorState.resourcename}
                        label="Resource"
                        onChange={(e) => {
                          handleChange(e, "resourcename");
                        }}
                        required
                      />
                    )}
                  />
                  <Autocomplete
                    // disablePortal
                    id="combo-box-demo"
                    options={["Contract", "Salary"]}
                    value={formData.status || null}
                    sx={{ width: 300 }}
                    onChange={(e, newValue) => {
                      handleChange(null, "status", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="normal"
                        label="Status"
                        error={errorState.status}
                        required
                      />
                    )}
                  />
                  {formData.status !== undefined &&
                    formData.status === "Contract" && (
                      <TextField
                        margin="normal"
                        id="Contract"
                        label="Rate"
                        type="text"
                        value={formData.salary || ""}
                        onChange={handleSalary}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Paise
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              /page
                            </InputAdornment>
                          ),
                        }}
                        error={errorState.salary}
                        required
                        sx={{ width: 300 }}
                      />
                    )}
                  {formData.status !== undefined &&
                    formData.status === "Salary" && (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="Contract"
                        label="Rate"
                        type="text"
                        value={formData.salary || ""}
                        onChange={handleSalary}
                        error={errorState.salary}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Paise
                            </InputAdornment>
                          ),
                        }}
                        required
                        sx={{ width: 300 }}
                      />
                    )}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <>
                      <FormControlLabel
                        label={selectAllCheck ? "Unselect All" : "Select All"}
                        control={
                          <Checkbox
                            checked={selectAllCheck}
                            onChange={(ev) => {
                              setSelectAll(ev.target.checked);
                              ev.target.checked
                                ? setFormData(
                                    checkBoxIds.reduce(
                                      (collection, cur) => ({
                                        ...collection,
                                        [cur]: true,
                                      }),
                                      { ...formData }
                                    )
                                  )
                                : setFormData(
                                    checkBoxIds.reduce(
                                      (collection, cur) => ({
                                        ...collection,
                                        [cur]: false,
                                      }),
                                      { ...formData }
                                    )
                                  );
                            }}
                          />
                        }
                      />
                      {checkBoxIds.map((e, i) => {
                        return (
                          <FormControlLabel
                            label={checkBoxLabels[i]}
                            control={
                              <Checkbox
                                checked={
                                  formData[e] !== undefined && formData[e]
                                }
                                onChange={(ev) => handleCheckbox(ev, e)}
                              />
                            }
                          />
                        );
                      })}
                    </>
                  </Box>
                </div>
              </div>
            </div>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={submitResources}>{isEditMode ? "Edit" : "Add"}</Button>
        <Button onClick={props.handleClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export const AllotDialog = React.memo(AllotD);
