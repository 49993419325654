import React, { useState } from "react";
import AccessControl from "../components/Dashboard/AccessControl/AccessControl";
import LeftDrawer from "../components/Dashboard/LeftDrawer/LeftDrawer";
import Profile from "../components/Dashboard/Profile/Profile";
import Projects from "../components/Dashboard/Projects/Projects";
import UserData from "../components/Dashboard/UserData/UserData";
import { Navbar } from "../components/Navbar/Navbar";

import { useLocation } from "react-router-dom";
const Dashboard = (props) => {
  let location = useLocation();

  const [activeTab, setActiveTab] = useState(1);

  let displayComponent = null;
  //TODO snackbar interface and update status with movable progress location
  if (activeTab === 0) displayComponent = <Profile username={"Test User"} />;
  else if (activeTab === 4) displayComponent = <AccessControl />;
  else if (activeTab === 1) displayComponent = <Projects />;
  else if (activeTab === 5) displayComponent = <UserData />;
  return (
    <div className="w-full h-screen flex flex-col bg-slate-800 ">
      <Navbar setActiveTab={setActiveTab} />
      <div className="w-full flex flex-row flex-1">
        <LeftDrawer activeTab={activeTab} setActiveTab={setActiveTab} />
        {displayComponent}
      </div>
    </div>
  );
};

export default Dashboard;
