/* eslint-disable import/no-anonymous-default-export */
import React, { useRef, useState, useEffect } from "react";
import RenamePreview from "./RenamePreview";
import { useSelector } from "react-redux/es/exports";
import { DocumentIcon } from "@heroicons/react/solid";
import { setSaved } from "../../slice/fileSlice";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import cyan from "@mui/material/colors/cyan";
import { SaveState, RestoreState } from "./SaveState";
import { ClipboardCheckIcon } from "@heroicons/react/outline";
import axios from "axios";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { useNavigate } from "react-router";
import HelperDialog from "../HelperDialog/HelperDialog";
import { useLocation } from "react-router-dom";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import RestorePageRoundedIcon from "@mui/icons-material/RestorePageRounded";
import SavingState from "../PdfPreview/SavingState";
import LoadSaveScreen from "../PdfPreview/LoadSaveScreen";
import { del, keys } from "idb-keyval";
import { setAutoSave, clearAutoSave } from "../../slice/autoSaveSlice";
import { set } from "idb-keyval";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Button,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { setFiles } from "../../slice/fileSlice";
import { file, folder } from "jszip";
export default (props) => {
  //Functions
  const theme = createTheme({
    palette: {
      primary: cyan,
      // secondary: #009688,
    },
  });
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  function SlideTransition(props) {
    return <Slide {...props} />;
  }

  const clearSaved = async (projectID, fileName) => {
    let keyList = await keys();
    for (let z of keyList) {
      let proj_id = parseInt(z.slice(16, z.lastIndexOf("--")));
      let file_name = z.slice(z.lastIndexOf("--") + 2);
      if (proj_id === projectID && file_name === fileName) {
        del(z);
        break;
      }
    }
  };
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const [namingvis, setNamingVis] = useState(false);
  const [flags, setFlags] = useState([]);
  const [leftvis, setLeftVis] = useState(false);
  const [uploadStatus, setUpload] = useState({});
  const [fetchStage, setStage] = useState(0);
  const [isRestoring, setRestoring] = useState(false);
  const fileState = useSelector((state) => state.file);
  const blobState = useSelector((state) => state.blob);
  const [snackBardata, setSnackbarData] = useState("");
  const [snackType, setSnackType] = useState("");
  const [openSnackBar, setSnackbar] = useState(false);
  const [overrideData, setOverrideData] = useState("");
  const [folderConfig, setFolderConfig] = useState([[]]); //list of textField data for alll files
  const [fileData, setFileData] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [fileConfig, setFileConfig] = useState([]);
  const [fileIndex, setFileIndex] = useState(0);
  const [ofileIndex, setOfileIndex] = useState(0);
  const [folderIndex, setFolderIndex] = useState(0);
  const [names, setNames] = useState({});
  const [inputValue, setInputValue] = useState({});
  const [foldernames, setFolderNames] = useState({});
  const [delimiter, setdelimiter] = useState([]);
  const [overrideRestore, setOverride] = useState(true);
  const [cats, setMainCats] = useState([]);
  const [mainFileConfig, setMainFileConfig] = useState([]);
  const [mainFolderConfig, setMainFolderConfig] = useState([]);
  const [ognames, setOgnames] = useState({});
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [submit, setSubmit] = useState(false);
  const [callAutoSave, setCallAutoSave] = useState(0);
  const [vis, setVis] = useState(false);
  const [saveProgress, setSaveProgress] = useState({
    isTypeSave: true,
    hasStarted: false,
    progress: 0,
    error: false,
    errorText: null,
  });
  useEffect(() => {
    setFlags(new Array(fileData.length).fill(false));
  }, [fileData.length, ofileIndex]);
  useEffect(() => {
    if (fetchStage === 2) {
      const interval = setInterval(() => {
        setCallAutoSave((autosave) => autosave + 1);
      }, 30000);

      return () => clearInterval(interval);
    }
  }, [fetchStage]);
  useEffect(() => {
    console.log("Autosave working");
    saveSubmit();
  }, [callAutoSave]);
  const displayMsg = (msg, type) => {
    setSnackbarData(msg);
    setSnackbar(true);
    setSnackType(type);
  };

  const handleSave = () => {
    setDialogOpen(true);
    dialogProp.current = {
      onSubmit: saveSubmit,
      handleClose: () => {
        setDialogOpen(false);
      },
      title: "Save Current Work?",
      desc: "All Previously saved work of this file will be overwritten",
      okText: "Save",
    };
  };
  const handleRestore = () => {
    setDialogOpen(true);
    dialogProp.current = {
      onSubmit: restoreSubmit,
      handleClose: () => {
        setDialogOpen(false);
      },
      title: "Restore Previous Work? ",
      desc: "All Current work will be lost.",
      okText: "Restore",
    };
  };

  const validateData = (data) => {
    console.log(data);
    var count = 0;

    data.forEach((obj) => {
      if (obj.hasOwnProperty("name")) {
        if (!obj.name || obj.name.trim() === "") {
          count += 1;
        }
      } else {
        count += 1;
      }
    });
    return count;
  };
  const validateFileData = (data) => {
    console.log(data);
    var count = 0;

    data.forEach((obj) => {
      if (obj.hasOwnProperty("name")) {
        if (
          !obj.name ||
          obj.name.trim() === "" ||
          obj.name.split(".pdf")[0].trim() === ""
        ) {
          count += 1;
        }
      } else {
        count += 1;
      }
    });
    return count;
  };
  const handleSubmit = async () => {
    if (!fileState.files[ofileIndex].completed) {
      console.log("Here");
      const index = ofileIndex;
      const newfileStatus = { ...fileState.files[index], completed: true };
      const files = [...fileState.files];
      // console.log(files);
      files[index] = newfileStatus;
      dispatch(setFiles(files));
    }

    var fileerrors = 0;
    var foldererrors = 0;
    fileerrors = validateFileData(fileData);
    foldererrors = validateData(folderData);
    console.log(fileData, folderData);
    console.log(fileerrors, foldererrors);

    if (fileerrors === 0 && foldererrors === 0) {
      console.log(
        ognames,
        fileState,
        ognames,
        fileState.files[ofileIndex].name
      );
      setTotalCompleted(totalCompleted + blobState.allBlobs[ofileIndex].length);
      // axios.post("/api/naming-pattern-suggest/",{

      // }).then((response)=>{
      //   console.log(response.data)
      // }).catch((error)=>{
      //   console.log(error)
      // })
      var combined = [];
      combined.push(...folderConfig);
      combined.push(...fileConfig);
      var filtered = combined.filter((obj) => {
        if (obj.suggestion_values.length > 0) {
          return true;
        } else {
          return false;
        }
      });
      var suggestions = filtered.map((obj) => {
        return obj.suggestion_values;
      });
      var naming_pattern = filtered.map((obj) => {
        return obj.id;
      });
      console.log(suggestions, naming_pattern);

      axios
        .patch("/api/naming-pattern-suggest/", {
          suggestions,
          naming_pattern,
        })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .post(
          "/api/rename-s3-object/",
          {
            project_id: location.state.id,
            upload_path: folderData.map((folder) => {
              return folder.name + "/";
            }, {}),

            original: ognames[fileState.files[ofileIndex].name],
            updated: fileData.map((file) => {
              return file.name;
            }),
            flags: flags,
            parent_file: fileState.files[ofileIndex].name,
            category: fileState.files[ofileIndex].category,
          },
          {
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader("content-length") ||
                  progressEvent.target.getResponseHeader(
                    "x-decompressed-content-length"
                  );
              console.log("onUploadProgress", totalLength);
              if (
                totalLength !== null &&
                Math.round((progressEvent.loaded * 100) / totalLength) >
                  uploadStatus[ofileIndex]
              ) {
                // console.log(Math.round( progressEvent.loaded * 100 / totalLength ))
                let newUp = { ...uploadStatus };
                newUp[ofileIndex] = Math.round(
                  (progressEvent.loaded * 100) / totalLength
                );
                setUpload(newUp);
              }
            },
          }
        )
        .then((r) => {
          clearSaved(location.state.id, fileState.files[ofileIndex].name);
          const newfileStatus = {
            ...fileState.files[ofileIndex],
            completed: true,
          };

          const files = [...fileState.files];

          files[ofileIndex] = newfileStatus;

          dispatch(setFiles(files));
          setFolderData([]);
          setFileData([]);
          setSubmit(false);
          setVis(false);
          setSnackbarData("Renaming has been successfully done");
          setSnackType("success");
          setNamingVis(false);
          setSnackbar(true);
          setVisible(false);
          let comp = [];
          fileState.files.forEach((file, index) => {
            console.log(file, index);
            if (!file.completed) {
              comp.push(index);
            }
          });
          if (comp.length > 0) {
            console.log(comp);
            let ext_data = blobState.allBlobs[comp[0]];
            setNames({});
            setInputValue({});
            setOfileIndex(comp[0]);

            setFileIndex(0);

            setFileData(ext_data);

            setFileConfig(
              mainFileConfig.filter((obj) => {
                if (obj.root_category_name === cats[comp[0] + 1]) {
                  return true;
                } else {
                  return false;
                }
              })
            );

            setFolderConfig(
              mainFolderConfig.filter((obj) => {
                if (obj.root_category_name === cats[comp[0] + 1]) {
                  return true;
                } else {
                  return false;
                }
              })
            );

            if (Array.isArray(ext_data)) {
              ext_data = new Array(ext_data.length).fill("");
            } else {
              ext_data = ["No Files Extracted"];
              setFileData(ext_data);
            }

            setFolderData(ext_data);
            setVis(true);
          } else {
            setVis(false);
          }
        });
    } else {
      setSnackbarData(
        "Found " +
          foldererrors +
          " errors in folder paths" +
          " and " +
          fileerrors +
          " errors in file paths " +
          "please check the paths and try again."
      );
      setSnackType("error");
      setSnackbar(true);
    }
  };
  const saveSubmit = () => {
    if (fetchStage < 2) {
      return;
    } else {
      console.log("saving", ofileIndex);
      setDialogOpen(false);
      setSaveProgress({ ...saveProgress, hasStarted: true });

      SaveState(
        blobState,
        fileState,
        fileData,
        folderData,
        ognames,
        ofileIndex,
        displayMsg
      ).then(() => {
        setSaveProgress({ ...saveProgress, hasStarted: false });
        dispatch(setSaved(ofileIndex));
        setStage(2);
        dispatch(setAutoSave());
        setOverride(false);
      });
    }
  };
  const restoreSubmit = async () => {
    setStage(-1);
    setRestoring(true);

    RestoreState(
      fileState.project_id,
      fileState.files[ofileIndex].name,
      fileState.files[ofileIndex].category
    ).then((vars) => {
      console.log(vars);
      if (!vars.error) {
        console.log(vars.data.folderData);
        // setFileIndex(fileIndex);
        console.log(vars.data.fileData);
        setFileData(vars.data.fileData);

        setFolderData(vars.data.folderData);
        setVis(true);
        setFileIndex(0);
        setFolderIndex(0);
        setSnackbarData("File has been restored successfully");
        setSnackbar(true);
        setSnackType("success");
        setOverride(false);
        setStage(2);
        // });
      } else {
        alert(vars.errorText);
      }

      setDialogOpen(false);
      setRestoring(false);
    });
  };
  const handleRemoval = (i) => {
    const filecopy = [...fileConfig];
    filecopy.splice(parseInt(i), 1);
    setFileConfig(filecopy);
  };
  const handleAddition = (i) => {
    console.log("Adding at", parseInt(i) + 1);
    const defaultConfig = {
      editable: true,
      name: "Extra Field",
    };
    const filecopy = [...fileConfig];
    filecopy.splice(parseInt(i) + 1, 0, defaultConfig);
    setFileConfig(filecopy);
  };
  const openPreview = (url, pages, index, name) => {
    setPreview(url);
    setPages(pages);

    setFileIndex(index);

    setVisible(true);

    setNames({});
    setFolderNames({});
    setInputValue({});
  };
  const nextFile = async () => {
    console.log(folderConfig, fileConfig);
    console.log(foldernames, names);
    var filev = 0,
      filenv = 0;
    var folderv = 0,
      folderenv = 0;
    var nl = fileConfig.length,
      fnl = folderConfig.length;
    console.log(names, foldernames);
    for (var i = 0; i < nl; i++) {
      console.log(names[i]);
      if (names[i]) {
        if (names[i]?.trim() === "") {
          filenv++;
        } else {
          filev++;
        }
      } else {
        filenv++;
      }
    }

    for (i = 0; i < fnl; i++) {
      if (foldernames[i]) {
        if (foldernames[i].trim() === "") {
          folderenv++;
        } else {
          folderv++;
        }
      } else {
        folderenv++;
      }
    }
    console.log(filev, filenv, folderenv, folderv);
    if (filev !== 0 && folderv !== 0 && filev === nl && folderv === fnl) {
      //addinf config data to the array
      var oldfolder = [...folderConfig];
      var oldfile = [...fileConfig];
      //var names and foldernames
      console.log(oldfolder, oldfile);
      console.log(names, foldernames);

      for (i = 0; i < folderConfig.length; i++) {
        if (oldfolder[i].suggest) {
          if (!oldfolder[i].suggestion_values.includes(foldernames[i])) {
            oldfolder[i].suggestion_values.push(foldernames[i]);
          }
          console.log(oldfolder[i]);
        }
      }

      for (i = 0; i < fileConfig.length; i++) {
        if (oldfile[i].suggest) {
          if (!oldfile[i].suggestion_values.includes(names[i])) {
            oldfile[i].suggestion_values.push(names[i]);
          }
        }
      }

      console.log(oldfolder, oldfile);

      var minfolder = mainFolderConfig.filter((obj) => {
        if (obj.root_category_name !== cats[ofileIndex]) {
          return true;
        } else {
          return false;
        }
      });

      var minfile = mainFileConfig.filter((obj) => {
        if (obj.root_category_name !== cats[ofileIndex]) {
          return true;
        } else {
          return false;
        }
      });

      var combinedfol = [...minfolder];
      combinedfol = combinedfol.concat([...oldfolder]);
      var combinedfile = [...minfile];
      combinedfile = combinedfile.concat([...oldfile]);
      console.log(combinedfol, combinedfile);
      setMainFolderConfig(combinedfol);
      setMainFileConfig(combinedfile);
      setFileConfig(
        mainFileConfig.filter((obj) => {
          if (obj.root_category_name === cats[ofileIndex]) {
            return true;
          } else {
            return false;
          }
        })
      );

      setFolderConfig(
        mainFolderConfig.filter((obj) => {
          if (obj.root_category_name === cats[ofileIndex]) {
            return true;
          } else {
            return false;
          }
        })
      );

      if (fileIndex + 1 < fileData.length) {
        console.log(
          "Erroring here",
          ognames[fileState.files[ofileIndex].name],
          fileIndex,
          fileState.files[ofileIndex].name,
          ognames,
          ofileIndex
        );
        var original_name =
          ognames[fileState.files[ofileIndex].name][fileIndex + 1];

        var r = await axios.post("/api/get-single-s3/", {
          file_name: original_name,
          dir_name: fileData[fileIndex + 1]?.path.split(original_name)[0],
        });
        setPreview(r.data.url);
        setVisible(true);
        setPages(fileData[fileIndex + 1].pages);
        const prev = folderData[fileIndex];
        console.log("previous folder Data", prev);
        const newFolderData = [...folderData];
        console.log("New folder Data", newFolderData);
        newFolderData[fileIndex + 1] = {
          ...newFolderData[fileIndex + 1],
          name: prev.name,
        };
        setFolderData(newFolderData);
        const prevf = fileData[fileIndex];
        const newFileData = [...fileData];
        newFileData[fileIndex + 1] = {
          ...newFileData[fileIndex + 1],
          name: prevf.name,
        };

        console.log(newFileData, newFolderData);
        setFileData(newFileData);

        setFileIndex(fileIndex + 1);
        setFolderIndex(fileIndex + 1);
      } else {
        setSnackbarData("Reached end of extracted files list");
        setSnackType("info");
        setSnackbar(true);
        setSubmit(true);
      }
    } else {
      console.log(folderv, filev);
      if (folderv !== fnl) {
        setSnackbarData("Empty fields in folder naming");
        setSnackType("error");
        setSnackbar(true);
      } else if (filev !== nl) {
        setSnackbarData("Empty fields in file naming");
        setSnackType("error");
        setSnackbar(true);
      } else {
        setSnackbarData("Empty file and folder fields");
        setSnackType("error");
        setSnackbar(true);
      }
    }
    console.log(names, foldernames);
  };
  const prevFile = async () => {
    //old configuration
    // setNames({ val });
    // setInputValue({ val });
    // setFileIndex(val);
    // setFolderIndex(val);
    // setVisible(true);
    // setPreview(fileData[val].url);

    // setPages(fileData[val].pages);

    // const prev = folderData[folderIndex];
    // const newFolderData = [...folderData];
    // newFolderData[val] = prev;
    // setFolderData(newFolderData);

    //separation

    console.log(folderConfig, fileConfig);
    console.log(foldernames, names);
    var filev = 0,
      filenv = 0;
    var folderv = 0,
      folderenv = 0;
    var nl = Object.keys(names).length,
      fnl = Object.keys(foldernames).length;
    console.log(names, foldernames);
    for (var i = 0; i < nl; i++) {
      console.log(names[i]);
      if (names[i]?.trim() === "") {
        filenv++;
      } else {
        filev++;
      }
    }

    for (var i = 0; i < fnl; i++) {
      if (foldernames[i].trim() === "") {
        folderenv++;
      } else {
        folderv++;
      }
    }
    console.log(filev, filenv, folderenv, folderv);
    if (filev === nl && folderv === fnl) {
      var val = fileIndex - 1 >= 0 ? fileIndex - 1 : fileData.length - 1;

      setFileIndex(val);
      setFolderIndex(val);

      var original_name = ognames[fileState.files[ofileIndex].name][val];

      var r = await axios.post("/api/get-single-s3/", {
        file_name: original_name,
        dir_name: fileData[val]?.path.split(original_name)[0],
      });
      setPreview(r.data.url);
      setVisible(true);
      setPages(fileData[val].pages);
      const prev = folderData[val];
      const newFolderData = [...folderData];
      newFolderData[val] = { ...newFolderData[val], name: prev.name };
      setFolderData(newFolderData);
      const prevf = fileData[fileIndex];
      const newFileData = [...fileData];
      newFileData[val] = { ...newFileData[val], name: prevf.name };

      console.log(newFileData, newFolderData);
      setFileData(newFileData);
    } else {
      console.log(folderv, filev);
      if (folderv !== fnl) {
        setSnackbarData("Empty fields in folder naming");
        setSnackType("error");
        setSnackbar(true);
      } else if (filev !== nl) {
        setSnackbarData("Empty fields in file naming");
        setSnackType("error");
        setSnackbar(true);
      } else {
        setSnackbarData("Empty file and folder fields");
        setSnackType("error");
        setSnackbar(true);
      }
    }
    console.log(names, foldernames);
  };
  const handleOnext = (event) => {
    if (ofileIndex + 1 < fileState.files.length) {
      let ext_data = blobState.allBlobs[ofileIndex + 1];

      setNames({});
      setInputValue({});
      setOfileIndex(ofileIndex + 1);

      setFileIndex(0);

      setFileData(ext_data);
      console.log(
        "setting",
        mainFileConfig.filter((obj) => {
          if (obj.root_category_name === cats[ofileIndex + 1]) {
            return true;
          } else {
            return false;
          }
        })
      );
      setFileConfig(
        mainFileConfig.filter((obj) => {
          if (obj.root_category_name === cats[ofileIndex + 1]) {
            return true;
          } else {
            return false;
          }
        })
      );

      setFolderConfig(
        mainFolderConfig.filter((obj) => {
          if (obj.root_category_name === cats[ofileIndex + 1]) {
            return true;
          } else {
            return false;
          }
        })
      );

      if (Array.isArray(ext_data)) {
        ext_data = new Array(ext_data.length).fill("");
      } else {
        ext_data = ["No Files Extracted"];
        setFileData(ext_data);
      }

      setFolderData(ext_data);
      setVis(true);
    } else {
      setSnackbarData("Reached end of all parent files");
      setSnackType("success");
      setSnackbar(true);
    }
  };
  const handleFolderChange = (e, v, index) => {
    console.warn("value-------->", v, typeof v);
    if (index != -1 && !v.includes("/")) {
      setFolderNames({ ...foldernames, [index]: v?.toUpperCase() });
      console.log(foldernames);
      let finalName = "";
      for (let i = 0; i < folderConfig.length; i++) {
        if (i in foldernames && i !== index) {
          finalName = finalName + foldernames[i];
        } else if (i === index) finalName = finalName + v?.toUpperCase();

        if (i !== folderConfig.length - 1 && finalName !== "") {
          finalName = finalName + "/";
        }
      }
      var newFolderData = [...folderData];
      newFolderData[folderIndex] = {
        ...newFolderData[folderIndex],
        name: finalName,
      };

      setFolderData(newFolderData);
    } else {
      setOverrideData(v?.toUpperCase());
      var newFolderData = [...folderData];
      newFolderData[folderIndex] = {
        ...newFolderData[folderIndex],
        name: overrideData,
      };
    }
  };
  const handleChange = (e, v, index) => {
    if (index !== -1 && !v.includes("/")) {
      console.log(v?.toUpperCase());
      setNames({ ...names, [index]: v?.toUpperCase() });
      console.log("setting names here");
      let missed = 0;
      let finalName = "";
      for (let i = 0; i < fileConfig.length; i++) {
        if (i in names && i !== index) {
          finalName = finalName + names[i];
        } else if (i === index) finalName = finalName + v?.toUpperCase();
        else {
          missed++;
        }
        if (i !== fileConfig.length - 1 && finalName !== "") {
          finalName =
            finalName +
            (fileConfig[i].delimiter !== null ? fileConfig[i].delimiter : " ");
        }
      }
      finalName = finalName + ".pdf";
      var newFileData = [...fileData];
      newFileData[fileIndex] = {
        ...newFileData[fileIndex],
        name: finalName,
        completed: missed == 0,
      };
      setFileData(newFileData);
    } else {
      console.log("here");
      setOverrideData(v?.toUpperCase());
      var newFileData = [...fileData];
      newFileData[fileIndex] = {
        ...newFileData[fileIndex],
        name: overrideData + ".pdf",
        completed: true,
      };
      setFileData(newFileData);
    }
  };
  const handleInputChange = (v, index) => {
    console.log(v, index);
    if (index != -1 && !v.includes("/")) {
      console.log(v?.toUpperCase());
      setNames({ ...names, [index]: v?.toUpperCase() });
      console.log("setting names");
      let missed = 0;
      let finalName = "";
      for (let i = 0; i < fileConfig.length; i++) {
        if (i in names && i !== index) {
          finalName = finalName + names[i];
        } else if (i === index) finalName = finalName + v?.toUpperCase();
        else {
          missed++;
        }
        if (i !== fileConfig.length - 1 && finalName !== "") {
          finalName =
            finalName +
            (fileConfig[i].delimiter !== null ? fileConfig[i].delimiter : " ");
        }
      }
      finalName = finalName + ".pdf";
      var newFileData = [...fileData];
      newFileData[fileIndex] = {
        ...newFileData[fileIndex],
        name: finalName,
        completed: missed == 0,
      };
      setFileData(newFileData);
    } else {
      console.log("here");
      setOverrideData(v?.toUpperCase());
      var newFileData = [...fileData];
      newFileData[fileIndex] = {
        ...newFileData[fileIndex],
        name: overrideData + ".pdf",
        completed: true,
      };
      setFileData(newFileData);
    }
  };
  const handleFolderInputChange = (v, index) => {
    console.log(typeof v, index);
    if (index != -1 && !v.includes("/")) {
      console.log(v?.toUpperCase());
      setFolderNames({ ...foldernames, [index]: v?.toUpperCase() });
      console.log("setting names");
      let missed = 0;
      let finalName = "";
      for (let i = 0; i < folderConfig.length; i++) {
        if (i in foldernames && i !== index) {
          finalName = finalName + foldernames[i];
        } else if (i === index) finalName = finalName + v?.toUpperCase();
        else {
          missed++;
        }
        if (i !== folderConfig.length - 1 && finalName !== "") {
          finalName = finalName + "/";
        }
      }
      var newFileData = [...folderData];
      newFileData[folderIndex] = {
        ...newFileData[folderIndex],
        name: finalName,
      };
      setFolderData(newFileData);
    }
    //  else  {
    //    console.log("here");
    //    setOverrideData(v?.toUpperCase());
    //    var newFileData = [...folderData];
    //    newFileData[folderIndex] = {
    //      ...newFileData[folderIndex],
    //      name: overrideData + ".pdf",
    //      completed: true
    //    };
    //    setFileData(newFileData);
    //  }
  };
  const [preview, setPreview] = useState(null);
  const [pages, setPages] = useState(null);
  const [visible, setVisible] = useState(false);
  const highlighted = "bg-slate-600  hover:bg-cyan-700";
  const [extracted_data, setExtractedData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  //props for helper dialog like name desc etc
  const dialogProp = useRef({});
  const saveOps = async () => {
    var updated = fileData.map((obj, i) => {
      return {
        ...obj,
        url: blobState.allBlobs[ofileIndex][i]?.url,
        path: blobState.allBlobs[ofileIndex][i]?.path,
      };
    });
    console.log("Autosetting", fileData, folderData);
    await set(
      "--naming--file--" +
        fileState.project_id +
        "--" +
        fileState.files[ofileIndex].category +
        "--" +
        fileState.files[ofileIndex].name,
      {
        folderData: folderData,
        fileData: updated,
        children: ognames[fileState.files[ofileIndex].name],
        fileState: fileState.files[ofileIndex],
        flags: flags,
      }
    )
      .then(() => console.log("Rename Data saved Successfully", "success"))
      .catch((err) =>
        displayMsg("An Error occured while saving the file try again error")
      );
  };
  // useEffect(() => {
  //   if(fetchStage === 2){
  //   const interval = setInterval(async()=>{await saveOps()}, 30000);

  //   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  // }
  // }, [fetchStage,ofileIndex]);

  useEffect(() => {
    setMainFileConfig(location.state.fileNaming);
    setMainFolderConfig(location.state.folderNaming);
    setMainCats(location.state.cats);
    console.log(location.state.cats, location.state.fileNaming);
    setOgnames(location.state.ognames);
    console.log(location.state.ognames);
    const i = 0;
    let ext_data = blobState.allBlobs[i];

    setOfileIndex((state) => {
      return i;
    });

    setFileIndex(i);

    setFileData(ext_data);
    const filecopy = [...location.state.fileNaming];
    const foldercopy = [...location.state.folderNaming];
    setFileConfig(
      filecopy.filter((obj) => {
        if (obj.root_category_name === cats[fileIndex]) {
          return true;
        } else {
          return false;
        }
      })
    );
    console.log(
      foldercopy.filter((obj) => {
        if (obj.root_category_name === cats[fileIndex]) {
          return true;
        } else {
          return false;
        }
      })
    );

    setFolderConfig(
      foldercopy.filter((obj) => {
        if (obj.root_category_name === cats[fileIndex]) {
          return true;
        } else {
          return false;
        }
      })
    );

    if (Array.isArray(ext_data)) {
      ext_data = new Array(ext_data.length).fill("");
    } else {
      ext_data = ["No Files Extracted"];
      setFileData(ext_data);
    }

    setFolderData(ext_data);

    setVis(true);
  }, [vis]);
  useEffect(() => {
    var test = fileConfig.map((obj) => {
      return obj.delimiter;
    });

    setdelimiter(test);
  }, [fileConfig]);
  // useEffect(() => {
  //   console.log("Here")
  //   var len=fileConfig.length;
  //   var def={};
  //   for (var i=0; i<len; i++) {
  //    def={...def,[i]:''}

  //   }
  //   console.log(def)
  //   setNames({...def})
  //   setInputValue({...def})
  // }, [fileConfig]);

  // useEffect(() => {
  //   console.log("Here")
  //   var len=folderConfig.length;
  //   var def={};
  //   for (var i=0; i<len; i++) {
  //    def={...def,[i]:''}
  //   }
  //   console.log(def)
  //   setFolderNames({...def})
  // }, [folderConfig]);
  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which);
    console.log(event.key, charCode);
    if ((event.ctrlKey || event.metaKey) && charCode === "D") {
      event.preventDefault();
      nextFile();
    }
    if ((event.ctrlKey || event.metaKey) && charCode === "A") {
      event.preventDefault();
      prevFile();
    }
    if ((event.ctrlKey || event.metaKey) && charCode === "S") {
      event.preventDefault();
      saveSubmit();
    }
    if ((event.ctrlKey || event.metaKey) && charCode === "R") {
      event.preventDefault();
      restoreSubmit();
    }
    //   if (!fileState.files[ofileIndex + 1].completed) {
    //     event.preventDefault();

    //     if (ofileIndex + 1 < fileState.files.length) {
    //       setDialogOpen(true);
    //       dialogProp.current = {
    //         okText: "Switch",
    //         title: "Switch Working File ?",
    //         desc: "Switching to another file will discard current unsaved work",
    //         handleClose: () => {
    //           setDialogOpen(false);
    //         },
    //         onSubmit: () => {
    //           let ext_data = blobState.allBlobs[ofileIndex + 1];

    //           setOfileIndex((state) => {
    //             return;
    //           });

    //           setFileIndex(0);

    //           setFileData(ext_data);

    //           setFileConfig(
    //             mainFileConfig.filter((obj) => {
    //               if (obj.root_category_name === cats[ofileIndex + 1]) {
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             })
    //           );

    //           setFolderConfig(
    //             mainFolderConfig.filter((obj) => {
    //               if (obj.root_category_name === cats[ofileIndex + 1]) {
    //                 return true;
    //               } else {
    //                 return false;
    //               }
    //             })
    //           );

    //           if (Array.isArray(ext_data)) {
    //             ext_data = new Array(ext_data.length).fill("");
    //           } else {
    //             ext_data = ["No Files Extracted"];
    //             setFileData(ext_data);
    //           }

    //           setFolderData(ext_data);
    //           setVis(true);
    //           setDialogOpen(false);
    //         },
    //       };
    //     } else {
    //       setSnackbarData("Reached end of all parent files");
    //       setSnackType("success");
    //       setSnackbar(true);
    //     }
    //   }
    // }
  };

  return (
    <div className="flex flex-row flex-1" onKeyDown={handleKeyDown}>
      <Snackbar
        sx={{ width: 600 }}
        anchorOrigin={{
          height: 100,
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={SlideTransition}
        open={openSnackBar}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
      >
        <Alert
          variant="filled"
          severity={snackType}
          sx={{
            width: "100%",
            fontSize: 20,
          }}
        >
          {snackBardata}
        </Alert>
      </Snackbar>
      <div className="p-4">
        <HelperDialog open={dialogOpen} {...dialogProp.current} />
        <div className="flex justify-between   mb-3 items-end">
          <p className="text-sm text-slate-200 font-semibold tracking-widest text-xl inline">
            Original Files ({ofileIndex + 1}/{fileState.files.length})
          </p>
          <SaveRoundedIcon
            fontSize="large"
            className=" text-slate-200 rounded p-1 hover:bg-slate-800 cursor-pointer"
            onClick={handleSave}
          />
          <RestorePageRoundedIcon
            fontSize="large"
            className=" text-slate-200 rounded p-1 hover:bg-slate-800 cursor-pointer"
            onClick={handleRestore}
          />
          <div className="space-x-2">
            <Button
              variant="contained"
              color="error"
              size="small"
              startIcon={<ExitToAppRoundedIcon />}
              onClick={() => {
                setDialogOpen(true);
                dialogProp.current = {
                  okText: "Quit",
                  title: "Quit Naming?",
                  desc: "Un-uploaded work wont be saved. Are you sure you want to quit?",
                  handleClose: () => {
                    setDialogOpen(false);
                  },
                  onSubmit: () => {
                    navigate("/dashboard", { replace: true });
                  },
                };
              }}
            >
              Quit
            </Button>
            {submit && (
              <Button
                variant="contained"
                size="small"
                startIcon={<EditRoundedIcon />}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
        <div className="border-t w-96 border-slate-500"></div>
        <div className="flex flex-col overflow-y-auto  text-slate-300 w-96 h-40 pt-2">
          {fileState.files.length >= 1 ? (
            fileState.files.map((file, i) => {
              if (!file.completed) {
                return (
                  <Tooltip title={file.description} placement="right-start">
                    <div
                      className={
                        "inline w-full cursor-pointer p-1 rounded " +
                        (ofileIndex === i ? highlighted : " hover:bg-cyan-700")
                      }
                      onClick={() => {
                        setDialogOpen(true);
                        dialogProp.current = {
                          okText: "Switch",
                          title: "Switch Working File ?",
                          desc: "Switching to another file will discard current unsaved work",
                          handleClose: () => {
                            setDialogOpen(false);
                          },
                          onSubmit: () => {
                            if (!file.saved) {
                              setNamingVis(true);

                              console.log(overrideRestore);
                            } else {
                              setNamingVis(false);
                            }
                            setStage(1);
                            setOverride(true);
                            dispatch(clearAutoSave());
                            let ext_data = blobState.allBlobs[i];
                            setPreview(null);
                            setVisible(false);
                            setOfileIndex(i);
                            setFileIndex(0);
                            console.log("Setting ofileindex ", ofileIndex);
                            setFileData(ext_data);
                            console.log(mainFileConfig, cats);
                            console.log(
                              "setting data",
                              mainFileConfig.filter((obj) => {
                                if (obj.root_category_name === cats[i]) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })
                            );
                            var fileconfig = mainFileConfig.filter((obj) => {
                              if (obj.root_category_name === cats[i]) {
                                return true;
                              } else {
                                return false;
                              }
                            });
                            setFileConfig(fileconfig);
                            var folderconfig = mainFolderConfig.filter(
                              (obj) => {
                                if (obj.root_category_name === cats[i]) {
                                  return true;
                                } else {
                                  return false;
                                }
                              }
                            );
                            setFolderConfig(folderconfig);

                            if (Array.isArray(ext_data)) {
                              ext_data = new Array(ext_data.length).fill("");
                            } else {
                              ext_data = ["No Files Extracted"];
                              setFileData(ext_data);
                            }

                            setFolderData(ext_data);
                            setVis(true);
                            setDialogOpen(false);
                            setStage(2);
                            var names = {};
                            console.log(folderconfig.length, fileconfig.length);
                            fileconfig.forEach((obj, index) => {
                              names[index] = "";
                            });

                            setNames(names);
                            var foldernames = {};
                            folderconfig.forEach((obj, index) => {
                              foldernames[index] = "";
                            });
                            console.log(names, foldernames);
                            setFolderNames(foldernames);
                          },
                        };
                      }}
                    >
                      <DocumentIcon
                        onClick={() => {
                          openPreview(file.url, file.pages, i, file.name);
                        }}
                        className="w-5 h-5 text-slate-300 inline "
                      />
                      <p className=" inline px-1 break-all w-30">
                        {file.name + (file.saved ? " [Saved]" : "")}
                      </p>
                    </div>
                  </Tooltip>
                );
              } else {
                return (
                  <div
                    className={
                      "inline w-full hover:bg-blue-700 cursor-not-allowed p-1 rounded " +
                      (i === ofileIndex ? highlighted : " ")
                    }
                    key={i}
                  >
                    {uploadStatus[i] !== 100 ? (
                      <ClipboardCheckIcon className="w-5 h-5 text-slate-300 inline" />
                    ) : (
                      <CloudDoneIcon fontSize="small" color="primary" />
                    )}
                    <p className=" inline px-1">
                      {file.name + (file.saved ? " [Saved]" : "")}
                    </p>
                    <p className=" inline px-1 text-sky-400 font-bold">
                      {uploadStatus[i] !== undefined
                        ? " [" + uploadStatus[i] + "%]"
                        : null}
                    </p>
                  </div>
                );
              }
            })
          ) : (
            <p className="text-sm text-slate-200 font-semibold tracking-widest">
              No files exist
            </p>
          )}
        </div>
        <div className="naming-drawer" hidden={!namingvis}>
          <div className="flex justify-between  mb-3 items-end">
            <p className="text-sm text-slate-200 font-semibold tracking-widest text-xl inline">
              Extracted Files ({fileIndex + 1}/
              {fileData[fileIndex] ? fileData.length : "--"})
            </p>
            <Button
              variant="contained"
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={handleOnext}
            >
              Next Main File
            </Button>
          </div>

          <div className="flex flex-col text-slate-300 w-96 h-60 overflow-y-auto pt-2">
            {fileData && fileData?.length >= 1 ? (
              fileData.map((page, i) => {
                return (
                  <div
                    className={
                      "inline w-full cursor-pointer p-1 rounded " +
                      (fileIndex === i ? highlighted : " hover:bg-cyan-700")
                    }
                    onClick={() => {
                      var original_name =
                        ognames[fileState.files[ofileIndex].name][i];
                      console.log(page);
                      axios
                        .post("/api/get-single-s3/", {
                          file_name: original_name,
                          dir_name: page?.path.split(original_name)[0],
                        })
                        .then((response) => {
                          openPreview(
                            response.data.url,
                            page.pages,
                            i,
                            page?.name
                          );
                          setFolderIndex(i);
                        })
                        .catch((err) => {
                          console.log(err);
                          setSnackType("error");
                          if (err.response.status === 404) {
                            setSnackbarData(
                              "The file is deleted or alloted to someone else"
                            );
                          } else if (err.response.status === 500) {
                            setSnackbarData(
                              "Could not submit file due to an internal error contact admin"
                            );
                          } else {
                            setSnackbarData(
                              "Error occured cannot submit file contact admin"
                            );
                          }
                          setSnackbar(true);
                        });

                      // const prev = folderData[folderIndex];
                      // console.log(folderIndex);
                      // console.log(folderData);
                      // const newFolderData = [...folderData];
                      // newFolderData[i] = prev;
                      // console.log(prev);
                      // setFolderData(newFolderData);
                    }}
                  >
                    <DocumentIcon className="w-5 h-5 text-slate-300 inline" />
                    <div className="flex">
                      <p className=" inline px-1 break-all w-30 ">
                        {page.hasOwnProperty("name")
                          ? page.name.trim() !== ".pdf"
                            ? page.name
                            : null
                          : null}
                      </p>
                      <p className="text-red-600 font-bold">
                        {page.hasOwnProperty("name")
                          ? page.name.trim() === ".pdf"
                            ? "Blank"
                            : null
                          : "Blank"}
                      </p>
                      <p>
                        &nbsp;Place at-
                        {folderData[i].hasOwnProperty("name")
                          ? folderData[i].name.trim() !== ""
                            ? folderData[i].name
                            : null
                          : null}
                      </p>
                      <p className="text-red-600 font-bold">
                        {folderData[i].hasOwnProperty("name")
                          ? folderData[i].name.trim() === ""
                            ? "   Blank"
                            : null
                          : "  Blank"}
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-sm text-slate-200 font-semibold tracking-widest">
                No files splitted
              </p>
            )}
          </div>
          {/* <div className="flex flex-col text-slate-300 w-96 h-1/ overflow-y-auto pt-2">
          {folderData && folderData.length >= 1 ? (
            folderData.map((page, i) => {
              return (
                <div
                  className={
                    "inline w-full cursor-pointer p-1 rounded " +
                    (fileIndex === i ? highlighted : " hover:bg-cyan-700")
                  }
                >
                  <p className=" inline px-1">{page.name}</p>
                </div>
              );
            })
          ) : (
            <p className="text-sm text-slate-200 font-semibold tracking-widest">
              No files splitted
            </p>
          )}
        </div> */}
          <div className="flex justify-between  mb-3 items-end">
            <p className="text-sm text-slate-200 font-semibold tracking-widest text-xl inline">
              Naming Pattern
            </p>
            <Button
              variant="contained"
              size="small"
              startIcon={<EditRoundedIcon />}
              onClick={nextFile}
            >
              Next Extracted File
            </Button>
          </div>
          <div className="border-t w-96 border-slate-500"></div>
          <div className="flex flex-col text-slate-300 w-96  pt-2">
            <p className="text-sm text-slate-200 font-semibold tracking-widest  inline">
              Folder Location
            </p>

            {vis
              ? folderConfig.length >= 1
                ? folderConfig.map((e, i) => {
                    if (!e.editable) {
                      return (
                        <FormControl
                          fullWidth
                          margin="normal"
                          error={foldernames[i]?.trim() === ""}
                        >
                          <InputLabel id={"label2-" + i}>{e.name}</InputLabel>
                          <Select
                            labelId={"label2" + i}
                            id={"select2" + i}
                            value={foldernames[i] || ""}
                            label={e.name}
                            onChange={(e) =>
                              handleFolderChange(e, e.target.value, i)
                            }
                          >
                            {e.possible.map((element) => {
                              return (
                                <MenuItem value={element}>{element}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      );
                    } else {
                      return (
                        <FormControl fullWidth margin="normal">
                          <Autocomplete
                            disablePortal
                            freeSolo
                            disableClearable
                            clearOnEscape
                            // onClick={(event) => {
                            //   handleChange(event, i);
                            // }}
                            id={"combo-box" + i}
                            options={
                              Array.isArray(e.suggestion_values)
                                ? e.suggestion_values
                                : []
                            }
                            sx={{ width: 300 }}
                            onChange={(e, v) => {
                              console.log("here in first");

                              handleFolderChange(e, v, i);
                            }}
                            value={foldernames[i] || null}
                            inputValue={foldernames[i] || ""}
                            // onInputChange={(event, v) => {
                            //   console.log("Value", v);
                            //   handleFolderInputChange(v, i);
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={(e) => {
                                  handleFolderChange(e, e.target.value, i);
                                  console.log("here in second");
                                }}
                                margin="normal"
                                label={e.name}
                                variant="outlined"
                              />
                            )}
                          />
                        </FormControl>
                      );
                    }
                  })
                : null
              : null}
          </div>
          <div className="flex flex-col text-slate-300 w-96 h-2/3 pt-2">
            <p className="text-sm text-slate-200 font-semibold tracking-widest  inline">
              File Name
            </p>

            {props.fileOverride ? (
              <div className="flex">
                <FormControl fullWidth margin="normal">
                  <TextField
                    id="outlined-basic"
                    value={overrideData || ""}
                    label={"Filename"}
                    onChange={(e) => handleChange(e, e.target.value, -1)}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            ) : (
              fileConfig.map((e, i) => {
                if (!e.editable) {
                  return (
                    <div className="flex flex-col">
                      <div className="flex">
                        <FormControl
                          error={names[i]?.trim() === ""}
                          fullWidth
                          margin="normal"
                        >
                          <InputLabel id={"label-" + i}>{e.name}</InputLabel>
                          <Select
                            labelId={"label" + i}
                            id={"select" + i}
                            value={names[i] || ""}
                            label={e.name}
                            onChange={(e) => {
                              handleChange(e, e.target.value, i);
                            }}
                          >
                            {e.possible.map((element) => {
                              return (
                                <MenuItem value={element}>{element}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {/* <Autocomplete
                      // disablePortal
                      // freeSolo
                      id={"combo-box-demo" + i}
                      options={e.possible}
                      sx={{ width: 300 }}
                      onChange={(event) => {
                        handleChange(event, i);
                      }}
                      value={names[i] || null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          label={e.name}

                          // onChange={(e) => handleChange(e, "resourcename")}
                        />
                      )}
                    /> */}
                        {/* <div id={i} className="flex items-center ">
                      <div>
                        <AddCircleIcon
                          onClick={(e) => {
                            const index = e.currentTarget.parentNode.id;
                            handleAddition(index);
                          }}
                        />
                      </div>
                      <div>
                        <RemoveIcon
                          onClick={(e) => {
                            const index = e.currentTarget.parentNode.id;
                            handleRemoval(index);
                          }}
                        />
                      </div>
                    </div> */}
                      </div>
                      {i < fileConfig.length - 1 ? (
                        <div className="">{delimiter[i]}</div>
                      ) : null}
                    </div>
                  );
                } else {
                  return (
                    <div className="flex flex-col">
                      <div className="flex">
                        <FormControl fullWidth margin="normal">
                          {/* <TextField
                          error={names[i]?.trim()===""}
                          id="outlined-basic"
                          value={names[i] || ""}
                          label={e.name}
                          onChange={(e) => handleChange(e, i)}
                          variant="outlined"
                        /> */}

                          <Autocomplete
                            disablePortal
                            freeSolo
                            disableClearable
                            clearOnEscape
                            // onClick={(event) => {
                            //   handleChange(event, i);
                            // }}
                            id={"combo-box" + i}
                            options={
                              Array.isArray(e.suggestion_values)
                                ? e.suggestion_values
                                : []
                            }
                            sx={{ width: 300 }}
                            onChange={(e, v) => {
                              handleChange(e, v, i);
                            }}
                            value={names[i] || null}
                            inputValue={names[i] || ""}
                            // onInputChange={(event, v) => {
                            //   handleInputChange(v, i);
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={(e) => {
                                  handleChange(e, e.target.value, i);
                                }}
                                margin="normal"
                                label={e.name}
                                variant="outlined"
                                // onChange={(e) => handleChange(e, "resourcename")}
                              />
                            )}
                          />
                        </FormControl>

                        {/* <div id={i} className="flex items-center ">
                      <div
                        onClick={(e) => {
                          const index = e.currentTarget.parentNode.id;
                          handleAddition(index);
                        }}
                      >
                        <AddCircleIcon
                          onClick={(e) => {
                            const index = e.currentTarget.parentNode.id;
                            handleAddition(index);
                          }}
                        />
                      </div>
                    </div> */}
                        {/* <div>
                      <RemoveIcon
                        onClick={(e) => {
                          const index = e.currentTarget.parentNode.id;
                          handleRemoval(index);
                        }}
                      />
                    </div> */}
                      </div>
                      {i < fileConfig.length - 1 ? (
                        <div>{delimiter[i]}</div>
                      ) : null}
                    </div>
                  );
                }
              })
            )}
          </div>
        </div>
      </div>
      <div className="flex-1 h-5/6 w-3/6">
        {visible && (
          <RenamePreview
            flagConfig={{
              combined:
                fileState.files[ofileIndex].name +
                "-" +
                ognames[fileState.files[ofileIndex].name][fileIndex],
              id: fileState.project_id,
              name: ognames[fileState.files[ofileIndex].name][fileIndex],
              category: fileState.files[ofileIndex].category,
              path: fileState.files[ofileIndex].path,
            }}
            index={fileIndex}
            flagSet={setFlags}
            pages={pages}
            url={preview}
            curTask={2}
            rename
            close={() => setVisible(false)}
            next={() => {
              nextFile();
            }}
          ></RenamePreview>
        )}
        {overrideRestore && fileState.files[ofileIndex]?.saved && (
          <LoadSaveScreen
            setNamingVis={setNamingVis}
            renametab
            hasSavedOps
            fileName={fileState.files[ofileIndex].name}
            handleRestoreAll={restoreSubmit}
            handleGetOriginal={() => {
              setOverride(false);
            }}
            handle
          />
        )}
        {/* {saveProgress.hasStarted && (
          <SavingState
            {...saveProgress}
            handleClose={() => {
              setSaveProgress({
                isTypeSave: true,
                hasStarted: false,
                progress: 0,
                error: false,
                errorText: null,
              });
            }}
          />
        )} */}
      </div>
    </div>
  );
};

{
}
