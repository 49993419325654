import { createSlice } from "@reduxjs/toolkit";
export const cropSlice = createSlice({
  name: "crop",
  initialState: {
    value: {
      cropState: false,
    },
  },
  reducers: {
    setCrop: (state, action) => {
      state.value = { cropState: action.payload };
    },
  },
});

export const { setCrop } = cropSlice.actions;
export default cropSlice.reducer;
