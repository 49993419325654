import React from "react";
import { Box, CircularProgress } from "@mui/material";
import logo_s from "../../assets/logo-s.png";
export default () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <img className="block lg:hidden h-20 w-auto" src={logo_s} alt="NuDoc" />
      <CircularProgress />
    </Box>
  );
};
