import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@mui/material";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { useSelector } from "react-redux";
import { AlternateEmail } from "@mui/icons-material";
export default (props) => {
  const authState = useSelector((state) => state.auth);
  //all the preset values 
  const [allocated, setAllocated] = useState([]);
  //one of the possible preset values
  const [textVal, setText] = useState("");
  const [presetName, setPresetName] = useState("");
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [autocompleteData, setAutoCompleteData] = useState([
    { label: "Test", values: ["hi", "hello"] },
  ]);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("api/preset/")
      .then((response) => {
        response.data.map((e) => {
          e.label = e.preset_name;
          e.values = e.possible_values.split(",");
        });
        setLoading(false);
        setAutoCompleteData(response.data);
      });
  }, []);

  useEffect(()=>{
    // console.log(props.toDisplay)
    if(props.toDisplay.possible){
      setAllocated(props.toDisplay.possible)
      setPresetName(props.toDisplay.presetName || "Previous Preset")
    }
  }, [props.open])
  const removeChip = (id) => {
    let newAll = [];
    for (let i = 0; i < allocated.length; i++) {
      if (id !== i) {
        newAll.push(allocated[i]);
      }
    }
    setAllocated(newAll);
  };
  const editChip = (id) => {
    setText(allocated[id]);
  };
  const addVal = () => {
    setAllocated([...allocated, textVal]);
    setText("");
  };
  const setAndSave = () => {
    console.log(authState.token);
    axios
      .post(
        "api/preset/",
        {
          preset_name: presetName,
          possible_values: allocated.join(","),
        }
      )
      .then((response) => {
        props.set(allocated, presetName);
        setAllocated([]);
        setPresetName("");
        setSelectedPreset(null);
      }).catch((err) => {
        alert(err.data.preset_name)
      })
  };
  const onlySet = () => {
    props.set(allocated, presetName);
    setAllocated([]);
    setPresetName("");
    setSelectedPreset(null);
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      PaperProps={{
        style: {
          backgroundColor: "#0F172A",
        },
      }}
    >
      <DialogTitle>Select Possible Values</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Divider textAlign="left">
              <Chip label="Possible Values" />
            </Divider>
            {!(allocated.length > 0) ? (
              <DialogContentText className="py-4">
                No Possible Values
              </DialogContentText>
            ) : (
              <div className="py-4 space-x-1 space-y-1">
                {allocated.map((e, i) => {
                  return (
                    <Chip
                      // label={e.resourcename.name}
                      label={e}
                      key={i}
                      color="primary"
                      onClick={(ev) => editChip(i)}
                      onDelete={(ev) => removeChip(i)}
                    />
                  );
                })}
              </div>
            )}
            <div className="w-full items-center flex">
              <TextField
                autoFocus
                margin="normal"
                id="field"
                label="Possible Value"
                type="text"
                value={textVal}
                onChange={(e) => setText(e.target.value)}
                variant="outlined"
              />
              <Button onClick={addVal}>Add</Button>
            </div>
            <TextField
              margin="normal"
              id="presetName"
              label="Preset Name"
              type="text"
              fullWidth
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
              variant="outlined"
            />
            <Divider textAlign="left">
              <Chip label="Or Select a Saved Preset" />
            </Divider>
            <Autocomplete
              // disablePortal
              id="combo-box-demo"
              options={autocompleteData}
              value={selectedPreset}
              sx={{ width: 300 }}
              onChange={(e, newValue) => {
                setSelectedPreset(newValue);
                setPresetName(newValue.label);
                setAllocated(newValue.values);
              }}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Saved Preset" />
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <Button onClick={onlySet}>Set</Button>
        <Button onClick={setAndSave}>Set And Save</Button>
      </DialogActions>
    </Dialog>
  );
};
