import { createSlice } from "@reduxjs/toolkit";
export const modalSlice = createSlice({
  name: "modal",
  initialState: {
    value: {
      modalState: false,
      deletetype: 0,
      from: null,
      to: null,
    },
  },
  reducers: {
    setModal: (state, action) => {
      state.value = { ...state.value, modalState: action.payload };
    },
    setType: (state, action) => {
      state.value = { ...state.value, deletetype: action.payload };
    },
    setInter: (state, action) => {
      state.value = {
        ...state.value,
        from: action.payload.from,
        to: action.payload.to,
      };
    },
  },
});

export const { setModal, setType, setInter } = modalSlice.actions;
export default modalSlice.reducer;
