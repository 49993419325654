import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import ControlledTreeView from "../components/FileTree/FileTree";
import axios from "axios";
import RenamePreview from "../components/PdfRename/RenamePreview";
import { useNavigate } from "react-router";
import { Navbar } from "../components/Navbar/Navbar";
import { Button, Typography } from "@mui/material";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Box, CircularProgress } from "@mui/material";
import { DownloadRounded } from "@mui/icons-material";
import HelperDialog from "../components/HelperDialog/HelperDialog";
import LinearProgress from "@mui/material/LinearProgress";
import { flushSync } from "react-dom";
import { setInter } from "../slice/modalSlice";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [fetcher, setFetcher] = useState(0);
  const [prev, setPrev] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogProp = useRef({});
  const [timer, setTimer] = useState(0);

  const decrementTimer = useCallback(() => {
    setTimer((oldTimer) => oldTimer - 1000);
  }, [timer]);

  useEffect(() => {
    if (timer <= 0) {
      return;
    }
    const timeoutFunction = setInterval(decrementTimer, 1000);
    return () => clearInterval(timeoutFunction);
  }, [decrementTimer, timer]);

  const [ctr1, setCtr1] = useState(0);
  const [ctr2, setCtr2] = useState(0);
  useEffect(() => {
    if (timer > 0) {
      setZLoading(true);
    } else {
      setZLoading(false);
    }
  }, [timer]);
  const [data, setData] = useState({
    name: "No data",
    children: [],
    id: "0000",
    status: "",
  });
  const [zipdata, setZipData] = useState({
    name: "No data",
    children: [],
    id: "0000",
  });
  let navigate = useNavigate();
  const fileState = useSelector((s) => s.file);
  const [dloadAllowed, setDloadAllowed] = useState(false);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  // const [catOptions, setCatoptions] = useState([]);
  // const category = useRef(null);
  const catOptions = useRef([]);
  const [zloading, setZLoading] = useState(false);
  const [dloading, setDLoading] = useState(false);
  const authState = useSelector((s) => s.auth);
  const [link, setLink] = useState(false);
  const [linkData, setLinkData] = useState("");
  const zipsortFunction = function (x, y) {
    if (x.last_modified > y.last_modified) {
      return -1;
    }
    if (x.last_modified < y.last_modified) {
      return 1;
    }
    return 0;
  };
  useEffect(() => {
    refetchFileTree(category);
  }, [fetcher]);

  const refetchFileTree = (n) => {
    if (n !== null) {
      axios
        .post("api/get-s3-tree/", {
          dir_name: `project/${fileState.project_id}/working/${n}/deskewed/`,
          show_all: true,
          project_id: fileState.project_id,
        })
        .then((response) => {
          var paths = response.data.file_names;
          var last_modified = response.data.last_modified;
          var status = response.data.status;
          var allocation = response.data.allocation_status;
          var ctr1 = 0;
          var pending_count = 0,
            completed = 0;
          response.data.status.forEach((obj) => {
            if (obj !== "Named" && obj !== "Verified" && obj !== "Zipped") {
              pending_count += 1;
            } else {
              completed += 1;
            }
          });

          const result = paths.reduce((r, p, i) => {
            var full = p.split(
              `project/${fileState.project_id}/working/${n}/deskewed/`
            );
            var names = (`${n}/` + full[1]).split("/");
            console.warn("names-----_>", names);
            names.reduce((q, name) => {
              var temp = q.find((o) => o.name === name);
              if (!temp)
                q.push(
                  (temp = {
                    id: ctr1.toString(),
                    path: p,
                    name,
                    children: [],
                    status: status[i],
                    allocation: allocation[i],
                    last_modified: last_modified[i],
                  })
                );
              console.warn("Status------------", status[i]);
              ctr1 += 1;
              return temp.children;
            }, r);
            return r;
          }, []);
          result[0].status = "Start";
          result[0].allocation = "";
          result[0].last_modified = "";
          result[0].path = "";
          result[0].name =
            result[0].name +
            " Pending:" +
            pending_count.toString() +
            " Completed:" +
            completed.toString();
          setData(result[0]);
          setCtr1(ctr1);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post("api/get-s3-tree/", {
          dir_name: `project/${fileState.project_id}/final/${n}/`,
          show_all: true,
          project_id: fileState.project_id,
        })
        .then((response) => {
          var paths = response.data.file_names;
          var last_modified = response.data.last_modified;
          var status = response.data.status;
          var ctr2 = 0;
          if (paths) {
            const result = paths.reduce((r, p, i) => {
              var full = p.split(`project/${fileState.project_id}/final/${n}/`);
              var names = (`${n}/` + full[1]).split("/");

              names.reduce((q, name) => {
                var temp = q.find((o) => o.name === name);
                if (!temp)
                  q.push(
                    (temp = {
                      id: ctr2.toString(),
                      path: p,
                      name,
                      children: [],
                      status: status[i],
                      last_modified: last_modified[i],
                    })
                  );

                ctr2 += 1;
                return temp.children;
              }, r);
              return r;
            }, []);
            console.warn(result);
            result[0].children = result[0].children.sort(zipsortFunction);
            setZipData(result[0]);
            setCtr2(ctr2);
          } else {
            setZipData({
              name: "No Zips generated",
              children: [],
              id: "0000",
            });
            setCtr2(ctr2);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  useEffect(() => {
    axios
      .get("api/root-category/?project_id=" + fileState.project_id)
      .then((r) => {
        catOptions.current = r.data;

        setLoading(false);
      })
      .catch((e) => {
        // props.setSnackbarData(e);
        // props.setSnackbar(true);
        // props.setSnackType("error");
        alert("some error occurred, logged");
        console.error(e);
      });
    setDloadAllowed(true);
  }, []);

  const handleChange = (e, n) => {
    setCategory(n.category);
    refetchFileTree(n.category);
  };
  return (
    <div
      className="w-full h-screen flex flex-col bg-slate-800"
      // onKeyDown={(e) => {
      //   console.log(e.key);
      // }}
    >
      <Navbar />
      <div className="w-full flex ">
        <div className="flex flex-col space-y-1 px-2">
          <HelperDialog open={dialogOpen} {...dialogProp.current} />
          <Typography fontSize={32} color={"white"} margin={3}>
            Verify
          </Typography>
          <Button
            variant="contained"
            color="error"
            size="small"
            startIcon={<ExitToAppRoundedIcon />}
            onClick={() => {
              setDialogOpen(true);
              dialogProp.current = {
                okText: "Quit",
                title: "Quit Verification?",
                desc: "Un-uploaded work wont be saved. Are you sure you want to quit?",
                handleClose: () => {
                  setDialogOpen(false);
                },
                onSubmit: () => {
                  navigate("/dashboard", { replace: true });
                },
              };
            }}
          >
            Quit
          </Button>
          <Button
            // margin="normal"
            variant="contained"
            startIcon={<DownloadRounded />}
            disabled={authState.privilege !== 1}
            // onClick={() => setDloadDialog(true)}
            onClick={() => {
              if (category) {
                setZLoading(true);
                axios
                  .get(
                    "api/download-project/" +
                      fileState.project_id +
                      "?category=" +
                      category
                  )
                  .then((response) => {
                    if (response.data.files !== 0) {
                      setPrev(null);
                      setTimeout(() => {
                        setFetcher(fetcher + 1);
                      }, response.data.files * 2000);
                      setTimer(response.data.files * 2000);

                      // setDuration(30000)
                      //   let duration=20000
                      //   const interval=setInterval(()=>{
                      //   duration=duration-1000
                      //   // setDuration(duration-1000);
                      //   console.log("in interval",duration)

                      //   if (duration === 0){
                      //     clearInterval(interval);
                      //   }

                      // },1000)

                      alert(
                        `Please wait for ${Math.round(
                          (response.data.files * 2) / 60
                        )} minutes for the zipping to complete`
                      );
                    } else {
                      alert(`No new files to zip`);
                    }
                    // setLinkData(response.data.url);
                    // setLink(true);
                  })
                  .catch(alert);
              }
            }}
          >
            Create Zip
          </Button>
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Autocomplete
              // disablePortal
              id="categories"
              options={catOptions.current}
              getOptionLabel={(option) =>
                option.category + "- Zip Pending: " + option.pending
              }
              // sx={{ width: 300 }}
              // value={catOptions.current[category]}
              onChange={handleChange}
              // value={category.current}
              renderInput={(params) => (
                <TextField {...params} margin="normal" label="Category" />
              )}
            />
          )}
          {/* <DownloadDialog
            onClose={() => setDloadDialog(false)}
            dloadDisabled={!dloadAllowed}
            open={dloadDialogOpen}
            id={fileState.project_id}
            token={authState.token}
            onSubmit={() => {
              
            }}
          /> */}
          {/* <Button
            variant="contained"
            // margin="normal"
            startIcon={<DownloadRounded />}
            onClick={() => {
              setDialogOpen(true);
              dialogProp.current = {
                onSubmit: verifyFiles,
                handleClose: () => {
                  setDialogOpen(false);
                },
                title: "Confirm Project Verification?",
                desc: "Are all the files verified? This action cannot be undone.",
                okText: "Submit",
              };
            }}
          >
            Verify All
          </Button> */}
          <p className="text-sm text-slate-200 font-semibold tracking-widest text-xl inline">
            Files
          </p>
          <div className="border-t w-96 border-slate-500"></div>
          <ControlledTreeView
            fetcher={fetcher}
            setFetcher={setFetcher}
            id={fileState.project_id}
            data={data}
            timestamp={true}
            // className="flex-1"
            token={authState.token}
            verify={false}
            setPrev={setPrev}
            limit={ctr1}
          ></ControlledTreeView>

          <p className="text-sm text-slate-200 font-semibold tracking-widest text-xl inline">
            Zips generated
          </p>
          <div className="border-t w-96 border-slate-500"></div>
          <ControlledTreeView
            setDLoading={setDLoading}
            zip={true}
            timestamp={true}
            data={zipdata}
            id={fileState.project_id}
            // className="flex-1"
            token={authState.token}
            verify={true}
            setPrev={setPrev}
            limit={ctr2}
          ></ControlledTreeView>
        </div>
        {zloading && timer > 0 && (
          // <Box sx={{ display: "flex" }}>
          // {/* </Box> */}
          <div className="flex items-center  justify-center w-full z-50">
            <CircularProgress />
            <Typography variant="h3" color="common.white">
              Compressing Files Please Wait for {parseInt(timer / 60000, 10)}{" "}
              minutes : {(timer % 60000) / 1000} seconds
            </Typography>
          </div>
        )}{" "}
        {dloading && (
          <div className="flex items-center  justify-center w-full z-50">
            <CircularProgress />
            <Typography variant="h3" color="common.white">
              Downloading Zip Please wait
            </Typography>
          </div>
        )}
        {prev !== null && (
          <RenamePreview
            rename={false}
            close={() => setPrev(null)}
            curTask={3}
            {...prev}
          />
        )}
      </div>
    </div>
  );
};
