import React, { useEffect, useState } from "react";
import axios from "axios";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackRounded from "@mui/icons-material/ArrowBackRounded";
import DisplayTable from "./DisplayTable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ProjectInfo from "./ProjectInfo";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useLocation } from "react-router-dom";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  console.log("Projects Page");
  const location = useLocation();

  const authState = useSelector((state) => state.auth);
  const [isSubmitting, setSubmitting] = useState(false);

  const [openSuccessSnackBar, setSuccessSnackbar] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [snackBardata, setSnackbarData] = useState("");
  const [snackType, setSnackType] = useState("success");
  const [userPriv, setUserPriv] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [init, setInit] = useState(
    location.state ? location.state.init : false
  );
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };
  function SlideTransition(props) {
    return <Slide {...props} />;
  }
  const modifyTable = (received) => {
    setTableData(received);
  };
  const updateTable = () => {
    setSnackbarData("Updating Data");
    setSnackType("info");
    setSuccessSnackbar(true);
    if (authState.privilege === 1)
      axios
        .get("api/sales-project-list/")
        .then(function (response) {
          console.log(response.data);
          const parsed_data = response.data.map((row, i) => {
            return { ...row, file_allot: true, table_id: i };
          });
          setTableData(parsed_data);
          setTableLoading(false);
          setSnackbarData("Updated project Data");
          setSnackType("success");
          setSuccessSnackbar(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    else {
      axios
        .get("api/user-project-list/?username=" + authState.name)
        .then(function (response) {
          setTableLoading(false);
          const newPerms = {};
          response.data.permission.map((row, i) => {
            newPerms[row.project] = {};
            row.permission.split(",").map((e) => {
              newPerms[row.project][e] = true;
            });
          });

          // console.log(response.data);
          const parsed_data = response.data.project.map((row, i) => {
            return { ...row, file_allot: true, table_id: i };
          });
          setUserPriv(newPerms);
          setTableData(parsed_data);
          setSnackbarData("Project data has been updated");
          setSnackType("success");
          setSuccessSnackbar(true);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleSuccessSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
  };

  //data fetching
  useEffect(() => {
    axios
      .get("api/customer-list/")
      .then((response) => {
        const customerslist = response.data.map((custconfig) => {
          return custconfig.name;
        });
        setClientState(customerslist);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("api/marketing-list/")
      .then((response) => {
        const channellist = response.data.map((custconfig) => {
          return custconfig.username;
        });
        setChannelState(channellist);
      })
      .catch((error) => {
        console.log(error);
      });
    updateTable();
  }, []);
  const [previewState, setPreview] = useState(false);
  const [buttonState, setButton] = useState(false);
  const [formdata, setFormData] = useState({
    name: { value: "", valid: false, required: true },
    client: { value: "", valid: false, required: true },
    rep1_name: { value: "", valid: false, required: true },
    rep1_mobile: { value: "", valid: false, required: true },
    rep1_email: { value: "", valid: false, required: true },
    rep1_department: { value: "", valid: false, required: true },
    referred_by: { value: "", valid: false, required: true },
  });
  const [clientState, setClientState] = useState([]);
  const [infoID, setInfoID] = useState(-1);
  const [channelState, setChannelState] = useState([]);
  const [errormessage, setErrorMessage] = useState(null);
  const [privState, setPriveState] = useState({
    c_upl_str: true,
    c_allot: true,
    c_dload: true,
    c_verify: true,
    c_flag: true,
    c_create: true,
  });

  const openPreview = () => {
    setPreview(true);
  };

  const handleClose = () => {
    setPreview(false);
  };
  const submitHandler = () => {
    let readytosubmit = true;
    let flag = true;
    Object.keys(formdata).forEach((key) => {
      if (formdata[key].required) {
        if (formdata[key].valid) {
          readytosubmit = true;
        } else {
          readytosubmit = false;
          flag = false;
        }
      } else {
        if (formdata[key].valid) {
          readytosubmit = true;
        } else {
          readytosubmit = false;
          flag = false;
        }
      }
    });

    if (flag && readytosubmit) {
      let api_response = {};
      Object.keys(formdata).forEach(function (key) {
        api_response[key] = formdata[key].value;
      });
      api_response = {
        ...api_response,
      };
      setErrorMessage(null);
      setPreview(false);
      setSubmitting(true);
      axios
        .post(
          "api/sales-project-create/",

          {
            ...api_response,
            // client_id: 1,
            status: "Resource assignment pending",
          }
        )
        .then(function (response) {
          console.log(response);
          updateTable();
          handleClose();
          setSnackbarData("Project has been created successfully");
          setSnackType("success");
          setSuccessSnackbar(true);
          setSubmitting(false);
          setFormData({
            name: "",
            address: "",
            client: "",
            email_id: "",
            rep1_name: "",
            rep1_mobile: "",
            rep1_email: "",
            rep1_department: "",
            referred_by: "",
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("Here");
      setSnackbarData("Please fill all fields that are marked in red");
      setSnackType("error");
      setSuccessSnackbar(true);
    }
  };
  const errorHandler = (value, type, req) => {
    if (type === "name") {
      if (value.match(/^[a-zA-Z ]*$/)) {
        return true;
      } else {
        if (!req && value == "") {
          return true;
        }
      }
      return false;
    }

    if (type === "GST") {
      if (
        value.match(
          /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9]{1}Z[a-zA-Z0-9]{1}$|^$/
        )
      ) {
        return true;
      }
      return false;
    }

    if (type === "mobile") {
      if (value.match(/^[789]\d{9}$/)) {
        return true;
      } else {
        if (!req && value == "") {
          return true;
        }
      }
      return false;
    }
    if (type === "email") {
      if (
        value.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return true;
      } else {
        if (!req && value == "") {
          return true;
        }
      }
      return false;
    }
    if (req && value != "") {
      return true;
    }
    if (!req) {
      return true;
    }
  };

  return infoID === -1 ? (
    <div className=" m-8 flex flex-col space-y-6 mt-16 w-10/12 ">
      {privState.c_create && (
        <>
          {authState.privilege == 1 && (
            <Tooltip title="Create a new Project">
              <Fab
                color="primary"
                aria-label="add"
                onClick={openPreview}
                sx={{
                  zIndex: 40,
                }}
              >
                {!buttonState ? <AddIcon /> : <ArrowBackRounded />}
              </Fab>
            </Tooltip>
          )}
          {/* <Snackbar
            sx={{ width: 600 }}
            anchorOrigin={{
              height: 100,
              vertical: "top",
              horizontal: "center",
            }}
            TransitionComponent={SlideTransition}
            open={location.state ? (init != null ? true : false) : false}
            onClose={handleSnackbarClose}
            autoHideDuration={5000}
          >
            <Alert
              variant="filled"
              severity="info"
              sx={{
                width: "100%",
                fontSize: 20,
              }}
            >
              WELCOME {authState.name.toUpperCase()}
            </Alert>
          </Snackbar> */}
          <Snackbar
            sx={{ width: 600 }}
            anchorOrigin={{
              height: 100,
              vertical: "top",
              horizontal: "center",
            }}
            TransitionComponent={SlideTransition}
            open={openSuccessSnackBar}
            onClose={handleSuccessSnackbarClose}
            autoHideDuration={5000}
          >
            <Alert
              variant="filled"
              severity={snackType}
              sx={{
                width: "100%",
                fontSize: 20,
              }}
            >
              {snackBardata}
            </Alert>
          </Snackbar>
        </>
      )}
      {/* {privState.c_upl_str && <Dropzone />} */}
      {/* accept={"pdf/*"} */}
      <>
        <>
          <Tooltip title="Refresh for latest Project Data">
            <Button
              color="primary"
              variant="contained"
              aria-label="refresh"
              onClick={updateTable}
              sx={{
                zIndex: 40,
                maxWidth: "30px",
              }}
            >
              <RefreshIcon />
            </Button>
          </Tooltip>
        </>
        <DisplayTable
          privState={privState}
          setInfoID={setInfoID}
          updateTableData={updateTable}
          tableData={tableData}
          modifyTable={modifyTable}
          setSnackbar={setSuccessSnackbar}
          setSnackbarData={setSnackbarData}
          setSnackType={setSnackType}
          tableLoading={tableLoading}
          userPriv={userPriv}
        />
      </>
      {previewState && (
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "#0F172A",
            },
          }}
          open={previewState}
          onClose={handleClose}
        >
          <DialogTitle>CREATE A NEW PROJECT</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To initiate a new project please enter the details and submit this
              form.
            </DialogContentText>
            <DialogContentText>
              (All Fields with * are required)
            </DialogContentText>
            <div className="flex space-x-2">
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Project Name"
                type="name"
                variant="outlined"
                required
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(
                        event.target.value,
                        "project-name",
                        true
                      ),
                      required: true,
                    },
                  });
                }}
                value={formdata.name ? formdata.name.value : undefined}
                error={formdata.name ? !formdata.name.valid : undefined}
              />
              <Autocomplete
                // disablePortal
                id="client"
                options={clientState}
                value={formdata.client ? formdata.client.value : null}
                sx={{ width: 200 }}
                onChange={(event, value) => {
                  setFormData({
                    ...formdata,
                    client: {
                      value,
                      valid: errorHandler(value, "client-name", true),
                      required: true,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    margin="dense"
                    label="Client Name"
                    error={formdata.client ? !formdata.client.valid : undefined}
                  />
                )}
              />
            </div>

            <TextField
              autoFocus
              margin="dense"
              id="gst_number"
              label="GST"
              fullWidth
              variant="outlined"
              onChange={(event) => {
                setFormData({
                  ...formdata,
                  [event.target.id]: {
                    value: event.target.value,
                    valid: errorHandler(event.target.value, "GST", false),
                    required: true,
                  },
                });
              }}
              value={
                formdata.gst_number ? formdata.gst_number.value : undefined
              }
              error={
                formdata.gst_number ? !formdata.gst_number.valid : undefined
              }
            />

            <Divider textAlign="left" sx={{ m: 2 }}>
              <Chip label="Project Details" />
            </Divider>
            <Divider textAlign="left">
              <Chip label="Representative 1" />
            </Divider>
            <div className="flex space-x-2">
              <TextField
                autoFocus
                margin="dense"
                required
                id="rep1_name"
                label="name"
                type="name"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "name", true),
                      required: true,
                    },
                  });
                }}
                value={
                  formdata.rep1_name ? formdata.rep1_name.value : undefined
                }
                error={
                  formdata.rep1_department
                    ? !formdata.rep1_name.valid
                    : undefined
                }
              />

              <TextField
                autoFocus
                margin="dense"
                required
                id="rep1_mobile"
                label="Mobile no"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "mobile", true),
                      required: true,
                    },
                  });
                }}
                value={
                  formdata.rep1_mobile ? formdata.rep1_mobile.value : undefined
                }
                error={
                  formdata.rep1_mobile ? !formdata.rep1_mobile.valid : undefined
                }
              />
              <TextField
                autoFocus
                margin="dense"
                id="rep1_email"
                label="email"
                type="email"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "email", true),
                    },
                  });
                }}
                value={
                  formdata.rep1_email ? formdata.rep1_email.value : undefined
                }
                error={
                  formdata.rep1_email ? !formdata.rep1_email.valid : undefined
                }
              />
              <TextField
                autoFocus
                required
                margin="dense"
                id="rep1_department"
                label="Dept"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "dept", true),
                      required: true,
                    },
                  });
                }}
                value={
                  formdata.rep1_department
                    ? formdata.rep1_department.value
                    : undefined
                }
                error={
                  formdata.rep1_department
                    ? !formdata.rep1_department.valid
                    : undefined
                }
              />
            </div>
            <Divider textAlign="left">
              <Chip label="Representative 2" />
            </Divider>
            <div className="flex space-x-2">
              <TextField
                autoFocus
                margin="dense"
                id="rep2"
                label="name"
                type="name"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "name", false),
                    },
                  });
                }}
                value={formdata.rname2 ? formdata.rname2.value : undefined}
                error={formdata.rname2 ? !formdata.rname2.valid : undefined}
              />
              <TextField
                autoFocus
                margin="dense"
                id="remail2"
                label="email"
                type="email"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "email", false),
                    },
                  });
                }}
                value={formdata.remail2 ? formdata.remail2.value : undefined}
                error={formdata.remail2 ? !formdata.remail2.valid : undefined}
              />
              <TextField
                autoFocus
                margin="dense"
                id="rmnumber2"
                label="Mobile no"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "mobile", false),
                    },
                  });
                }}
                value={
                  formdata.rmnumber2 ? formdata.rmnumber2.value : undefined
                }
                error={
                  formdata.rmnumber2 ? !formdata.rmnumber2.valid : undefined
                }
              />
              <TextField
                autoFocus
                margin="dense"
                id="rdept2"
                label="Dept"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: event.target.value,
                      valid: errorHandler(event.target.value, "dept", false),
                    },
                  });
                }}
                value={formdata.rdept2 ? formdata.rdept2.value : undefined}
                error={formdata.rdept2 ? !formdata.rdept2.valid : undefined}
              />
            </div>
            <Divider textAlign="left">
              <Chip label="Channel data" />
            </Divider>
            <div className="flex space-x-2">
              <Autocomplete
                // disablePortal
                id="referred_by"
                options={channelState}
                value={formdata.referred_by ? formdata.referred_by.value : null}
                sx={{ width: 200 }}
                onChange={(event, value) => {
                  console.log(value);
                  setFormData({
                    ...formdata,
                    referred_by: {
                      value,
                      valid: errorHandler(value, "channel-name", true),
                      required: true,
                    },
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    required
                    label="Channel Name"
                    error={
                      formdata.referred_by
                        ? !formdata.referred_by.valid
                        : undefined
                    }
                  />
                )}
              />

              <TextField
                autoFocus
                margin="dense"
                id="lumpsum_share"
                label="Lumpsum Share"
                type="number"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: parseInt(event.target.value),
                      valid: true,
                      required: !formdata.per_page_share.valid,
                    },
                  });
                }}
                value={
                  formdata.lumpsum_share
                    ? formdata.lumpsum_share.value
                    : undefined
                }
                error={
                  formdata.lumpsum_share
                    ? !formdata.lumpsum_share.valid
                    : undefined
                }
              />
              <TextField
                autoFocus
                margin="dense"
                id="per_page_share"
                label="Perpage Share"
                variant="outlined"
                onChange={(event) => {
                  setFormData({
                    ...formdata,
                    [event.target.id]: {
                      value: parseInt(event.target.value),
                      valid: true,
                      required: !formdata.per_page_share.valid,
                    },
                  });
                }}
                value={
                  formdata.per_page_share
                    ? formdata.per_page_share.value
                    : undefined
                }
                error={
                  formdata.per_page_share
                    ? !formdata.per_page_share.valid
                    : undefined
                }
              />
            </div>

            <TextField
              autoFocus
              margin="dense"
              id="remark"
              label="Remarks"
              fullWidth
              variant="outlined"
              type="text"
              multiline
              rows={3}
              onChange={(event) => {
                setFormData({
                  ...formdata,
                  [event.target.id]: {
                    value: event.target.value,
                    valid: errorHandler(event.target.value, "remark", false),
                    required: false,
                  },
                });
              }}
              value={formdata.remark ? formdata.remark.value : undefined}
              error={formdata.remark ? !formdata.remark.valid : undefined}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            {isSubmitting ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <div>
                <Button onClick={submitHandler}>Submit</Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  ) : (
    <ProjectInfo
      setSnackbar={setSuccessSnackbar}
      setSnackbarData={setSnackbarData}
      setSnackType={setSnackType}
      token={authState.token}
      id={infoID}
      projectData={tableData}
      setInfoID={setInfoID}
      updateTable={updateTable}
    />
  );
};
