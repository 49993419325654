import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DocumentIcon } from "@heroicons/react/solid";
import { setFiles, setTask } from "../../../slice/fileSlice";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import axios from "axios";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { addBlob, clearBlobs } from "../../../slice/blobSlice";
import { InfoRounded } from "@mui/icons-material";
import { keys } from "idb-keyval";
import FlagIcon from "@mui/icons-material/Flag";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#0F172A",
  color: theme.palette.text.secondary,
  height: 60,
  alignItems: "center",
  paddingLeft: 8,
  paddingRight: 8,
  lineHeight: "60px",
}));

const checkForSaved = async (projectID, splitNames, split = true) => {
  let keyList = await keys();
  let newKeyList = [];
  let newOpsKeyList = [];
  for (let z of keyList) {
    if (z.startsWith("--og--")) {
      let [proj_id, cat] = z.slice(6, z.lastIndexOf("--")).split("#");
      newKeyList.push({
        proj_id: parseInt(proj_id),
        cat,
        file_name: z.slice(z.lastIndexOf("--") + 2),
      });
    } else if (z.startsWith("--ops--")) {
      let [proj_id, cat] = z.slice(7, z.lastIndexOf("--")).split("#");
      newOpsKeyList.push({
        proj_id: parseInt(proj_id),
        cat,
        file_name: z.slice(z.lastIndexOf("--") + 2),
      });
    }
  }
  console.log(splitNames);
  let newNames = [];
  for (let obj of splitNames) {
    for (let key of newKeyList) {
      if (
        key.file_name === obj.name &&
        key.proj_id === projectID &&
        obj.category === key.cat
      ) {
        obj.saved = true;
      }
    }
    for (let key of newOpsKeyList) {
      if (
        key.file_name === obj.name &&
        key.proj_id === projectID &&
        obj.category === key.cat
      ) {
        obj.savedOps = true;
      }
    }
  }
  return splitNames;
};

const checkForNamedSaved = async (projectID, splitNames) => {
  let keyList = await keys();
  let newKeyList = [];
  for (let z of keyList) {
    let combined = z.slice(16, z.lastIndexOf("--")).split("--");
    newKeyList.push({
      proj_id: parseInt(combined[0]),
      category: combined[1],
      file_name: z.slice(z.lastIndexOf("--") + 2),
    });
  }
  let newNames = [];
  for (let obj of splitNames) {
    console.log(obj);
    for (let key of newKeyList) {
      if (
        key.file_name === obj.name &&
        key.proj_id === projectID &&
        key.category == obj.category
      ) {
        // newNames.push(obj.name+" [Saved]")
        obj.saved = true;
      }
      // else{
      //   // newNames.push(obj.name)
      // }
    }
  }
  return splitNames;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProjectData = (props) => {
  const authState = useSelector((state) => state.auth);
  let displayFields = [];
  const [tab, setTab] = React.useState(0);
  let dialogTitle = "Untitled Project";
  const [isLoading, setLoading] = useState(true);
  const [selectAllCheck, setSelectAll] = useState(false);
  const [verifyDisabled, setVerifyable] = useState(true);
  const [formData, setFormData] = useState({});
  const splitQueue = useRef([]);
  const renameQueue = useRef([]);
  const [configData, setConfigData] = useState([]);
  const [checkboxCount, setCheckboxCount] = useState([0, 0]);
  const [pendingFiles, setPendingFiles] = useState(0);
  const [statsLoading, setStatsLoading] = useState(true);
  // const [buttonLoadingId, setBtnLoading] = useState(-1);
  const verifyQueue = useRef([]);
  const stats = useRef({ naming: 0, splitting: 0 });
  const dispatch = useDispatch();
  let navigate = useNavigate();
  // console.log(props.userPriv)
  useEffect(() => {
    console.log("here");
    setLoading(true);
    setStatsLoading(true);
    if (!props.record.id && props.record.id !== 0) {
      return;
    }
    axios
      .get("api/user-stats/" + props.projectData.id, {
        params: {
          stat_type: "pending",
        },
      })
      .then((r) => {
        console.log(r);
        stats.current.splitting = r.data.splitting_left;
        stats.current.naming = r.data.naming_left;
        setStatsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .post("api/user-files/", {
        user: authState.name,
        project: props.projectData.id,
      })
      .then((response) => {
        splitQueue.current = response.data.splitting_qs.filter((obj) => {
          if (obj.status === "Deskewed") {
            return true;
          } else {
            return false;
          }
        });

        renameQueue.current = response.data.naming_qs.filter((obj) => {
          if (obj.status === "Splitted") {
            return true;
          } else {
            return false;
          }
        });
        console.log(renameQueue);
        splitQueue.current.map((e) => {
          e.name = e.file_name;
          e.completed = false;
          e.extracted = [];
          console.log(e);
        });
        renameQueue.current.map((e) => {
          e.name = e.file_name;
          e.path = e.file;
          e.completed = false;
        });
        let newForm = {};
        splitQueue.current.map((e, i) => {
          newForm[i] = false;
        });
        setFormData(newForm);

        var q = Promise.all([
          checkForSaved(props.projectData.id, splitQueue.current),
          checkForNamedSaved(props.projectData.id, renameQueue.current),
        ]);
        return q;
        // renameQueue.current.filter(e=>{

        // })
        // verifyQueue.current = response.data.verification_qs;
      })
      .then((r) => {
        splitQueue.current = r[0];
        renameQueue.current = r[1];
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.record.id, props.update]);

  const handleRedirect = () => {
    let redirectPath = tab === 0 ? "/editor" : "/rename";
    dispatch(
      setTask({
        project_id: props.projectData.id,
      })
    );
    if (tab === 0) {
      dispatch(setFiles(splitQueue.current.filter((e, i) => formData[i] && e)));

      navigate(redirectPath, {
        replace: true,
        state: {
          id: props.projectData.id,
          remarks: props.projectData.remark,
        },
      });
    } else {
      dispatch(
        setFiles(renameQueue.current.filter((e, i) => formData[i] && e))
      );
      var folderNaming;
      var fileNaming;
      var children;
      var cats = renameQueue.current
        .filter((e, i) => formData[i] && e)
        .map((obj) => {
          return obj.category;
        });
      console.log(cats);
      Promise.all([
        axios.get("api/naming-pattern/", {
          params: {
            id: props.projectData.id,
          },
        }),

        axios.post("api/child-files/", {
          project_id: props.projectData.id,
          parent_files: renameQueue.current
            .filter((e, i) => formData[i] && e)
            .map((obj) => {
              return obj.file;
            }),
        }),
      ])
        .then((r) => {
          console.log(r);
          let newData = r[0].data;
          newData.map((e) => {
            console.log(e);
            e.editable = !e.locked;
            e.possible = e.locked ? e.possible_values.split(",") : null;
          });
          folderNaming = newData.filter((el) => el.type === "folder");
          var folder = folderNaming.map((folderobj) => {
            var suggestions = [];
            folderobj.suggestion_values.forEach((obj) => {
              console.log(obj);
              suggestions.concat(obj.suggestions);
            });
            console.log(suggestions);
            return { ...folderobj, suggestion_values: suggestions };
          });
          console.log(folder);
          fileNaming = newData.filter((el) => el.type === "file");
          var file = fileNaming.map((fileobj) => {
            var suggestions = [];
            fileobj.suggestion_values.forEach((obj) => {
              console.log(obj.suggestions);
              suggestions.push(...obj.suggestions);
              console.log(suggestions);
            });
            console.log(suggestions);
            return { ...fileobj, suggestion_values: suggestions };
          });
          console.log(file);
          dispatch(clearBlobs());
          var ognames = {};
          r[1].data.files.forEach((file, index) => {
            var nameslist = [];
            var obj = file.child_files.map((sfile, i) => {
              nameslist.push(sfile);
              return {
                name: sfile,
                url: file.child_files_urls[i],
                path: file.child_files_s3_path[i],
              };
            });
            ognames[
              file.parent_file.includes("_deskewed")
                ? file.parent_file.split("/").pop().split("_deskewed")[0] +
                  ".pdf"
                : file.parent_file.split("/").pop()
            ] = nameslist;
            dispatch(
              addBlob({
                filename: index,
                blobArray: obj,
              })
            );
          });
          console.log(file, folder);
          navigate(redirectPath, {
            replace: true,
            state: {
              id: props.projectData.id,
              fileNaming: file,
              folderNaming: folder,
              cats,
              ognames,
            },
          });
        })

        .catch(function (error) {
          console.log(error);
        });
    }
  };

  if (props.open) {
    displayFields = configData;

    if (props.record.details !== null) {
      dialogTitle = props.record.name;
    }
  }
  // const isWorkNotAllocated =
  //   props.record.allocated === null ||
  //   props.record.allocated === undefined ||
  //   props.record.allocated == [];
  const isWorkNotAllocated = false;
  const handleChange = (event, newValue) => {
    setSelectAll(false);
    console.log(newValue);
    setTab(newValue);
    if (newValue !== 2) {
      let queueToMap =
        newValue === 0 ? splitQueue.current : renameQueue.current;
      let newForm = {};
      queueToMap.map((e, i) => {
        newForm[i] = false;
      });
      setFormData(newForm);
    }
  };

  const toggleSelect = (pos, split = false) => {
    var curstate = { ...formData };
    console.warn(curstate);
    var len = Object.keys(formData).length;
    console.warn(len);
    var mod = [...checkboxCount];
    console.warn(mod, selectAllCheck);

    // curstate.fill(!selectAllCheck)
    if (split) {
      mod[pos] = 0;
      for (let key of Object.keys(formData)) {
        if (!splitQueue.current[key].major_issue) {
          mod[pos] = mod[pos] + 1;
          curstate[key] = !selectAllCheck;
        }
      }
    } else {
      mod[pos] = selectAllCheck ? 0 : len;
      // curstate.fill(!selectAllCheck)
      for (let key of Object.keys(formData)) {
        curstate[key] = !selectAllCheck;
      }
    }

    console.warn("FInal mod", mod);
    setCheckboxCount(mod);
    setFormData(curstate);
    setSelectAll(!selectAllCheck);
  };

  const handleCheckbox = (ev, inputField, pos) => {
    setFormData({ ...formData, [inputField]: ev.target.checked });
    if (ev.target.checked) {
      var mod = [...checkboxCount];
      mod[pos] = mod[pos] + 1;
      setCheckboxCount(mod);
      console.log(checkboxCount[pos]);
    } else {
      var mod = [...checkboxCount];
      mod[pos] = mod[pos] - 1;
      setCheckboxCount(mod);
    }
  };
  return (
    <>
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#0F172A",
          },
        }}
        fullWidth
        open={() => {
          props.open();
        }}
        onClose={(e, reason) => {
          console.log(reason);
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
      >
        {/* <DialogTitle>{dialogTitle}</DialogTitle> */}
        <DialogTitle>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                props.handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <div className="">
                {displayFields.map((e) => {
                  return (
                    <div className="w-full flex justify-between space-x-2">
                      <p>{e.label + ": "}</p>
                      <p className={e.addStyle}> {e.value}</p>
                    </div>
                  );
                })}
              </div>
              {verifyQueue.current.length > -1 ? (
                <div>
                  <Button
                    variant="contained"
                    sx={{ marginRight: 3 }}
                    onClick={() => {
                      dispatch(
                        setTask({
                          project_id: props.projectData.id,
                        })
                      );
                      navigate("/verify", {
                        replace: true,
                      });
                    }}
                    disabled={
                      // verifyDisabled ||
                      authState.privilege === 0 &&
                      !props.userPriv.c_verification
                    }
                  >
                    Verify
                  </Button>
                  {authState.privilege === 1 ||
                  props.userPriv.c_verification ? (
                    <Chip
                      color="warning"
                      variant="outlined"
                      icon={<InfoRounded />}
                      label={
                        pendingFiles > 0
                          ? pendingFiles + " File(s) pending"
                          : "--"
                      }
                    />
                  ) : null}
                </div>
              ) : (
                "No Tasks Alloted"
              )}

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label={"Split(" + splitQueue.current.length + ")"}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={"Rename(" + renameQueue.current.length + ")"}
                    {...a11yProps(1)}
                  />
                  <Tab label="Stats" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                {splitQueue.current.sort(function (x, y) {
                  if (x.name < y.name) {
                    return -1;
                  }
                  if (x.name > y.name) {
                    return 1;
                  }
                  return 0;
                }).length > 0 ? (
                  <div>
                    <div className="flex flex-col overflow-y-auto">
                      <FormControlLabel
                        label="Select All"
                        control={
                          <Checkbox
                            checked={selectAllCheck}
                            onChange={() => toggleSelect(0, true)}
                          />
                        }
                      />
                      {splitQueue.current.map((e, i) => {
                        return (
                          <FormControlLabel
                            label={
                              e.name +
                              " - " +
                              e.category +
                              (e.saved ? " [Saved]" : "")
                            }
                            key={i}
                            control={
                              <>
                                <Checkbox
                                  checked={
                                    formData[i] !== undefined && formData[i]
                                  }
                                  onChange={(ev) => handleCheckbox(ev, i, 0)}
                                  disabled={e.major_issue}
                                />
                                {e.flags && <FlagIcon fontSize="small" />}
                              </>
                            }
                          />
                        );
                      })}
                    </div>
                    <Button
                      disabled={!(checkboxCount[0] > 0)}
                      variant="contained"
                      onClick={handleRedirect}
                      fullWidth
                    >
                      Start
                    </Button>
                  </div>
                ) : (
                  "No Tasks Alloted"
                )}
              </TabPanel>
              <TabPanel value={tab} index={1}>
                {renameQueue.current.length > 0 ? (
                  <div>
                    <div className="flex flex-col overflow-y-auto">
                      <FormControlLabel
                        label="Select All"
                        control={
                          <Checkbox
                            checked={selectAllCheck}
                            onChange={() => toggleSelect(1)}
                          />
                        }
                      />
                      {renameQueue.current
                        .sort(function (x, y) {
                          if (x.name < y.name) {
                            return -1;
                          }
                          if (x.name > y.name) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((e, i) => {
                          console.log(e.flags);
                          return (
                            <FormControlLabel
                              label={
                                e.name +
                                " - " +
                                e.category +
                                (e.saved ? " [Saved]" : "")
                              }
                              control={
                                <>
                                  <Checkbox
                                    checked={
                                      formData[i] !== undefined && formData[i]
                                    }
                                    onChange={(ev) => handleCheckbox(ev, i, 1)}
                                    disabled={!(e.status === "Splitted")}
                                  />

                                  {e.flags && <FlagIcon fontSize="small" />}
                                </>
                              }
                            />
                          );
                        })}
                    </div>
                    <Button
                      disabled={!(checkboxCount[1] > 0)}
                      variant="contained"
                      onClick={handleRedirect}
                      fullWidth
                    >
                      Start
                    </Button>
                  </div>
                ) : (
                  "No Tasks Alloted"
                )}
              </TabPanel>
              <TabPanel value={tab} index={2}>
                {statsLoading ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <div>
                    <p>Pending Files Count</p>
                    {Object.keys(stats.current).map((e) => {
                      return (
                        <div className="w-full flex justify-between space-x-2">
                          <p>{e + ": "}</p>
                          <p> {stats.current[e]}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </TabPanel>
            </>
          )}
        </DialogContent>
        {/* <Button
          disabled={isWorkNotAllocated}
          variant="contained"
          startIcon={
            isWorkNotAllocated ? (
              <PlayCircleRoundedIcon />
            ) : (
              <PlayCircleRoundedIcon />
            )
          }
        >
          {isWorkNotAllocated ? "No Work Allocated" : "Start " + "Splitting"}
        </Button> */}
      </Dialog>
    </>
  );
};

export default ProjectData;
