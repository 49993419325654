import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
// import { useCookies } from "react-cookie";
// import {setLogin} from '../slice/authSlice'
// import axios from "axios";
export default function RequireAuth({ children }) {
  const authed = useSelector((state) => state.auth.loggedIn);
  // const [cookies, setCookie, removeCookie] = useCookies(["token","auth"]);
  // useEffect(() => {
  //   if (
  //     cookies.auth &&
  //     cookies.auth !== null &&
  //     cookies.auth.expiryD > new Date()
  //   ) {
  //     //if token is valid and auth cookie is present
  //     setLogin(cookies.auth);
  //   } else if (cookies.auth && cookies.auth !== null) {
  //     //if token is expired and auth cookie is present , refresh token
  //     axios
  //       .post("api/refresh-token/", {
  //         username: "pdf",
  //       })
  //       .then((r) => {
  //         // console.log('regenerated')
  //         // addRefreshTimeout(r.data.token)
  //         // store.dispatch(updateToken(r.data.token))
  //         //set new expiryD for token
  //         var now = new Date();
  //         var time = now.getTime();
  //         var expireTime = time + 3600000 * 24 * 14;
  //         now.setTime(expireTime);
  //         var tokenExpiryDate = new Date;
  //         var expireToken = time + 2700000
  //         tokenExpiryDate.setTime(expireToken)
  //         setCookie("auth", {...cookies.auth, expiryD: now}, { expires:now  });
  //         //set new token
  //         setCookie("token", r.data.token, {
  //           httpOnly: true,
  //           expires: tokenExpiryDate,
  //         });
  //         setLogin({...cookies.auth, expiryD: now})
  //       });
  //   }
  // }, []);

  return authed === true ? children : <Navigate to="/login" replace />;
}
