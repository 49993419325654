import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { darken, lighten } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import { AllotFiles } from "../FileAllocation/AllotFiles";
import { DeleteOutlineRounded } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockIcon from "@mui/icons-material/Lock";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [open, setOpen] = useState(false);
  const [delId, setDelId] = useState(null);
  const [updateState, setUpdateState] = useState(0);
  const [locking, setLocking] = useState([]);
  const lockProject = (shouldLock, id) => {
    axios
      .patch("api/flag/?id=" + id, {
        id: id,
        major_issue: shouldLock,
      })
      .then((response) => {
        var lock_stats = [...locking];
        lock_stats[id] = !lock_stats[id];

        setLocking(lock_stats);
        setUpdateState(updateState + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const deleteFile = (id) => {
    //console.log("here")
    if (Number.isInteger(id)) {
      axios
        .delete("api/get-s3-objects/", {
          data: {
            file_id: id,
          },
        })
        .then((response) => {
          //console.log(response)
          setUpdateState(updateState + 1);
          props.setSnackDuration(5000);
          props.setSnackbarData("Deleted File");
          props.setSnackType("info");
          props.setSnackbar(true);
        })
        .catch((err) => {
          props.setSnackDuration(5000);
          props.setSnackbarData(
            "Could not delete file due to an internal error"
          );

          props.setSnackType("error");
        });
    } else {
      props.setSnackDuration(3000);
      props.setSnackbarData("File does not exist");
      props.setSnackType("info");
      props.setSnackbar(true);
    }
  };
  const columns = [
    {
      field: "og_name",
      headerName: "File Name",
      width: 300,
    },

    {
      field: "category",
      headerName: "Category",
      width: 100,
    },
    {
      field: "file_path",
      headerName: "Path",
      width: 400,
    },
    {
      field: "created_at",
      headerName: "Date Flagged",
      valueGetter: ({ value }) => value && new Date(value),
      width: 250,
    },
    {
      field: "origin",
      headerName: "Origin",
      width: 100,
    },
    {
      field: "user",
      headerName: "Flagged By",
      width: 100,
    },
    {
      field: "description",
      headerName: "Flag Desc",
      width: 200,
      sortable: false,
    },
    {
      field: "page_number",
      headerName: "Page(s)",
      width: 100,
      sortable: false,
    },

    {
      field: "major_issue",
      headerName: "Lock File",
      width: 75,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            color={params.row.is_locked ? "primary" : undefined}
            onClick={() => {
              var lock_stat = locking;
              lock_stat[params.row.id] = true;
              setLocking(lock_stat);
              lockProject(!params.row.major_issue, params.row.id);
            }}
            disabled={authState.privilege < 1}
          >
            {
              <Box sx={{ m: 1, position: "relative" }}>
                {params.row.major_issue ? (
                  <LockIcon />
                ) : (
                  <LockOpenRoundedIcon />
                )}
                {locking[params.row.id] && (
                  <CircularProgress
                    size={50}
                    sx={{
                      position: "absolute",
                      top: -6,
                      left: -9,
                      zIndex: 1,
                    }}
                  />
                )}
              </Box>
            }
            {}
          </IconButton>
        );
      },
    },
    {
      field: "Delete",
      headerName: "Delete File",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              setDelId(params.row.file);
              setOpen(true);
            }}
            disabled={authState.privilege < 1 || !params.row.deletable}
          >
            <DeleteOutlineRounded index={params.row.table_id} />
          </IconButton>
        );
      },
    },
  ];

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [allotState, setAllotState] = useState(false);
  const authState = useSelector((state) => state.auth);
  const [configRecord, setConfigRecord] = useState(null);

  useEffect(() => {
    axios
      .get("api/flag/?id=" + props.project_id)
      .then((response) => {
        response.data.forEach((e) => {
          e.page_number = e.page_number.join(",");
        });
        setTableData(response.data);
        setLoading(false);
      })
      .catch((e) => alert(e));
  }, [props.project_id, updateState]);
  const handleConfig = (id) => {
    const record = tableData[id];
    console.log(record);
    setConfigRecord(record);
    setAllotState(true);
  };
  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        "& .super-app-theme--Fup": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.light, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.light,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Npp": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Rap": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.dark, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.warning.dark,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Fap": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--Ip": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.info.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.info.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--C": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.success.main,
                theme.palette.mode
              ),
          },
        },
        "& .super-app-theme--L": {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
          "&:hover": {
            bgcolor: (theme) =>
              getHoverBackgroundColor(
                theme.palette.error.main,
                theme.palette.mode
              ),
          },
        },
      }}
    >
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete this File ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the file all allocation and data
            related to this file will be deleted ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            CANCEL
          </Button>
          <Button
            onClick={() => {
              deleteFile(delId);
              setOpen(false);
              setDelId(null);
            }}
            autoFocus
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "created_at", sort: "desc" }],
          },
        }}
        rowsPerPageOptions={[3, 5]}
        disableSelectionOnClick={true}
        rows={tableData}
        loading={loading}
        columns={columns}
        pageSize={5}
        getRowClassName={(params) => {
          var colorclass = "";
          if (params.row.major_issue) {
            colorclass = "L";
          } else {
            colorclass = "Ip";
          }

          return `super-app-theme--${colorclass}`;
        }}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
        }}
        onSelectionModelChange={(itm) => console.log(itm)}
      />
    </Box>
  );
};
