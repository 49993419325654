import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@mui/material";
import { PDFDocument } from "pdf-lib";

const Dropzone = (props) => {
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);

      reader.readAsArrayBuffer(file);
    });
  };

  const getNumPages = async (file) => {
    const arrayBuffer = await readFile(file);

    const pdf = await PDFDocument.load(arrayBuffer);

    return pdf.getPageCount();
  };

  console.log(props.globalData);
  const index = props.index;
  const vis = props.visibility;
  let visibility = "hidden";
  if (vis) {
    visibility = "";
  }

  const removeFile = async (file) => {
    console.log(props.globalData);
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);

    const curglobal = [...props.globalData];
    const data = await getAllFileLengths(acceptedFiles);
    curglobal[props.index] = data;

    props.setSubmitData(curglobal);
  };

  const getAllFileLengths = async (files) => {
    let data = await Promise.all(
      files.map(async (file) => {
        const numPages = await getNumPages(file);

        return { file: file, numPages: numPages };
      })
    );
    return data;
  };
  const onDrop = useCallback(
    async (acceptedFiles) => {
      console.log(acceptedFiles);

      // do nothing if no files
      if (acceptedFiles.length === 0) {
        return;
      } else if (acceptedFiles.length > 51) {
        props.setSnackbarData("Please upload upto 50 files");
        props.setSnackbar(true);
        props.setSnackType("error");
      } else {
        var copy = [...acceptedFiles];
        props.setLoader(true);
        copy = await getAllFileLengths(copy);
        console.log("got the size");
        props.setLoader(false);
        const curglobal = [...props.globalData];
        curglobal[props.index] = copy;
        props.setSubmitData(curglobal);
      }
    },
    [props.globalData]
  );
  const { isDragActive, getRootProps, getInputProps, acceptedFiles, open } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
      },
      noClick: true,
    });

  const files = acceptedFiles.map((file, index) => (
    <li key={file.path}>
      {file.path} -{" "}
      {file.size / 1000 / 1000 < 1
        ? file.size / 1000 + " kB "
        : Math.round((file.size / 1000 / 1000) * 100) / 100 + " MB "}
      <Button
        variant="text"
        onClick={async () => {
          await removeFile(file);
        }}
      >
        Remove File
      </Button>
    </li>
  ));

  return (
    // dropzone text-center text-white border-4 border-dashed",
    <div
      {...getRootProps({
        className:
          "dropzone text-center text-white border-sky-400 border-2 p-2 border-dashed rounded-md " +
          visibility,
        // onClick: (event) => event.stopPropagation(),
      })}
    >
      <input className="input-zone" {...getInputProps()} />
      <div className="text-center">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <div className="dropzone-content">
            {" "}
            <p>Drag’n’drop some files here, or click to select files </p>
          </div>
        )}
        <Button variant="text" type="button" onClick={open} className="btn">
          Click to select files
        </Button>
      </div>
      <aside>
        <ul>{files}</ul>
      </aside>
    </div>
  );
};
export default Dropzone;
