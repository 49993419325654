import { createSlice } from "@reduxjs/toolkit";

export const timeSlice = createSlice({
  name: "time",
  initialState: {
    timeobj: null,
  },
  reducers: {
    setTime: (state, action) => {
      state.timeobj = action.payload.timeobj;
    },
  },
});

export const { setTime } = timeSlice.actions;
export default timeSlice.reducer;
