import React from "react";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import logo_s from "../../assets/logo-s.png";

export default () => {
    let navigate = useNavigate();
  return <div className="w-full h-full flex flex-col items-center mt-20 space-y-2">
    <img className="mx-auto w-20" src={logo_s} alt="Workflow" />
    <div className="flex items-center space-x-2">
    <AccessTimeIcon fontSize="large" color="action"/>
    <Typography fontSize={48} variant="h1" component="h1" color="white">Session Expired</Typography></div>
    <Typography fontSize={18} color="gray" >A session only lasts 4 hours.</Typography>
    <br></br>
    <Button variant="contained"  onClick={()=>{
        navigate("/login", {
            replace: true,
          });
    }}>Login</Button>
  </div>;
};
