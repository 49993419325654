import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Chip from "@mui/material/Chip";
import { Dialog, MenuItem } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Dropzone from "./DropZone";
import { DialogContent } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useCancelToken } from "../../../hooks/useCancel";
// eslint-disable-next-line import/no-anonymous-default-export
const Upload = (props) => {
  const { newCancelToken, isCancel } = useCancelToken();
  const [submitData, setSubmitData] = useState([]);
  const [rootDir, setRootDir] = useState(null);
  const authState = useSelector((state) => state.auth);
  const [curcat, setCurcat] = useState("");
  const [uploadStatus, setUpload] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [vis, setVis] = useState([]);
  const [catList, setCatList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [calcLoader, setCalcLoader] = useState(false);

  useEffect(() => {
    console.log("in use effect upload");
    axios
      .get("api/root-category/", {
        params: { project_id: props.projectData.id },
        cancelToken: newCancelToken(),
      })
      .then((response) => {
        setLoading(false);
        let cats = [];
        cats = response.data.map((catobj) => {
          return catobj.category;
        });

        setCatList(cats);
        setSubmitData(new Array(cats.length));
        setVis(new Array(cats.length).fill("false"));
      })
      .catch((error) => {
        if (isCancel(error)) {
          console.log("Cancelled  ");
          return;
        }
        console.log(error);
      });
  }, [newCancelToken, isCancel, props]);
  useEffect(() => {});
  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& .MuiLinearProgress-bar": {
              transition: "none",
            },
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

  const handleChange = (event) => {
    const visible = catList.indexOf(event.target.value);
    var curvis = [...vis];
    curvis = curvis.map((visibility, i) => {
      if (i === visible) {
        return "true";
      } else {
        return "false";
      }
    });

    setCurcat(event.target.value);
    console.log(curvis);
    setVis(curvis);
  };

  // console.log(props.projectData);
  // const submitAllt=()=>
  const submitResources = () => {
    setSubmitting(true);

    const processData = [...submitData];

    var api_response_array = processData.map((files, catindex) => {
      const dir_name = catList[catindex];
      if (files && dir_name == curcat) {
        return { name: dir_name, files: files };
      } else {
        return;
      }
      // const obj
    });
    api_response_array = api_response_array.flat();
    var count = 0;

    api_response_array.forEach((obj) => {
      if (obj) {
        if (obj.files.length == 0) {
          count += 1;
        }
      } else {
        count += 1;
      }
    });
    console.log(api_response_array);
    var bodyFormData = new Array(catList.length).fill(null);

    for (let j = 0; j < api_response_array.length; j++) {
      if (
        api_response_array[j] != undefined &&
        api_response_array[j].files.length > 0
      ) {
        bodyFormData[j] = new FormData();
        const dir = "pdf/" + props.projectData.id + "/" + catList[j] + "/";
        bodyFormData[j].append("project_id", parseInt(props.projectData.id));
        bodyFormData[j].append("category", curcat);
        console.log(api_response_array[j]);
        var numpagearr = [];

        for (let i = 0; i < api_response_array[j].files.length; i++) {
          bodyFormData[j].append("file[]", api_response_array[j].files[i].file);
          numpagearr.push(api_response_array[j].files[i].numPages);
          bodyFormData[j].append(
            "num_pages",
            api_response_array[j].files[i].numPages
          );
        }
      }
    }
    // for (let j = 0; j < catList.length; j++) {
    //   for (var pair of bodyFormData[j].entries()) {
    //     console.log(pair[0] + ", " + pair[1]);
    //   }
    // }

    bodyFormData.map((data, index) => {
      const loc = catList.indexOf(curcat);
      //TODO Dispatch this data for background info
      if (bodyFormData[index]) {
        let newUp = { ...uploadStatus };
        // newUp[loc] = 0;
        // setUpload(newUp);

        axios
          .post("api/put-s3-object/", bodyFormData[index], {
            cancelToken: newCancelToken(),
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.lengthComputable
                ? progressEvent.total
                : progressEvent.target.getResponseHeader("content-length") ||
                  progressEvent.target.getResponseHeader(
                    "x-decompressed-content-length"
                  );

              newUp[loc] = Math.round(
                (progressEvent.loaded * 100) / totalLength
              );
              //TODO Dispatch this action
              setUpload(newUp[loc]);
            },
          })
          .then((response) => {
            // console.log(response);
            // newUp[loc]=100

            props.update(props.projectData.id, props.projectData.table_id);
            props.setSnackbarData("File Submitted Successfully");
            props.setSnackbar(true);
            props.setSnackType("success");
            var newUp = uploadStatus;

            setUpload(newUp);
            var cur = new Array(vis.length).fill("false");

            cur[catList.indexOf(curcat)] = true;

            setVis(cur);
          })
          .catch((error) => {
            if (isCancel(error)) return;
            delete newUp[loc];
            //TODO Dispatch this action
            setUpload("");
            var cur = new Array(vis.length).fill("false");
            cur[catList.indexOf(curcat)] = true;

            setVis(cur);
            if (error.status == 400) {
              props.setSnackbarData("Contact Server Admin (Improper Request)");
              props.setSnackbar(true);
              props.setSnackType("error");
            } else if (
              error.status == 500 ||
              error.status == 502 ||
              error.status == 504
            ) {
              props.setSnackbarData(
                "Server Side Error please contact server incharge"
              );
              props.setSnackbar(true);
              props.setSnackType("error");
            }
          });
      }
    });
    //TODO Dispatch this action
    setSubmitting(false);
    console.log(isSubmitting);
  };

  const setGlobalData = (data) => {
    // console.log(data);

    setSubmitData(data);
  };
  return (
    <>
      <Dialog
        open={props.open}
        onClose={(e, reason) => {
          console.log(reason);
          if (reason !== "backdropClick") {
            setSubmitData([]);
            props.handleClose();
          }
        }}
        PaperProps={{
          style: {
            backgroundColor: "#0F172A",
          },
        }}
      >
        <DialogTitle>
          Upload Files
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                props.handleClose();
                setSubmitData([]);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!isLoading ? (
            <>
              <FormControl sx={{ minWidth: 130, m: 2 }}>
                <InputLabel id="demo-simple-select-label">Category</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={curcat}
                  label="SelectedCat"
                  onChange={handleChange}
                >
                  {catList.map((e, i) => {
                    return (
                      <MenuItem index={i} value={e}>
                        {e}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {catList.map((e, i) => {
                if (e === curcat) {
                  return uploadStatus === "" || uploadStatus === undefined ? (
                    <>
                      <Dropzone
                        setLoader={setCalcLoader}
                        index={i}
                        globalData={submitData}
                        setSubmitData={setGlobalData}
                        multiple
                        visibility={vis[i]}
                        setSnackbarData={props.setSnackbarData}
                        setSnackType={props.setSnackType}
                        setSnackbar={props.setSnackbar}
                      />
                    </>
                  ) : (
                    <>
                      <CircularProgressWithLabel
                        variant="determinate"
                        value={uploadStatus}
                        visibility={vis[i]}
                      />
                    </>
                  );
                }
              })}
            </>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress value={uploadStatus} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>

          {!isSubmitting && !calcLoader && (
            <Button onClick={submitResources}>Submit Files</Button>
          )}
          {calcLoader && <CircularProgress />}
          {/* <Button onClick={}>Submit</Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export const UploadDialog = React.memo(Upload);
