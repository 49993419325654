import {
  Autocomplete,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from "@mui/icons-material/Folder";
import { DocumentIcon } from "@heroicons/react/solid";
import { styled } from "@mui/material/styles";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const Demo = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
  }));

  const [userList, setUserList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [user, setUser] = useState(null);
  const [project, setProject] = useState(null);
  const [tovalue, setToValue] = useState(null);
  const [fromvalue, setFromValue] = useState(null);
  const [fromError, setFromError] = useState(null);
  const [toError, setToError] = useState(null);
  const [fromErrorDate, setFromErrorDate] = useState(false);
  const [toErrorDate, setToErrorDate] = useState(false);
  const [result, setResult] = useState({ vis: false, data: null });
  const authState = useSelector((state) => state.auth);
  console.log("Auth", authState);
  const fetchUsers = (project) => {
    axios
      .get("api/project/" + project.id)
      .then((res) => {
        setUserList(
          res.data.user_permissions.map((obj) => {
            return { label: obj.full_name.toUpperCase(), ...obj };
          })
        );
        setUser(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [userValidator, setUserValidator] = useState({
    error: false,
    message: "",
  });
  const [projectValidator, setProjectValidator] = useState({
    error: false,
    message: "",
  });
  const [computing, setComputing] = useState(false);

  const formUserValidations = () => {
    if (fromvalue === null) {
      setFromError("Please select a starting date");
      setFromErrorDate(true);

      return false;
    }
    if (tovalue === null) {
      setToError("Please select an ending date");
      setToErrorDate(true);
      return false;
    }

    if (project == null) {
      setProjectValidator({ error: true, message: "Please select a project" });
      return false;
    }
    return true;
  };
  const formValidations = () => {
    console.log(user, project, fromvalue, tovalue);
    if (fromvalue === null) {
      setFromError("Please select a starting date");
      setFromErrorDate(true);

      return false;
    }
    if (tovalue === null) {
      setToError("Please select an ending date");
      setToErrorDate(true);
      return false;
    }
    if (user == null) {
      setUserValidator({ error: true, message: "Please select a user" });
      return false;
    }
    if (project == null) {
      setProjectValidator({ error: true, message: "Please select a project" });
      return false;
    }
    return true;
  };
  const handleUserSubmit = () => {
    if (formUserValidations()) {
      const f = new Date(fromvalue.$d);
      const t = new Date(tovalue.$d);
      var from_date = f.toISOString().slice(0, 10);
      var to_date = t.toISOString().slice(0, 10);
      setResult({ vis: false, data: null });
      setComputing(true);
      axios
        .get("api/user-project-report/", {
          params: {
            user: authState.name,
            project: project.id,
            from_date,
            to_date,
          },
        })
        .then((res) => {
          console.log(res.data);
          setResult({ vis: true, data: res.data });
          setComputing(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSubmit = () => {
    if (formValidations()) {
      const f = new Date(fromvalue.$d);
      const t = new Date(tovalue.$d);
      var from_date = f.toISOString().slice(0, 10);
      var to_date = t.toISOString().slice(0, 10);
      setResult({ vis: false, data: null });
      setComputing(true);
      axios
        .get("api/user-project-report/", {
          params: {
            user: user.username,
            project: project.id,
            from_date,
            to_date,
          },
        })
        .then((res) => {
          console.log(res.data);
          setResult({ vis: true, data: res.data });
          setComputing(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (authState.privilege === 1) {
      axios
        .get("api/user-access-control")
        .then((res) => {
          console.log(res.data);
          setUserList(
            res.data.map((obj) => {
              return { label: obj.full_name.toUpperCase(), ...obj };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
      axios
        .get("api/sales-project-names/")
        .then((res) => {
          console.log(res.data);
          setProjectList(
            res.data.map((obj) => {
              return { label: obj.name.toUpperCase(), ...obj };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get("api/user-project-list/", { params: { username: authState.name } })
        .then((res) => {
          setProjectList(
            res.data.project.map((obj) => {
              return { label: obj.name.toUpperCase(), ...obj };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  return (
    <div className="w-full">
      <div className="flex flex-col  ">
        <p className=" p-5 text-4xl text-slate-300 tracking-widest font-bold font-sans">
          {authState.privilege === 1 ? "User data" : "Your work"}
        </p>
        <div className="border-t w-80 border-slate-500 h-1"></div>
        {authState.privilege !== 1 ? (
          <div className="p-5   flex justify-evenly  bg-slate-700">
            <Autocomplete
              disablePortal
              value={project}
              id="combo-box-demo"
              options={projectList}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue) {
                  fetchUsers(newValue);
                }

                setResult({ vis: false, data: null });
                setProjectValidator({ error: false, message: "" });
                setProject(newValue);
                setUser(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={projectValidator.error}
                  helperText={projectValidator.message}
                  label="Project Name"
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                value={fromvalue}
                inputFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                mask={"____-__-__"}
                onChange={(newValue) => {
                  setResult({ vis: false, data: null });
                  setFromError(null);
                  setFromErrorDate(false);
                  setFromValue(newValue);
                }}
                onError={(reason, value) => {
                  if (reason) {
                    // reason is the error , which it will be displayed as
                    // default error message ,you can also  pass your own error
                    // message depending on the error
                    console.log(reason);
                    setFromError(reason);
                    setFromErrorDate(true);
                  } else {
                    setFromError(null);
                    setFromErrorDate(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    helperText={fromError}
                    error={fromErrorDate}
                    {...params}
                  />
                )}
                autoFocus={true}
              />
              <DatePicker
                label="To"
                value={tovalue}
                onError={(reason, value) => {
                  if (reason) {
                    console.log(reason);
                    setToError(reason);
                    setToErrorDate(true);
                  } else {
                    setToError(null);
                    setToErrorDate(false);
                  }
                }}
                mask={"____-__-__"}
                inputFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                minDate={fromvalue}
                onChange={(newValue) => {
                  setResult({ vis: false, data: null });
                  setToValue(newValue);
                  setToError(null);
                  setToErrorDate(false);
                }}
                renderInput={(params) => (
                  <TextField
                    error={toErrorDate}
                    helperText={toError}
                    {...params}
                  />
                )}
                autoFocus={true}
              />
            </LocalizationProvider>

            <Button variant="contained" onClick={handleUserSubmit}>
              Search
            </Button>
          </div>
        ) : (
          <div className="p-5   flex justify-evenly  bg-slate-700">
            <Autocomplete
              disablePortal
              value={project}
              id="combo-box-demo"
              options={projectList}
              sx={{ width: 300 }}
              onChange={(event, newValue) => {
                if (newValue) {
                  fetchUsers(newValue);
                }

                setResult({ vis: false, data: null });
                setProjectValidator({ error: false, message: "" });
                setProject(newValue);
                setUser(null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={projectValidator.error}
                  helperText={projectValidator.message}
                  label="Project Name"
                />
              )}
            />
            <Autocomplete
              value={user}
              disablePortal
              id="Resource Name"
              options={userList}
              onChange={(event, newValue) => {
                setResult({ vis: false, data: null });
                setUserValidator({ error: false, message: "" });
                setUser(newValue);
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  error={userValidator.error}
                  helperText={userValidator.message}
                  {...params}
                  label="Resource Name"
                />
              )}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                value={fromvalue}
                inputFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                mask={"____-__-__"}
                onChange={(newValue) => {
                  setResult({ vis: false, data: null });
                  setFromError(null);
                  setFromErrorDate(false);
                  setFromValue(newValue);
                }}
                onError={(reason, value) => {
                  if (reason) {
                    // reason is the error , which it will be displayed as
                    // default error message ,you can also  pass your own error
                    // message depending on the error
                    console.log(reason);
                    setFromError(reason);
                    setFromErrorDate(true);
                  } else {
                    setFromError(null);
                    setFromErrorDate(false);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    helperText={fromError}
                    error={fromErrorDate}
                    {...params}
                  />
                )}
                autoFocus={true}
              />
              <DatePicker
                label="To"
                value={tovalue}
                onError={(reason, value) => {
                  if (reason) {
                    console.log(reason);
                    setToError(reason);
                    setToErrorDate(true);
                  } else {
                    setToError(null);
                    setToErrorDate(false);
                  }
                }}
                mask={"____-__-__"}
                inputFormat="YYYY-MM-DD"
                format="YYYY-MM-DD"
                minDate={fromvalue}
                onChange={(newValue) => {
                  setResult({ vis: false, data: null });
                  setToValue(newValue);
                  setToError(null);
                  setToErrorDate(false);
                }}
                renderInput={(params) => (
                  <TextField
                    error={toErrorDate}
                    helperText={toError}
                    {...params}
                  />
                )}
                autoFocus={true}
              />
            </LocalizationProvider>

            <Button variant="contained" onClick={handleSubmit}>
              Search
            </Button>
          </div>
        )}
        {result.vis ? (
          result.data.naming > 0 || result.data.splitting > 0 ? (
            <div className="p-10 flex  border-2 border-black items-start justify-between ">
              <div className="h-fit">
                <p className=" mt-10 text-4xl text-slate-300 tracking-widest font-bold font-sans">
                  Results
                </p>
                <div className="border-t w-80 border-slate-500 h-1"></div>
                <p className=" mt-20 "></p>
                <span className=" mt-20 text-2xl text-slate-300 tracking-widest font-bold font-sans">
                  Naming pages:
                </span>

                <span className=" text-3xl text-slate-100 font-bold font-sans">
                  {" " + result.data.naming}
                </span>
                <p className=" mt-20 "></p>

                <span className=" mt-20 text-2xl text-slate-300 tracking-widest font-bold font-sans">
                  Splitting pages:
                </span>
                <span className=" text-3xl text-slate-100 font-bold font-sans">
                  {" " + result.data.splitting}
                </span>
              </div>
              <div className="grid grid-cols-2 gap-0 grid-rows-2 border-2 border-black">
                <Demo className=" max-h-80 overflow-y-auto border-2">
                  <p className=" ml-1 mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans sticky">
                    Completed Naming Files
                  </p>
                  <div>
                    <List dense={false}>
                      {result.data.files_named.length === 0 ? (
                        <p className=" ml-1 sticky mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans ">
                          No files named in this period
                        </p>
                      ) : (
                        result.data.files_named.map((obj) => {
                          return (
                            <ListItem className="mt-2 mb-2 bg-slate-700 rounded-lg">
                              <ListItemIcon>
                                <DocumentIcon />
                              </ListItemIcon>
                              <ListItemText primary={obj} />
                            </ListItem>
                          );
                        })
                      )}
                    </List>
                  </div>
                </Demo>
                <Demo className=" ml-1 max-h-80 overflow-y-auto border-2">
                  <p className="ml-1 sticky mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans ">
                    Completed Splitting Files
                  </p>
                  <div>
                    <List dense={false}>
                      {result.data.files_split.length === 0 ? (
                        <p className="sticky mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans ">
                          No files splitted in this period
                        </p>
                      ) : (
                        result.data.files_split.map((obj) => {
                          return (
                            <ListItem className="mt-2 mb-2 bg-slate-700 rounded-lg">
                              <ListItemIcon>
                                <DocumentIcon />
                              </ListItemIcon>
                              <ListItemText primary={obj} />
                            </ListItem>
                          );
                        })
                      )}
                    </List>
                  </div>
                </Demo>
                <Demo className="ml-1  max-h-80 overflow-y-auto border-2">
                  <p className="ml-1 sticky mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans ">
                    Pending Naming Files
                  </p>
                  <div>
                    <List dense={false}>
                      {result.data.naming_pending.length === 0 ? (
                        <p className="sticky mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans ">
                          No pending naming files
                        </p>
                      ) : (
                        result.data.naming_pending.map((obj) => {
                          return (
                            <ListItem className="mt-2 mb-2 bg-slate-700 rounded-lg">
                              <ListItemIcon>
                                <DocumentIcon />
                              </ListItemIcon>
                              <ListItemText primary={obj} />
                            </ListItem>
                          );
                        })
                      )}
                    </List>
                  </div>
                </Demo>
                <Demo className="ml-1 max-h-80 overflow-y-auto border-2">
                  <p className="sticky mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans ">
                    Pending Splitting Files
                  </p>
                  <div>
                    <List dense={false}>
                      {result.data.splitting_pending.length === 0 ? (
                        <p className="sticky mt-10 text-2xl text-slate-300 tracking-widest font-bold font-sans ">
                          No pending splitting files
                        </p>
                      ) : (
                        result.data.splitting_pending.map((obj) => {
                          return (
                            <ListItem className="mt-2 mb-2 bg-slate-700 rounded-lg">
                              <ListItemIcon>
                                <DocumentIcon />
                              </ListItemIcon>
                              <ListItemText primary={obj} />
                            </ListItem>
                          );
                        })
                      )}
                    </List>
                  </div>
                </Demo>
              </div>
            </div>
          ) : (
            <div className="self-center mt-20 text-4xl text-slate-300 tracking-widest font-bold font-sans">
              {authState.privilege === 1
                ? "No files were splitted or named in this timeframe by this user"
                : "No files were splitted or named in this timeframe"}
            </div>
          )
        ) : null}
        {computing ? (
          <CircularProgress className="self-center mt-20 text-4xl text-slate-300 tracking-widest font-bold font-sans" />
        ) : null}
      </div>
    </div>
  );
};
