import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import LoadingDiv from "../PdfPreview/LoadingDiv";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";
import FlagDialog from "../../components/FlagDialog/FlagDialog";
import FlagIcon from "@mui/icons-material/Flag";
import { useSelector } from "react-redux";

export default function RenamePreview(props) {
  console.warn("Flags", props.flags);
  const [scale, setScale] = useState(1);
  const [isFlagOpen, setFlag] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const fileState = useSelector((s) => s.file);
  const onSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  return (
    <div
      className="bg-white w-full z-50 inset-0 overflow-y-scroll"
      style={{ height: "90vh" }}
    >
      <div className="w-fit h-fit bg-blue-500 text-slate-100 font-semibold text-center cursor-pointer  fixed z-50 opacity-75">
        <div className="hover:bg-blue-600 p-1" onClick={props.close}>
          <CloseRoundedIcon />
        </div>
        <div
          className="hover:bg-blue-600 p-1"
          onClick={() => setScale(scale + 0.25)}
        >
          <ZoomInRoundedIcon />
        </div>
        <div
          className="hover:bg-blue-600 p-1"
          onClick={() => setScale(scale - 0.25)}
        >
          <ZoomOutRoundedIcon />
        </div>
        {props.rename && (
          <>
            <div
              className="hover:bg-blue-600 p-1"
              onClick={() => setFlag(true)}
            >
              <FlagIcon />
            </div>

            <FlagDialog
              flagConfig={props.flagConfig}
              index={props.index}
              setFlag={props.flagSet}
              open={isFlagOpen}
              curTask={props.curTask}
              handleClose={() => setFlag(false)}
              project_id={fileState.project_id}
              task_id={fileState.task_id}
              rename
              next={props.next}
            />
          </>
        )}
      </div>
      <center>
        <Document
          file={props.url}
          className="w-full h-5/6 max-h-full"
          onLoadSuccess={onSuccess}
          loading={<LoadingDiv downloadProgress={downloadProgress} />}
          onLoadProgress={({ loaded, total }) => {
            // console.log(`called: ${loaded}/ ${total}`)
            if (Math.floor((loaded * 100) / total) > downloadProgress) {
              setDownloadProgress(Math.floor((loaded * 100) / total));
            }
          }}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <>
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                className="w-full"
                loading={<LoadingDiv />}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                scale={scale}
              />
              <p className="text-slate-100 bg-slate-600">{index + 1}</p>
              <div className="h-2 bg-slate-600 w-full"></div>
            </>
          ))}
        </Document>
      </center>
      {/* <embed className="w-full h-full" src={props.blob + "#toolbar=0"}></embed> */}
    </div>
  );
}
