import React, { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { setLogin } from "../../../slice/authSlice";
export default (props) => {
  const [pState, setPState] = useState({ old: "", n: "", r: "" });
  const handlePChange = (e, val) => {
    setPState({ ...pState, [val]: e.target.value });
  };
  const [alertText, setAlertText] = useState([0, ""]);
  const authState = useSelector((state) => state.auth);
  const changePassword = () => {
    if (pState.n === pState.r)
      axios
        .post(
          "api/change-password/",
          {
            old_password: pState.old,
            username: authState.name,
            new_password: pState.n,
          }
        )
        .then(function (response) {
          console.log(response);
          setAlertText([1, "Password Changed Successfully"]);
        })
        .catch(function (error) {
          alert(error);
        });
  };
  const changeUsername = () => {
    axios
      .post(
        "api/change-username/",
        {
          old_username: authState.name,
          new_username: uname,
        },
      )
      .then((r) => {
        setLogin({ ...authState, name: uname });
      });
  };
  const [uname, setUname] = useState(authState.name);
  return (
    <div className=" m-8 flex flex-col space-y-6 mt-16">
      <p className=" text-2xl text-slate-300 tracking-widest font-bold font-sans">
        PROFILE
      </p>
      <div className="border-t w-80 border-slate-500 h-1"></div>
      <div className="w-96 flex-row flex justify-between">
        <p className="text-slate-400 tracking-widest text-md inline">
          USERNAME :{" "}
        </p>
        <input
          className="rounded bg-slate-600 text-slate-300 w-40 text-sm h-8 p-2 tracking-widest"
          value={uname}
          disabled
          // onChange={(e) => setUname(e.target.value)}
        ></input>
      </div>

      <div className="w-96 text-center">
        {/* <div
          className="bg-sky-700 mt-2 w-32 mx-auto px-4 py-2 tracking-widest rounded-md text-center text-md font-semibold text-slate-100 cursor-pointer hover:bg-sky-800"
          onClick={changeUsername}
        >
          CHANGE USERNAME
        </div> */}
      </div>
      <div className="pt-2">
        <p className="text-slate-300 tracking-widest font-semibold text-lg inline">
          CHANGE PASSWORD
        </p>
        <div className="border-t w-56 border-slate-500 h-1 mt-2"></div>
      </div>
      <div className="w-96 flex-row flex justify-between">
        <p className="text-slate-400 tracking-widest text-md inline">
          OLD PASSWORD :{" "}
        </p>
        <input
          className="rounded bg-slate-600 text-slate-300 w-40 text-sm h-8 p-2 tracking-widest "
          value={pState.old}
          type="password"
          onChange={(e) => handlePChange(e, "old")}
        ></input>
      </div>
      <div className="w-96 flex-row flex justify-between">
        <p className="text-slate-400 tracking-widest text-md inline">
          NEW PASSWORD :{" "}
        </p>
        <input
          className="rounded bg-slate-600 text-slate-300 w-40 text-sm h-8 p-2 tracking-widest "
          value={pState.n}
          type="password"
          onChange={(e) => handlePChange(e, "n")}
        ></input>
      </div>
      <div className="w-96 flex-row flex justify-between">
        <p className="text-slate-400 tracking-widest text-md inline">
          CONFIRM PASSWORD :{" "}
        </p>
        <input
          className="rounded bg-slate-600 text-slate-300 w-40 text-sm h-8 p-2 tracking-widest "
          type="password"
          value={pState.r}
          onChange={(e) => handlePChange(e, "r")}
        ></input>
      </div>
      <div className="w-96 text-center">
        <p
          className={
            "text-sm italic  mx-auto w-96 " +
            (alertText[0] === 1 ? "text-green-500" : "text-rose-400")
          }
        >
          {alertText[1]}
        </p>
        <div
          className="bg-sky-700 mt-2 w-32 mx-auto px-4 py-2 tracking-widest rounded-md text-center text-md font-semibold text-slate-100 cursor-pointer hover:bg-sky-800"
          onClick={changePassword}
        >
          CHANGE PASSWORD
        </div>
      </div>
    </div>
  );
};
