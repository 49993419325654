import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridToolbarContainer } from "@mui/x-data-grid";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockIcon from "@mui/icons-material/Lock";
import { GridPagination } from "@mui/x-data-grid";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useSelector } from "react-redux";
import HelperDialog from "../../HelperDialog/HelperDialog";
function CustomFooterStatusComponent(props) {
  return (
    <Box sx={{ p: 1 }}>
      {/* <Button startIcon={<LockClosedIcon />}>Lock users</Button> */}
      <GridPagination />
    </Box>
  );
}
function CustomToolbar(props) {
  return (
    <GridToolbarContainer>
      <Typography variant="h5" className="text-slate-300 pl-3 pt-2 font-bold">
        {props.title}
      </Typography>
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}
export { CustomFooterStatusComponent };
function DisplayTable(props) {
  console.log("Here");
  const authState = useSelector((s) => s.auth);
  const custcolumns = [
    { field: "name", headerName: "Name", width: 250 },
    // { field: "address", headerName: "Cust", width: 300 },
    { field: "mobile_number", headerName: "Contact Number", width: 250 },
    // { field: "email", headerName: "Email", width: 250 },
    {
      field: "edit_user",
      headerName: "Edit User",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="allocate_users"
            onClick={() => props.customerEdit(params.row)}
          >
            <EditRoundedIcon />
          </IconButton>
        );
      },
    },
    {
      field: "delete_user",
      headerName: "Delete User",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="allocate_users"
            onClick={() => deleteCustomer(params.row.name)}
          >
            <DeleteRoundedIcon />
          </IconButton>
        );
      },
    },
  ];

  const deleteCustomer = (name) => {
    setDialogOpen(true);
    dialogProp.current = {
      okText: "Delete",
      title: "Delete User?",
      desc: "Are you sure you want to delete user: " + name,
      handleClose: () => {
        setDialogOpen(false);
      },
      onSubmit: () => {
        setDialogOpen(false);
        axios
          .delete("api/customer-list/", {
            data: {
              name: name,
            },
          })
          .then((r) => {
            props.updateUsers();
            props.setSnackbarData("Deleted Customer");
            props.setSnackType("info");
            props.setSnackbar(true);
          })
          .catch((e) => alert(e));
      },
    };
  };

  const deleteUser = (name) => {
    setDialogOpen(true);
    dialogProp.current = {
      okText: "Delete",
      title: "Delete User?",
      desc: "Are you sure you want to delete user: " + name,
      handleClose: () => {
        setDialogOpen(false);
      },
      onSubmit: () => {
        setDialogOpen(false);
        axios
          .delete("api/profile/", {
            data: {
              username: name,
            },
          })
          .then((r) => {
            props.updateUsers();
            props.setSnackbarData("Deleted User");
            props.setSnackType("info");
            props.setSnackbar(true);
          })
          .catch((e) => alert(e));
      },
    };
  };

  const channelcolumns = [
    { field: "full_name", headerName: "Name", width: 250 },
    // { field: "address", headerName: "Cust", width: 300 },
    { field: "mobile_number", headerName: "Contact Number", width: 250 },
    // { field: "email", headerName: "Email", width: 250 },
    {
      field: "edit_user",
      headerName: "Edit User",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="allocate_users"
            onClick={() =>
              props.handleEdit(
                1,
                params.row.username,
                params.row.banking_details
              )
            }
          >
            <EditRoundedIcon />
          </IconButton>
        );
      },
    },
    {
      field: "delete_user",
      headerName: "Delete User",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="allocate_users"
            onClick={() => deleteUser(params.row.username)}
          >
            <DeleteRoundedIcon />
          </IconButton>
        );
      },
    },
  ];
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogProp = useRef({});
  const columns = [
    { field: "username", headerName: "Username" },
    { field: "full_name", headerName: "Name", width: 300 },
    {
      field: "lastLogin",
      headerName: "Last Login",
      width: 300,
      renderCell: (params) => {
        console.log("params", params);
        params.row.lastLogin = params.row.lastLogin
          ? new Date(params.row.lastLogin)
          : null;

        return params.row.lastLogin
          ? params.row.lastLogin.getDate() +
              "/" +
              (Number(params.row.lastLogin.getMonth()) + 1).toString() +
              "/" +
              params.row.lastLogin.getFullYear() +
              " at " +
              params.row.lastLogin.getHours() +
              ":" +
              params.row.lastLogin.getMinutes() +
              ":" +
              params.row.lastLogin.getSeconds()
          : null;
      },
    },
    // { field: "dateCreated", headerName: "Date Created", width: 300 },
    {
      field: "locked",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        return params.row.locked ? "Locked" : "Not Locked";
      },
    },
    {
      field: "options",
      headerName: "Options",
      width: 100,
      renderCell: (params) => {
        return (
          <IconButton
            color={params.row.locked ? "primary" : undefined}
            onClick={() => {
              props.lockUser(params.row, !params.row.locked);
            }}
          >
            {params.row.locked ? <LockIcon /> : <LockOpenRoundedIcon />}
          </IconButton>
        );
      },
    },
    {
      field: "edit_user",
      headerName: "Edit User",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="allocate_users"
            onClick={() =>
              props.handleEdit(
                0,
                params.row.username,
                params.row.banking_details
              )
            }
          >
            <EditRoundedIcon />
          </IconButton>
        );
      },
    },
    {
      field: "delete_user",
      headerName: "Delete User",
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <IconButton
            aria-label="allocate_users"
            onClick={() => deleteUser(params.row.username)}
          >
            <DeleteRoundedIcon />
          </IconButton>
        );
      },
    },
  ];

  // const authState = useSelector((state) => state.auth);

  return (
    <div className="flex flex-col">
      <Box
        sx={{
          height: 350,
          width: "100%",
          mb: 1,
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      >
        <DataGrid
          rowsPerPageOptions={[3, 5]}
          rows={props.tableData}
          columns={columns}
          pageSize={5}
          loading={props.isUsersLoading}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
          }}
          componentsProps={{ toolbar: { title: "Resources" } }}
        />
      </Box>

      <div className="flex justify-between">
        <Box
          sx={{
            height: 300,
            width: "49%",
            mb: 1,
            boxShadow: 2,
            border: 2,
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
        >
          <DataGrid
            rowsPerPageOptions={[3, 5]}
            rows={props.custTableData}
            columns={custcolumns}
            pageSize={5}
            loading={props.isCustomersLoading}
            components={{
              LoadingOverlay: LinearProgress,
              Toolbar: CustomToolbar,
            }}
            componentsProps={{ toolbar: { title: "Clients" } }}
          />
        </Box>

        <>
          <Box
            sx={{
              height: 300,
              width: "49%",
              mb: 1,
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
          >
            <HelperDialog open={dialogOpen} {...dialogProp.current} />
            <DataGrid
              rowsPerPageOptions={[3, 5]}
              rows={props.channelTableData}
              columns={channelcolumns}
              pageSize={5}
              loading={props.isChannelsLoading}
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: CustomToolbar,
              }}
              componentsProps={{ toolbar: { title: "Channels" } }}
            />
          </Box>
        </>
      </div>
    </div>
  );
}

export default DisplayTable;
