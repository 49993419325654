import React, { useState } from "react";
import { Navbar } from "../Navbar/Navbar";
import RenamePreview from "./RenamePreview";
import { DocumentIcon } from "@heroicons/react/solid";
import { useSelector } from "react-redux";
import RenameList from "./RenameList";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const [fileOverride, setFileOverride] = useState(false);
  const [folderOverride, setFolderOverride] = useState(false);
  const [keydata, setKeydata] = useState();
  const [execKeyEvent, setExecKeyEvent] = useState();
  const [handleKeydown,setHandleKeydown] = useState(()=>{})
  const handleKeyDown = (event) => {
    let charCode = String.fromCharCode(event.which).toLowerCase();
    if ((event.ctrlKey || event.metaKey) && charCode === "o") {
      setFileOverride(true);
    }
    if ((event.ctrlKey || event.metaKey) && charCode === "x") {
      setFileOverride(false);
    }
  };
  return (
    <div
      className="w-full h-200 flex flex-col"
      tabIndex="0"
      onKeyDown={handleKeyDown}
    >
 <Navbar showAutoSave/>
      <RenameList
        fileOverride={fileOverride}
        function={setExecKeyEvent}
        folderOverride={folderOverride}
      />
    </div>
  );
};
