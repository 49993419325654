import React, { useEffect, useState } from "react";
import UserComp from "./UserComp";

import DisplayTable from "./DisplayTable";
import { Box } from "@mui/material";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
const bankNames = [
  "Bank of Baroda",
  "Bank of Maharashtra",
  "Canara Bank",
  "Central Bank of India",
  "Indian Bank",
  "Indian Overseas Bank",
  "Punjab & Sind Bank",
  "Punjab National Bank",
  "State Bank of India",
  "UCO Bank",
  "Union Bank of India",
  "Axis Bank Ltd.",
  "Bandhan Bank Ltd.",
  "CSB Bank Ltd.",
  "City Union Bank Ltd.",
  "DCB Bank Ltd.",
  "Dhanlaxmi Bank Ltd.",
  "Federal Bank Ltd.",
  "HDFC Bank Ltd",
  "ICICI Bank Ltd.",
  "Induslnd Bank Ltd",
  "IDFC First Bank Ltd.",
  "Jammu & Kashmir Bank Ltd.",
  "Karnataka Bank Ltd.",
  "Karur Vysya Bank Ltd.",
  "Kotak Mahindra Bank Ltd",
  "Nainital Bank Ltd.",
  "RBL Bank Ltd.",
  "South Indian Bank Ltd.",
  "Tamilnad Mercantile Bank Ltd.",
  "YES Bank Ltd.",
  "IDBI Bank Ltd.",
  "India Post Payments Bank Limited",
  "Fino Payments Bank Limited",
  "Paytm Payments Bank Limited",
  "Airtel Payments Bank Limited",
];
const branches = [];
const columns = [
  { field: "username", headerName: "Username" },
  { field: "name", headerName: "Name", width: 300 },
  { field: "lastLogin", headerName: "Last Login", width: 300 },
  // { field: "dateCreated", headerName: "Date Created", width: 300 },
  { field: "role", headerName: "Role", width: 300 },
  { field: "locked", headerName: "Locked", width: 100 },
];

//regex for validations
const validations = {
  aadharno: /^[2-9]{1}[0-9]{3}\s[0-9]{4}\s[0-9]{4}$/,
  phoneno: /^\d{10}$/,
  gpayno: /^\d{10}$/,
  email:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ifsc: /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/,
  accno: /^(\d{1,4}\s)*\d{1,4}$/,
};
const resourceReqd = ["name", "phoneno"];
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  function SlideTransition(props) {
    return <Slide {...props} />;
  }
  const [errorState, setErrorState] = useState({});
  let sampleData = [{ name: "Test", index: 1 }];
  const [status, setStatus] = useState(false);
  const [statusC, setStatusC] = useState(false);
  const [statusCustomer, setStatusCust] = useState(false);
  // const [formDataC, setFormDataC] = useState({});
  const [formData, setFormData] = useState({});
  const [timer, setTimer] = useState(null);
  const [isUsersLoading, setUsersLoading] = useState(true);
  const [isChannelsLoading, setChannelsLoading] = useState(true);
  const [isCustomersLoading, setCustomersLoading] = useState(true);
  const [custTableData, setCustTableData] = useState([]);
  const [channelTableData, setChannelTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openSnackBar, setSnackbar] = useState(false);
  const [snackBardata, setSnackbarData] = useState("");
  const [snackType, setSnackType] = useState("");
  const [isEditMode, setEditMode] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar(false);
  };
  const updateUsers = () => {
    axios
      .get("api/user-access-control")
      .then((response) => {
        response.data.map((e, i) => (e.id = i));
        setUsersLoading(false);
        setTableData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    axios
      .get("api/customer-list/")
      .then((response) => {
        response.data.map((e, i) => (e.id = i));
        setCustomersLoading(false);
        setCustTableData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("api/marketing-list/")
      .then((response) => {
        response.data.map((e, i) => (e.id = i));

        setChannelsLoading(false);
        setChannelTableData(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    updateUsers();
  }, []);
  const lockUser = (row, status) => {
    axios
      .post("api/lock-user/", {
        username: row.username,
        status: status,
      })
      .then((response) => {
        const copy = [...tableData];
        copy[row.id].locked = !row.locked;
        setTableData(copy);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleEdit = (userType, username, bankingDetails = null) => {
    axios
      .get("api/profile/?username=" + username)
      .then((r) => {
        // console.log(r);
        let newBank =
          bankingDetails !== null
            ? {
                ifsc: "jhdfjs12123",
                bankname: bankingDetails.bank_name,
                bankbranch: bankingDetails.branch,
                accno: bankingDetails.account_number,
              }
            : {};
        let newForm = {
          name: r.data[0].full_name,
          phoneno: r.data[0].mobile_number,
          aadharno: r.data[0].aadhar,
          gpayno: r.data[0].gpay_number,
          refby: r.data[0].referred_by,
          address: r.data[0].address,
          email: r.data[0].email,
          status: r.data[0].locked ? "Inactive" : "Active",
          username: r.data[0].username,
          banking_id: r.data[0].banking_details,
          ...newBank,
        };
        setFormData(newForm);
        if (userType === 0) {
          setStatus(true);
        } else if (userType === 1) {
          setStatusC(true);
        } else {
          setStatusCust(true);
        }

        // setEditMode(false);
      })
      .catch((e) => alert(e));
    setEditMode(true);
  };

  const authState = useSelector((state) => state.auth);
  const handleClose = () => {
    setStatus(false);
    setFormData({});
    setEditMode(false);
    setErrorState({});
  };
  const handleCloseC = () => {
    setEditMode(false);
    setStatusC(false);
    setFormData({});
    setErrorState({});
  };
  const handleCloseCustomer = () => {
    setStatusCust(false);
    setEditMode(false);
    setFormData({});
    setErrorState({});
  };
  const handleCustSubmit = () => {
    setLoading(true);
    // console.log(formData);
    if (isEditMode) {
      axios
        .patch("api/customer-list/", {
          ...formData,
          mobile_number: formData.phoneno,
        })
        .then((r) => {
          setStatusCust(false);
          setEditMode(false);
          setLoading(false);
        })
        .catch((e) => alert(e));
      return;
    }
    axios
      .post("api/customer-create/", {
        name: formData.name,
        address: formData.address,
        mobile_number: formData.phoneno,
        email: formData.email,
      })
      .then((response) => {
        setLoading(false);
        setStatusCust(false);
        updateUsers();
        setSnackbarData("New customer has been created successfully");
        setSnackType("success");
        setSnackbar(true);
        setEditMode(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChannelSubmit = () => {
    setLoading(true);
    console.log("Submitting channel form");
    if (isEditMode) {
      axios
        .patch("api/profile/", {
          full_name: formData.name,
          address: formData.address,
          mobile_number: formData.phoneno,
          email: formData.email,
          gpay_number: formData.gpayno,
          aadhar: formData.aadharno,
          username: formData.username,
          // banking_details: response.data.banking_obj_id,
          is_marketing: true,
        })
        .then((r) => {
          setStatusC(false);
          setEditMode(false);
        });
      axios
        .patch("api/banking-details/", {
          id: formData.banking_id,
          ifsc_code: formData.ifsc,
          account_number: formData.accno,
          bank_name: formData.bankname,
          branch: formData.bankbranch,
        })
        .catch((e) => alert(e));
      return;
    }
    axios
      .post("api/banking-details/", {
        bank_name: formData.bankname,
        branch: "Bhandup",
        account_number: formData.accno,
        ifsc_code: formData.ifsc,
      })
      .then(function (response) {
        console.log(response);

        axios
          .post("api/signup/", {
            full_name: formData.name,
            address: formData.address,
            mobile_number: formData.phoneno,
            email: formData.email,
            gpay_number: formData.gpayno,
            aadhar: formData.aadharno,
            username: formData.username,
            banking_details: response.data.banking_obj_id,
            is_marketing: true,
            password1: formData.pwd,
          })
          .then(function (response) {
            console.log(response);
            setLoading(false);
            updateUsers();
            handleCloseC();
            setEditMode(false);
            setSnackbarData("New channel has been created successfully");
            setSnackType("success");
            setSnackbar(true);
          })
          .catch(function (error) {
            setSnackbarData("Duplicate data found can't create user");
            setSnackType("error");
            setSnackbar(true);
          });
      })
      .catch(function (error) {});
  };
  const [loading, setLoading] = useState(false);
  const handleValidations = (e, inputField, val = null) => {
    if (val !== null) {
      setFormData({ ...formData, [inputField]: val });
      if (val === "") {
        setErrorState({ ...errorState, [inputField]: true });
      } else {
        setErrorState({ ...errorState, [inputField]: false });
      }
      return;
    }
    if (Object.hasOwn(validations, inputField)) {
      setErrorState({
        ...errorState,
        [inputField]: !validations[inputField].test(e.target.value),
      });
    } else if (resourceReqd.includes(inputField) && e.target.value === "") {
      setErrorState({
        ...errorState,
        [inputField]: true,
      });
    } else if (e.target.value.length > 0) {
      setErrorState({
        ...errorState,
        [inputField]: false,
      });
    }
  };

  const handleCustomerEdit = (data) => {
    let newForm = { ...data };
    newForm.phoneno = newForm.mobile_number;
    setFormData(newForm);
    setEditMode(true);
    setStatusCust(true);
  };

  const handleChange = (e, inputField, val = null) => {
    clearTimeout(timer);
    setTimer(setTimeout(() => handleValidations(e, inputField, val), 1000));

    let newVal = e.target.value;
    if (inputField === "aadharno" || inputField === "accno") {
      newVal = e.target.value.replaceAll(" ", "");
      newVal = newVal.replace(/\d{4}(?=.)/g, "$& ");
    }
    setFormData({ ...formData, [inputField]: newVal });
  };
  const checkValidity = (isRes) => {
    let flag = true;
    let errorList = "";
    if (isRes) {
      for (let x of resourceReqd) {
        if (
          !Object.hasOwn(formData, x) ||
          formData.x === "" ||
          (Object.hasOwn(errorState, x) && errorState.x)
        ) {
          flag = false;
        }
      }
    }
    return flag;
  };
  const submitForm = () => {
    if (!checkValidity(true)) {
      setSnackbarData("Please fill all required fields");
      setSnackType("error");
      setSnackbar(true);
    } else {
      setLoading(true);
      if (isEditMode) {
        axios
          .patch("api/profile/", {
            full_name: formData.name,
            address: formData.address,
            mobile_number: formData.phoneno,
            email: formData.email,
            gpay_number: formData.gpayno,
            aadhar: formData.aadharno,
            username: formData.username,
            // banking_details: response.data.banking_obj_id,
            // is_marketing: true,
          })
          .then((r) => {
            setStatus(false);
            setEditMode(false);
            setLoading(false);
          });
        axios
          .patch("api/banking-details/", {
            id: formData.banking_id,
            ifsc_code: formData.ifsc,
            account_number: formData.accno,
            bank_name: formData.bankname,
            branch: formData.bankbranch,
          })
          .catch((e) => alert(e));
        return;
      }
      axios
        .post("api/banking-details/", {
          bank_name: formData.bankname,
          branch: formData.bankbranch,
          account_number: formData.accno,
          ifsc_code: formData.ifsc
            ? formData.ifsc.replaceAll(" ", "")
            : undefined,
        })
        .then(function (response) {
          // console.log(response);
          const aadharfinal = formData.aadharno
            ? formData.aadharno.replaceAll(" ", "")
            : undefined;
          axios
            .post("api/signup/", {
              full_name: formData.name,
              address: formData.address,
              mobile_number: formData.phoneno,
              email: formData.email,
              aadhar: aadharfinal,
              gpay_number: formData.gpayno,
              banking_details: response.data.banking_obj_id,
            })
            .then(function (response) {
              console.log(response);
              setLoading(false);
              updateUsers();
              handleClose();

              setSnackbarData("New resource has been created successfully");
              setSnackType("success");
              setSnackbar(true);
            })
            .catch(function (error) {
              setLoading(false);

              setSnackbarData("Duplicate data found can't create user");
              setSnackType("error");
              setSnackbar(true);
            });
        })
        .catch(function (error) {
          setLoading(false);
        });
    }
  };
  return (
    <div className=" m-8 flex flex-col space-y-6 mt-16 w-full">
      <Snackbar
        sx={{ width: 600 }}
        anchorOrigin={{
          height: 100,
          vertical: "top",
          horizontal: "center",
        }}
        TransitionComponent={SlideTransition}
        open={openSnackBar}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
      >
        <Alert
          variant="filled"
          severity={snackType}
          sx={{
            width: "100%",
            fontSize: 20,
          }}
        >
          {snackBardata}
        </Alert>
      </Snackbar>
      <p className=" text-2xl text-slate-300 tracking-widest font-bold font-sans">
        ACCESS CONTROL
      </p>
      <div className="border-t w-80 border-slate-500 h-1"></div>
      <Box sx={{ width: "100%" }}>
        <DisplayTable
          customerEdit={handleCustomerEdit}
          tableData={tableData}
          isChannelsLoading={isChannelsLoading}
          isCustomersLoading={isCustomersLoading}
          isUsersLoading={isUsersLoading}
          custTableData={custTableData}
          lockUser={lockUser}
          handleEdit={handleEdit}
          channelTableData={channelTableData}
          updateUsers={updateUsers}
          setSnackbarData={setSnackbarData}
          setSnackbar={setSnackbar}
          setSnackType={setSnackType}
        />
        <div className="space-x-3 mt-4">
          <Button
            variant="contained"
            onClick={() => setStatus(!status)}
            startIcon={<PersonAddAltRoundedIcon />}
            m
          >
            New Resource
          </Button>
          <Button
            variant="contained"
            onClick={() => setStatusC(!statusC)}
            startIcon={<PersonAddAltIcon />}
            margin="normal"
          >
            New Channel
          </Button>
          <Button
            variant="contained"
            onClick={() => setStatusCust(!statusCustomer)}
            startIcon={<PersonAddAltIcon />}
            margin="normal"
          >
            New Customer
          </Button>
        </div>
        <Dialog
          open={status}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: "#0F172A",
            },
          }}
        >
          <DialogTitle>New Resource</DialogTitle>
          <DialogContentText textAlign="center">
            (All Fields with * are required)
          </DialogContentText>
          <DialogContent>
            {/* <DialogContentText>Enter Resource's details</DialogContentText> */}
            <Divider textAlign="left">
              <Chip label="Personal Details" />
            </Divider>
            <div className="flex space-x-3">
              <TextField
                autoFocus
                variant="outlined"
                margin="normal"
                id="name"
                label="Name"
                type="text"
                value={formData.name}
                onChange={(e) => handleChange(e, "name")}
                required
                error={errorState.name}
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="phone"
                label="Phone"
                error={errorState.phoneno}
                value={formData.phoneno}
                onChange={(e) => handleChange(e, "phoneno")}
                inputProps={{ maxLength: 10 }}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="email"
                label="Email"
                type="email"
                value={formData.email}
                error={errorState.email}
                required
                onChange={(e) => handleChange(e, "email")}
              />
            </div>
            <div className="flex space-x-3">
              <TextField
                variant="outlined"
                margin="normal"
                id="aadhar"
                label="Aadhar No"
                fullWidth
                error={errorState.aadharno}
                onChange={(e) => handleChange(e, "aadharno")}
                value={formData.aadharno}
                inputProps={{ maxLength: 14 }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="gpayno"
                label="GPay Number"
                value={formData.gpayno}
                error={errorState.gpayno}
                onChange={(e) => handleChange(e, "gpayno")}
                inputProps={{ maxLength: 10 }}
              />
            </div>
            <div className="flex space-x-3 w-full">
              <Autocomplete
                // disablePortal
                freeSolo
                id="refby"
                options={[]}
                sx={{ width: 300 }}
                onChange={(e) => handleChange(e, "refby")}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    label="Ref By"
                    value={formData.refby}
                  />
                )}
              />
              <Autocomplete
                // disablePortal
                id="combo-box-demo"
                options={["Active", "Inactive"]}
                sx={{ width: 300 }}
                onChange={(e, newValue) => {
                  handleChange(e, "status", newValue);
                }}
                value={formData.status}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    label="Status"
                    error={errorState.status}
                  />
                )}
              />
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              id="address"
              label="Address"
              type="text"
              multiline
              fullWidth
              rows={3}
              error={errorState.address}
              value={formData.address}
              onChange={(e) => handleChange(e, "address")}
            />

            <Divider textAlign="left">
              <Chip label="Banking Details" />
            </Divider>
            <div className="flex space-x-3 w-full">
              <Autocomplete
                // disablePortal
                freeSolo
                id="combo-box-demo"
                options={bankNames}
                value={formData.bankname}
                sx={{ width: 300 }}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  if (typeof newValue === "string") {
                    handleChange(e, "bankname", newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    handleChange(e, "bankname", newValue.inputValue);
                  } else {
                    handleChange(e, "bankname", newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    error={errorState.bankname}
                    label="Bank Name"
                    onChange={(e) => handleChange(e, "bankname")}
                  />
                )}
              />
              <Autocomplete
                // disablePortal
                freeSolo
                id="combo-box-demo"
                options={[]}
                value={formData.bankbranch}
                sx={{ width: 300 }}
                onChange={(e, newValue) => {
                  if (typeof newValue === "string") {
                    handleChange(e, "bankbranch", newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    handleChange(e, "bankbranch", newValue.inputValue);
                  } else {
                    handleChange(e, "bankbranch", newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="normal"
                    label="Bank Branch"
                    error={errorState.bankbranch}
                    onChange={(e) => handleChange(e, "bankbranch")}
                  />
                )}
              />
            </div>
            <div className="flex space-x-3 w-full">
              <TextField
                variant="outlined"
                margin="normal"
                id="accno"
                label="Account Number"
                value={formData.accno}
                onChange={(e) => handleChange(e, "accno")}
                sx={{ width: 300 }}
              />
              <TextField
                variant="outlined"
                error={errorState.ifsc}
                margin="normal"
                id="ifsc"
                value={formData.ifsc}
                sx={{ width: 300 }}
                onChange={(e) => handleChange(e, "ifsc")}
                label="IFSC Code"
                inputProps={{ maxLength: 11 }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <LoadingButton
              onClick={() => {
                submitForm();
              }}
              loading={loading}
              variant="contained"
            >
              {isEditMode ? "Edit" : "Create"}
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={statusC}
          onClose={handleCloseC}
          PaperProps={{
            style: {
              backgroundColor: "#0F172A",
            },
          }}
        >
          <DialogTitle>New Channel</DialogTitle>
          <DialogContentText textAlign="center">
            (All Fields with * are required)
          </DialogContentText>
          <DialogContent>
            {/* <DialogContentText>Enter Resource's details</DialogContentText> */}
            <Divider textAlign="left">
              <Chip label="Personal Details" />
            </Divider>
            <div className="flex space-x-3">
              <TextField
                autoFocus
                variant="outlined"
                margin="normal"
                id="name"
                value={formData.name}
                label="Name"
                type="text"
                onChange={(e) => handleChange(e, "name")}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="phone"
                value={formData.phoneno}
                label="Phone"
                error={errorState.phoneno}
                onChange={(e) => handleChange(e, "phoneno")}
                inputProps={{ maxLength: 10 }}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="email"
                label="Email"
                value={formData.email}
                type="email"
                error={errorState.email}
                onChange={(e) => handleChange(e, "email")}
                required
              />
            </div>

            <TextField
              variant="outlined"
              margin="normal"
              id="address"
              label="Address"
              value={formData.address}
              type="text"
              multiline
              fullWidth
              rows={3}
              onChange={(e) => handleChange(e, "address")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              id="remark"
              label="Remark"
              value={formData.remark}
              type="text"
              fullWidth
              rows={1}
              onChange={(e) => handleChange(e, "remark")}
            />
            <TextField
              variant="outlined"
              margin="normal"
              id="pwd"
              label="pwd"
              value={formData.pwd}
              type="password"
              fullWidth
              rows={1}
              onChange={(e) => handleChange(e, "pwd")}
            />
            <Divider textAlign="left">
              <Chip label="Banking Details" />
            </Divider>
            <div className="flex space-x-3 w-full">
              <Autocomplete
                // disablePortal
                freeSolo
                id="combo-box-demo"
                options={bankNames}
                sx={{ width: 300 }}
                value={formData.bankname}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  if (typeof newValue === "string") {
                    handleChange(e, "bankname", newValue);
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    handleChange(e, "bankname", newValue.inputValue);
                  } else {
                    handleChange(e, "bankname", newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} margin="normal" label="Bank Name" />
                )}
              />
              <Autocomplete
                // disablePortal
                freeSolo
                id="combo-box-demo"
                value={formData.bankbranch}
                options={branches}
                sx={{ width: 300 }}
                onChange={(e) => handleChange(e, "bankbranch")}
                renderInput={(params) => (
                  <TextField {...params} margin="normal" label="Bank Branch" />
                )}
              />
            </div>
            <div className="flex space-x-3 w-full">
              <TextField
                variant="outlined"
                margin="normal"
                id="accno"
                label="Account Number"
                value={formData.accno}
                onChange={(e) => handleChange(e, "accno")}
                sx={{ width: 300 }}
              />

              <TextField
                variant="outlined"
                error={errorState.ifsc}
                margin="normal"
                id="ifsc"
                value={formData.ifsc}
                sx={{ width: 300 }}
                onChange={(e) => handleChange(e, "ifsc")}
                label="IFSC Code"
                inputProps={{ maxLength: 11 }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseC}>Cancel</Button>
            <Button onClick={handleChannelSubmit} variant="contained">
              {isEditMode ? "Edit" : "Create"}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={statusCustomer}
          onClose={handleCloseCustomer}
          PaperProps={{
            style: {
              backgroundColor: "#0F172A",
            },
          }}
        >
          <DialogTitle>New Customer</DialogTitle>
          <DialogContentText textAlign="center">
            (All Fields with * are required)
          </DialogContentText>
          <DialogContent>
            <div className="flex space-x-3">
              <TextField
                autoFocus
                variant="outlined"
                margin="normal"
                id="name"
                label="Name"
                type="text"
                disabled={isEditMode}
                value={formData.name}
                onChange={(e) => handleChange(e, "name")}
                required
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="phone"
                label="Phone"
                required
                value={formData.phoneno}
                error={errorState.phoneno}
                onChange={(e) => handleChange(e, "phoneno")}
                inputProps={{ maxLength: 10 }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                id="email"
                label="Email"
                type="email"
                value={formData.email}
                error={errorState.email}
                onChange={(e) => handleChange(e, "email")}
              />
            </div>

            <TextField
              variant="outlined"
              margin="normal"
              id="address"
              label="Address"
              type="text"
              multiline
              fullWidth
              value={formData.address}
              rows={3}
              onChange={(e) => handleChange(e, "address")}
              // required
            />
            <TextField
              variant="outlined"
              margin="normal"
              id="remark"
              label="Remark"
              type="text"
              fullWidth
              rows={1}
              onChange={(e) => handleChange(e, "remark")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCustomer}>Cancel</Button>
            <Button onClick={handleCustSubmit} variant="contained">
              {isEditMode ? "Edit" : "Create"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};
