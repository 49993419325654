import { createSlice } from "@reduxjs/toolkit";
export const blobSlice = createSlice({
  name: "blob",
  initialState: {
    allBlobs: {},
  },
  reducers: {
    addBlob: (state, action) => {
      state.allBlobs = {
        ...state.allBlobs,
        [action.payload.filename]: action.payload.blobArray,
      };
    },
    restoreBlobs: (state, action) => {
      state.allBlobs = action.payload.allBlobs;
    },
    clearBlobs: (state, action) => {
      state.allBlobs = {};
    },
    destroyBlob: (state, action) => {
      state.allBlobs[action.payload.index] = null
    }
  },
});

export const { addBlob, restoreBlobs, clearBlobs, destroyBlob } = blobSlice.actions;
export default blobSlice.reducer;
