import {
  DialogConten,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Chip,
  Autocomplete,
  TextField,
  Box,
  Tabs,
  Tab,
  Typography,
  DialogContentText,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import axios from "axios";
import HelperDialog from "../../../HelperDialog/HelperDialog";
import { DocumentIcon, FilterIcon } from "@heroicons/react/solid";
import RenameList from "../../../PdfRename/RenameList";
import Checkbox from "@mui/material/Checkbox";
import { Preview } from "../../../LeftBox/Preview";
import { AllotData } from "./AllotData";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import {
  Category,
  Delete,
  DeleteOutlineRounded,
  RedoRounded,
  TenMp,
} from "@mui/icons-material";
export const AllotFiles = (props) => {
  const authState = useSelector((state) => state.auth);
  const [categories, setCategories] = useState([]);
  const projectData = props.projectData;
  const [isLoading, setLoading] = useState(true);
  const [allocated, setAllocated] = useState([]);
  const [fileLabelName, setFileLabelName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedResource, setSelectedResource] = useState([]);
  const [userData, setUserData] = useState(null);
  const [allotedresources, setAllotedResources] = useState([]);
  const roletabs = ["c_split", "c_name"];
  const [fileData, setFileData] = useState([]);
  const [preview, setPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [pendingusers, setPendingUsers] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [checkboxcheck, setCheckBox] = useState([]);
  const [allocPreview, setAllocPreview] = useState(false);
  const [allocPreviewData, setAllocPreviewData] = useState({});
  const [newlyconfigured, setNewlyConfigured] = useState([]);
  const [delTrack, setDelTrack] = useState(0);
  const [allotTrack, setAllotTrack] = useState(0);
  const [allotTaskWise, setAllotTaskWise] = useState({});
  const [selectAllCheck, setSelectAll] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialogProp = React.useRef({});
  const permdata = {
    c_split: "Splitting",
    c_name: "Naming",
  };
  const [urls, setUrls] = useState({});

  const toggleSelect = () => {
    var curstate = [...checkboxcheck];
    curstate.fill(!selectAllCheck);
    setCheckBox(curstate);
    setSelectAll(!selectAllCheck);
  };

  const handleSubmit = () => {
    const copy = [...newlyconfigured];
    console.log(copy);
    const genCopy = copy.map((obj) => {
      console.log(obj);
      const filenames = obj.files;

      return { ...obj, files: filenames, project: props.projectData.id };
    });
    const submitData = { file_allocate: genCopy };
    console.log(submitData.file_allocate);
    if (submitData.file_allocate.length > 0) {
      axios
        .post("api/file-allocate/", submitData)
        .then((response) => {
          console.log(response);

          props.setSnackbarData("Resources have been allocated successfully");
          props.setSnackbar(true);
          props.setSnackType("success");
          setAllocPreview(false);
          var updatedval = props.update;

          console.log("here");
          console.log(updatedval + 1);
        })
        .catch((error) => {
          props.setSnackbarData("Could not allocated resources try again ");
          props.setSnackbar(true);
          props.setSnackType("error");
        });
    } else {
      props.setSnackbarData("Please allocate resources before submitting");
      props.setSnackbar(true);
      props.setSnackType("error");
    }
  };
  //fetch users and categories and files
  useEffect(() => {
    // setFileData(dummyfiledata);
    let categories = [];
    let users = [];

    axios
      .get("api/root-category/", {
        params: { project_id: props.projectData.id },
      })
      .then((response) => {
        categories = response.data.map((obj) => {
          return obj.category;
        });
        axios
          .get("api/user-project-role/" + props.projectData.id)
          .then((response) => {
            users = response.data.user_permissions.map((userobj) => {
              return {
                name: userobj.username,
                role: userobj.permission.split(","),
              };
            });

            const convertusers = users
              .map((user) => {
                const arrayobj = user.role.filter((perm) => {
                  console.log(perm);
                  if (perm === "c_split" || perm === "c_name") {
                    return true;
                  }
                  return false;
                });

                const allperms = arrayobj.map((role) => {
                  if (role === "c_split")
                    return { name: user.name, role: "Splitting" };
                  else if (role === "c_name")
                    return { name: user.name, role: "Naming" };
                });
                return allperms;
              })
              .filter((array) => {
                if (array.length > 0) {
                  return true;
                }
              });
            const final = convertusers.flat();

            setPendingUsers(final);

            var taskwiseallot = {};
            categories.forEach((obj) => {
              taskwiseallot[obj] = {
                c_split: [],
                c_name: [],
                c_verify: [],
              };
            });
            console.log(taskwiseallot);
            setAllotTaskWise(taskwiseallot);
            var allFiles = {};

            axios
              .get("api/project-files/" + props.projectData.id, {
                params: { status: "all" },
              })
              .then((response) => {
                console.log(response);
                var files = [];
                var alloted_list = [];
                files = response.data.map((obj) => {
                  var pack = {
                    url: obj.s3_path,
                    name: obj.file_name,
                    num_pages: obj.num_pages,
                    category: obj.category,
                    status: obj.status,
                  };
                  if (obj.is_split_allocated && obj.is_naming_allocated) {
                    alloted_list.push({
                      id: obj.id,
                      url: obj.s3_path,
                      name: obj.file_name,
                      num_pages: obj.num_pages,
                      category: obj.category,
                      splitting_user: obj.splitting_user,
                      naming_user: obj.naming_user,
                      status: obj.status,
                    });
                  } else {
                    return pack;
                  }
                });
                console.log(alloted_list, files);

                for (let file of files) {
                  if (file) {
                    if (!allFiles[file.category]) {
                      allFiles[file.category] = [file];
                    } else {
                      allFiles[file.category].push(file);
                    }
                  }
                }
                for (let cat of categories) {
                  if (!allFiles[cat]) {
                    allFiles[cat] = [];
                  }
                }

                setCategories(categories);

                // files
                //   .filter((item) => item)
                //   .forEach((obj) => {
                //     console.log(obj);
                //     allFiles[obj.category] = allFiles[obj.category]
                //       ? [...allFiles[obj.category], obj]
                //       : [obj];
                //   });

                setAllotedResources(alloted_list);
                setFileData(allFiles);

                setLoading(false);
              })
              .catch((error) => {
                props.setSnackbarData("Could not fetch files try again ");
                props.setSnackbar(true);
                props.setSnackType("error");
              });

            setLoading(false);
          })

          .catch((error) => {
            props.setSnackbarData(
              "Could not fetch project data due to timeout"
            );
            props.setSnackbar(true);
            props.setSnackType("error");
          });
      })
      .catch((error) => {
        props.setSnackbarData(
          "Could not fetch Cateogry data from server please try again"
        );
        props.setSnackbar(true);
        props.setSnackType("error");
      });

    // var allFiles = {};

    setTabs(["Splitting", "Naming"]);

    // setPendingUsers(dummyuserData);
  }, [props.projectData.id, delTrack, allotTrack]);
  //handlers
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const close = () => {
    setPreview(false);
    setPreviewData({});
  };

  const showPreview = (file) => {
    axios
      .post("api/single-s3/", { s3_path: file })
      .then((response) => {
        console.log(response);
        setPreviewData({ blob: response.data.url, close });
        setPreview(true);
      })
      .catch((err) => {
        props.setSnackbarData("Could Not Fetch File");
        props.setSnackbar(true);
        props.setSnackType("error");
      });
  };
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleAllot = () => {
    var files = fileData[selectedCategory].filter((val, i) => {
      if (checkboxcheck[i]) {
        return true;
      }
    });
    // .map((file) => {
    //   return file.name;
    // });
    var count = 0;

    const check = selectedResource.map((tabdata) => {
      if (tabdata !== "" || tabdata !== null) {
        count++;
      }
    });
    if (count === 2 && selectedCategory && files.length > 0) {
      files = files.map((file) => {
        return file.name;
      });
      var rolewise = {};
      roletabs.forEach((role, index) => {
        rolewise = { ...rolewise, [role]: selectedResource[index] };
      });
      console.log(rolewise);
      const config = {
        file_allocate: [
          {
            user: rolewise,
            category: selectedCategory,
            files,
            project: props.projectData.id,
          },
        ],
      };
      axios
        .post("api/file-allocate/", config)
        .then((response) => {
          setAllotTrack(allotTrack + 1);

          props.setSnackbarData("Alloted Files successfully");
          props.setSnackbar(true);
          props.setSnackType("success");
          setAllocPreview(false);
          props.updateTasks(props.update + 1);
          setCurrentTab(0);
        })
        .catch((error) => {
          props.setSnackbarData(
            "Could not do file allocation due to a server error"
          );
          props.setSnackbar(true);
          props.setSnackType("success");
        });
      const modifiedData = { ...fileData };
      const final = modifiedData[selectedCategory].filter((fileobj, i) => {
        if (!checkboxcheck[i]) {
          return true;
        }
      });
      setNewlyConfigured([...newlyconfigured, config]);

      setAllotedResources([...allotedresources, config]);
      console.log(config);
      modifiedData[selectedCategory] = final;
      setFileData({ ...modifiedData });
      setSelectedCategory(null);
      const cur = [...selectedResource];
      setSelectedResource([]);
    } else {
      props.setSnackbarData(
        "Please allocate resources in all task types before submitting"
      );
      props.setSnackbar(true);
      props.setSnackType("error");
    }
  };
  const handleCheckBox = (event, index, category) => {
    var curstate = [...checkboxcheck];
    curstate[index] = event.target.checked;
    setCheckBox(curstate);
  };
  const callDelete = (id) => {
    let newCheck = Array(checkboxcheck.length + 1).fill(false);
    setCheckBox(newCheck);

    axios
      .delete("api/file-allocate/", {
        data: {
          file_id: id,
        },
      })
      .then((response) => {
        setDelTrack(delTrack + 1);

        props.setSnackbarData("Deleted Allocation");
        props.setSnackbar(true);
        props.setSnackType("info");
        setDialogOpen(false);
      })
      .catch((error) => {
        props.setSnackbarData("Could not delete allocation");
        props.setSnackbar(true);
        props.setSnackType("error");
      });
  };
  const deleteAllocation = (id) => {
    setDialogOpen(true);
    dialogProp.current = {
      onSubmit: callDelete.bind(this, id),
      handleClose: () => {
        console.log("here");
        setDialogOpen(false);
      },
      title: "Delete File Allocation",
      desc: "All related data and children files will be deleted",
      okText: "Delete",
    };
  };
  // const removeChip = (i) => {};
  //Custom Tab view component
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  if (isLoading) {
    return (
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#0F172A",
          },
        }}
        open={props.open}
        onClose={(e, reason) => {
          console.log(reason);
          if (reason !== "backdropClick") {
            props.handleClose();
          }
        }}
      >
        <DialogTitle>
          File Allotment
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                props.handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <div>
      <AllotData
        open={allocPreview}
        onClose={() => {
          setAllocPreview(false);
        }}
        config={allocPreviewData}
      />
      <HelperDialog open={dialogOpen} {...dialogProp.current} />
      {preview && <Preview {...previewData} />}
      {!preview && (
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "#0F172A",
            },
          }}
          fullWidth
          maxWidth="lg"
          open={props.open}
          onClose={(e, reason) => {
            console.log(reason);
            if (reason !== "backdropClick") {
              props.handleClose();
            }
          }}
          files={props.files}
        >
          <DialogTitle>
            File Allotment
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <IconButton
                aria-label="close"
                onClick={() => {
                  props.handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <div className="flex">
              <div>
                <Divider textAlign="left">
                  <Chip label={"Alloted Resources for " + selectedCategory} />
                </Divider>

                {allotedresources.length > 0 ? (
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 600,

                      position: "relative",
                      overflow: "auto",
                      maxHeight: 300,
                      "& ul": { padding: 0 },
                    }}
                  >
                    {allotedresources
                      .filter((obj) => {
                        return obj.category === selectedCategory;
                      })
                      .sort(function (x, y) {
                        if (x.name < y.name) {
                          return -1;
                        }
                        if (x.name > y.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((file, i) => {
                        var colorclass = "bg-green-800";
                        // eslint-disable-next-line default-case

                        // eslint-disable-next-line default-case
                        switch (file.status) {
                          case "Deskewed":
                            colorclass = "bg-red-600";
                            break;
                          case "Splitted":
                            colorclass = "bg-yellow-600";
                            break;
                          case "Named":
                            colorclass = "bg-green-800";
                        }
                        console.log(colorclass);
                        return (
                          <div
                            className={
                              "flex rounded-lg m-2 w-4/7 h-full " + colorclass
                            }
                          >
                            <ListItem>
                              <ListItemText
                                // primary={resource.user}
                                secondary={
                                  <>
                                    {/* <Typography
                                      sx={{ display: "inline" }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      - {permdata[resource.role]}
                                    </Typography> */}
                                    <div>
                                      <Button
                                        className="break-all w-30 "
                                        onClick={() => {
                                          showPreview(file.url);
                                        }}
                                      >
                                        {file.name}
                                      </Button>
                                      <div>
                                        <p>Splitting: {file.splitting_user}</p>
                                        <p> Naming: {file.naming_user}</p>
                                      </div>
                                    </div>
                                  </>
                                }
                              />
                              {/* </ListItem> */}
                            </ListItem>
                            <Divider variant="inset" component="li" />
                            <ListItem>
                              <DeleteOutlineRounded
                                onClick={(ev) => {
                                  console.log(file.id);
                                  deleteAllocation(file.id);
                                }}
                              ></DeleteOutlineRounded>
                            </ListItem>
                          </div>
                        );
                      })}
                  </List>
                ) : (
                  <DialogContentText className="py-4">
                    No Resources Configured
                  </DialogContentText>
                )}
              </div>
              <div className="w-1/2">
                <Divider textAlign="left">
                  <Chip label="Categories to be alloted" />
                </Divider>
                {/* Category Autocomplete */}
                <Autocomplete
                  // disablePortal
                  id="combo-box-demo"
                  options={categories}
                  value={selectedCategory}
                  getOptionLabel={(option) =>
                    fileData[option]
                      ? option + " [Rem:" + fileData[option].length + "]"
                      : option
                  }
                  sx={{ width: 300 }}
                  onChange={(e, newValue) => {
                    setSelectedCategory(newValue);
                    let temp = Array(
                      newValue ? fileData[newValue].length : 0
                    ).fill(false);
                    setCheckBox(temp);
                    console.log(temp, selectedCategory);
                    setFileLabelName(newValue ? newValue.label : null);
                    setAllocated(newValue ? newValue.values : null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="normal"
                      label="Category Name"
                    />
                  )}
                />

                {/* File Autocomplete */}
                <Divider textAlign="left">
                  <Chip
                    label={
                      selectedCategory
                        ? "Files for " + selectedCategory
                        : "No category selected"
                    }
                  ></Chip>
                </Divider>

                <List
                  sx={{
                    width: "100%",
                    maxWidth: 600,

                    position: "relative",
                    overflow: "auto",
                    maxHeight: 300,
                    "& ul": { padding: 0 },
                  }}
                >
                  {fileData[selectedCategory] ? (
                    fileData[selectedCategory].length > 0 ? (
                      <>
                        <ListItem
                          key={-1}
                          disablePadding
                          className={
                            "inline  w-full cursor-pointer p-1 rounded  "
                          }
                        >
                          <div id={-1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectAllCheck}
                                  onChange={toggleSelect}
                                />
                              }
                            />
                            <p
                              className="cursor-context-menu px-1 inline"
                              onClick={toggleSelect}
                            >
                              Select All
                            </p>
                          </div>
                        </ListItem>
                        {fileData[selectedCategory]
                          .sort(function (x, y) {
                            if (x.name < y.name) {
                              return -1;
                            }
                            if (x.name > y.name) {
                              return 1;
                            }
                            return 0;
                          })
                          .map((filedata, i) => {
                            return (
                              <ListItem
                                key={i}
                                disablePadding
                                className={
                                  "inline  w-full cursor-pointer p-1 rounded  "
                                }
                              >
                                <div id={i}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          checkboxcheck[i] === undefined
                                            ? false
                                            : checkboxcheck[i]
                                        }
                                        onChange={(e) => {
                                          handleCheckBox(
                                            e,
                                            i,
                                            selectedCategory
                                          );
                                        }}
                                      />
                                    }
                                  />
                                  <DocumentIcon className="w-5 h-5 text-slate-300 inline" />
                                  <p
                                    className="cursor-context-menu px-1 inline"
                                    onClick={() => {
                                      showPreview(filedata.url);
                                    }}
                                  >
                                    {filedata.name}
                                  </p>
                                </div>
                              </ListItem>
                            );
                          })}
                      </>
                    ) : (
                      <p className="text-sm text-slate-200 font-semibold tracking-widest">
                        No files pending
                      </p>
                    )
                  ) : (
                    <p className="text-sm text-slate-200 font-semibold tracking-widest">
                      Files could not be fetched
                    </p>
                  )}
                </List>
                <Divider textAlign="left">
                  <Chip label={"Select resources"}></Chip>
                </Divider>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={currentTab}
                      onChange={handleChange}
                      aria-label="Roles"
                    >
                      <Tab label="Splitting" {...a11yProps(0)} />
                      <Tab label="Naming" {...a11yProps(1)} />
                    </Tabs>
                    {tabs.map((value, index) => {
                      let cur = [...pendingusers];
                      cur = cur.filter((user) => {
                        if (user.role === value) {
                          return true;
                        } else {
                          return false;
                        }
                      });
                      return (
                        <TabPanel value={currentTab} index={index}>
                          <Autocomplete
                            // disablePortal
                            id="resource-box"
                            options={cur.map((user) => {
                              return user.name;
                            })}
                            value={selectedResource[currentTab]}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value
                            }
                            onChange={(e, v) => {
                              const cur = [...selectedResource];
                              cur[currentTab] = v;
                              setSelectedResource(cur);
                            }}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                margin="normal"
                                label="Resource Name"
                              />
                            )}
                          />
                        </TabPanel>
                      );
                    })}
                  </Box>
                  <Box
                    m={1}
                    //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Button onClick={handleAllot} variant="text">
                      Allot
                    </Button>
                    <Button
                      onClick={() => {
                        props.handleClose();
                        props.updateTasks(props.update + 1);
                      }}
                      variant="text"
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};
