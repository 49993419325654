import React from "react";
import { Box, CircularProgress, Typography,Button } from "@mui/material";
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  return (
    <div className="w-max flex-1 flex flex-col justify-start p-20 space-y-4">
        <p className='text-3xl tracking-widest text-slate-200 font-semibold text-center'>
            Restore File?  
          </p>
          <p className='text-xl tracking-widest text-slate-300 font-semibold text-center'>
            A previous save of file: {props.fileName} was found. Saved work won't be discarded.
          </p>
          {props.hasSavedOps && <Button size="large" variant="contained" onClick={()=>{props.handleRestoreAll();
            if(props?.hasOwnProperty('setNamingVis'))
            {props.setNamingVis(true)}}}>
            Restore
          </Button>}
         {!props?.renametab&& <Button size="large" variant={props.hasSavedOps?"outlined":"contained"} onClick={()=>{props.handleRestoreOG(); if(props?.hasOwnProperty('setNamingVis'))
            {props.setNamingVis(true)}}} >
            Start new
          </Button>}
          <Button size="large" variant="outlined" onClick={()=>{props.handleGetOriginal(); if(props?.hasOwnProperty('setNamingVis'))
            {props.setNamingVis(true)}}}>
            Redownload & Start new
          </Button>
    </div>
  );
};