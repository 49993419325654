import { createSlice } from '@reduxjs/toolkit'
export const expSlice = createSlice({
    name: 'expansion',
    initialState: {
        value: [false,true]
    },
    reducers: {
        setExp: (state,action) =>{
            state.value = action.payload
        }
    },
   
})

export const {setExp } = expSlice.actions
export default expSlice.reducer