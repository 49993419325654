import { configureStore } from "@reduxjs/toolkit";
import operationReducer from "../slice/operationSlice";
import pageReducer from "../slice/pageSlice";
import cropReducer from "../slice/cropSlice";
import expansionReducer from "../slice/expansionSlice";
import authReducer from "../slice/authSlice";
import fileReducer from "../slice/fileSlice";
import blobReducer from "../slice/blobSlice";
import modalReducer from "../slice/modalSlice";
import autosaveReducer from '../slice/autoSaveSlice'
import timeReducer from "../slice/timeSlice";
export default configureStore({
  reducer: {
    operation: operationReducer,
    pages: pageReducer,
    crop: cropReducer,
    expansion: expansionReducer,
    file: fileReducer,
    auth: authReducer,
    blob: blobReducer,
    autosave: autosaveReducer,
    modal: modalReducer,
    time: timeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
