import React from "react";

const LoadingDiv = (props) => {
  switch (props.stage) {
    case -1:
      return (
        <div className="w-max flex-1">
          <p className="text-3xl tracking-widest text-slate-400 font-semibold text-center">
            RESTORING
          </p>
        </div>
      );
    case 0:
      return (
        <div className="w-max flex-1">
          <p className="text-3xl tracking-widest text-slate-400 font-semibold text-center">
            {props.downloadProgress < 100
              ? "LOADING: " + props.downloadProgress + " %"
              : "RENDERING"}
          </p>
        </div>
      );
    case 1:
      return(
        <div className="w-max flex-1">
          <p className="text-3xl tracking-widest text-slate-400 font-semibold text-center">
          CREATING LOCAL COPY
          </p>
        </div>
      )
    default:
      return(
        <div className="w-max flex-1">
          <p className="text-3xl tracking-widest text-slate-400 font-semibold text-center">
          PLEASE WAIT
          </p>
        </div>
      )
  }
};
export default LoadingDiv;
