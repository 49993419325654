import React from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from "@mui/material";
import axios from "axios";

export default (props) => {
  // console.log(props);
  const authState = useSelector((s) => s.auth);
  const deleteProject = () => {
    axios
      .delete("api/sales-project/" + props.id)
      .then((r) => {
        props.updateTable();
        props.handleComplete();
      })
      .catch((e) => alert(e));
  };
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Delete this Project ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the project and all the data
          associated with this project?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>CANCEL</Button>
        <Button onClick={deleteProject} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
